import { apiClientBearerToken } from './ApiClientConfig';

import Logger from '../Logger';

const Log = new Logger('src/api/ApplicationsApi.js');

const APPLICATIONS_PATH = '/access/applications';
//const APPLICATIONS_PATH = '/';

const ACTIONS_PATH = '/access';

export const getAllApplications = (
    paginated: Boolean,
    size?: Number,
    page?: Number,
    search?: String,
    resourceView?: String,
): Promise<Array<Object>> =>
  apiClientBearerToken()
    .get(APPLICATIONS_PATH, {
      params: {
        paginated,
        size,
        page,
        search: search?.trim(),
        resourceView,
      },
    })
    .then(response => {
        return Promise.resolve(response).then();
    })
    .catch(error => {
      Log.error(
        getAllApplications.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });

export const getApplicationById = (id: Number): Promise<Object> => {
  return apiClientBearerToken()
    .get(`${APPLICATIONS_PATH}/${id}`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(getApplicationById.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const getApplicationActions = (
    id: Number,
    paginated: Boolean,
    size?: Number,
    page?: Number,
    search?: String,
    resourceView?: String,
): Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`${APPLICATIONS_PATH}/${id}/actions`, {
            params: {
                paginated,
                size,
                page,
                search: search?.trim(),
                resourceView,
            },
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(getApplicationActions.name, error.message, error.response ? error.response : error);
            return Promise.reject();
        });
    };

export const getAllActions = (
    paginated: Boolean,
    size?: Number,
    page?: Number,
    search?: String,
    resourceView?: String,
): Promise<Array<Object>> =>
  apiClientBearerToken()
    .get(`${ACTIONS_PATH}/actions`, {
      params: {
        paginated,
        size,
        page,
        search: search?.trim(),
        resourceView,
      },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(getApplicationActions.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });

export const createNewApplication = (
  applicationName: String,
  applicationDescription: String,
): Promise<Object> => {
  return apiClientBearerToken()
    .post(APPLICATIONS_PATH, {
      applicationName,
      applicationDescription,
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      if (error.response) {
        const errors = { appNameErrorMessage: null, appDescriptionError: null };
        error.response.data.errors.forEach(e => {
          if (e.field === 'application.applicationName') {
            errors.appNameErrorMessage = e.message;
          }
          if (e.field === 'application.applicationDescription') {
            errors.appDescriptionError = e.message;
          }
        });
        return Promise.reject(errors);
      }
      Log.error(createNewApplication.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const updateApplication = (
  id: Number,
  applicationName: String,
  applicationDescription: String,
): Promise<Object> => {
  return apiClientBearerToken()
    .patch(`${APPLICATIONS_PATH}/${id}`, {
      applicationName,
      applicationDescription,
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(updateApplication.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const deleteApplication = (id: Number, restore?: Boolean): Promise<Object> => {
  return apiClientBearerToken()
    .delete(`${APPLICATIONS_PATH}/${id}`, {
      params: {
        restore,
      },
    })
    .then(() => {
      return Promise.resolve();
    })
    .catch(error => {
      Log.error(deleteApplication.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const addActionApplication = (
  id: Number,
  actionName: String,
  actionDescription: String,
): Promise<Object> => {
  return apiClientBearerToken()
    .post(`${APPLICATIONS_PATH}/${id}/actions`, {
      actionName,
      actionDescription,
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(addActionApplication.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const updateApplicationActionById = (
  idApplication: Number,
  actionName: String,
  actionDescription: String,
  idAction: Number,
): Promise<Object> => {
  return apiClientBearerToken()
    .patch(`${APPLICATIONS_PATH}/${idApplication}/actions/${idAction}`, {
      actionName,
      actionDescription,
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        updateApplicationActionById.name,
        error.message,
        error.response ? error.response : error,
      );
      return Promise.reject();
    });
};

export const deleteActionApplication = (
  idApplication: Number,
  idAction: Number,
): Promise<Object> => {
  return apiClientBearerToken()
    .delete(`${APPLICATIONS_PATH}/${idApplication}/actions/${idAction}`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        deleteActionApplication.name,
        error.message,
        error.response ? error.response : error,
      );
      return Promise.reject();
    });
};

export const deleteActionByID = (idAction: Number, restore?: Boolean): Promise<Object> =>
  apiClientBearerToken()
    .delete(`${ACTIONS_PATH}/actions/${idAction}`, {
      params: {
        restore,
      },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        deleteActionApplication.name,
        error.message,
        error.response ? error.response : error,
      );
      return Promise.reject();
    });

export const getGenerateAppsActionsKeys = (
  idApplication: Number,
  platform: String,
): Promise<Object> => {
  return apiClientBearerToken()
    .get(
      `${APPLICATIONS_PATH}/${idApplication}/actions/generateAppsActionsKeys?platform=${platform}`,
    )
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getGenerateAppsActionsKeys.name,
        error.message,
        error.response ? error.response : error,
      );
      return Promise.reject();
    });
};
