export default {
  AppKey: 'AP_RDTDI',
  CreateUser: 'AP_RDTDI:AC_ESEEU',
  GetAllUsers: 'AP_RDTDI:AC_SRAGL',
  GetUserById: 'AP_RDTDI:AC_EERST',
  UpdateUser: 'AP_RDTDI:AC_PPESS',
  DeleteUser: 'AP_RDTDI:AC_DREUE',
  GetUserDataById: 'AP_RDTDI:AC_TEETD',
  UpdateUserDataById: 'AP_RDTDI:AC_BRSIE',
  GetUserAuthorityById: 'AP_RDTDI:AC_TABTA',
  ResetPasswordByEmail: 'AP_RDTDI:AC_EEBIO',
};
