export const APP_ROUTES = {
  LOGIN: '/login',
  CHANGEPASSWORD: '/changepassword/:token',
  RESETPASSWORD: '/reset_password',
  HOME: '/',
  USERS: '/users',
  SHOPS: '/shops',
  WAVES: '/olas',
  MORE: '/more',
  PROFILE: '/perfil',
  PRIORITY: '/prioridades',
};

export const PRIORITY_ROUTES = {
  PRIORITY: '/index'
};

export const PROFILE_ROUTES = {
  PROFLILE: '/index'
};

export const USERS_ROUTES = {
  ADMINISTRATORS: '/administrators',
  POLICIES: '/policies',
  ROLES: '/roles',
};

export const WAVES_ROUTES = {
  CREATE: '/crear',  
  WAVES: '/listar',
  PICKING: '/picking',
  SUPPLYORDERS: '/ods',
  WAVE_NOT_FOUND: '/waves/notfound',
  ZONES: '/zones',
  TEAMS: '/teams',
};

export const MORE_ROUTES = {
  APPLICATIONS: '/applications',
  COLORS: '/colors',
  PAPER_BIN: '/bin',
  PAPER_BIN_MORE: '/bin/more',
  PAPER_BIN_USERS: '/bin/users',
  PAPER_BIN_SHOPS: '/bin/shops',
};

export const SHOPS_ROUTES = {
  PORTAGES: '/portages',
  CATALOGUES: '/catalogues',
  TERRITORIES: '/territories',
  ROUTES: '/routes',
  SHOPS: '/shops',
  PORTAGE_NOT_FOUND: '/portages/notfound',
  SHOP_NOT_FOUND: '/shops/notfound',
};
