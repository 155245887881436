import { apiClientBearerToken } from '../../ApiClientConfig';
import Logger from '../../../Logger';

const Log = new Logger('src/api/ShopServices/Catalogues/DepartmentsApi.js');

const DEPARTMENTS_PATH = '/departments';

export const getAllDepartments = (): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${DEPARTMENTS_PATH}/all`)
      .then(response => {
          return Promise.resolve(response.data);
      })
      .catch(error => {
          Log.error(
              getAllDepartments.name,
              error.message,
              error.response ? error.response : error,
              !(error.response && error.response.status === 404),
          );
          return Promise.reject();
      });
};

export const getDepartmentByID = (id: number): Promise<Object> => {
  return apiClientBearerToken()
    .get(`${DEPARTMENTS_PATH}/${id}`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(getDepartmentByID.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const createDepartment = (name: String, deptoJda: String): Promise<Object> => {
  return apiClientBearerToken()
    .post(DEPARTMENTS_PATH, {
      name,
      deptoJda,
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      if (error.response) {
        if (!error.response.data.errors) {
          const errorDuplicate = {
            departmentsNameErrorMessage: 'Clave de JDA duplicado.',
          };
          return Promise.reject(errorDuplicate);
        }
        const errorsR = {
          departmentsNameErrorMessage: null,
          departmentDeptoJdaErrorMessage: null,
        };
        error.response.data.errors.forEach(e => {
          if (e.field === 'department.name') {
            errorsR.departmentsNameErrorMessage = e.message;
          }
          if (e.field === 'department.deptoJda') {
            errorsR.departmentDeptoJdaErrorMessage = e.message;
          }
        });

        return Promise.reject(errorsR);
      }

      Log.error(createDepartment.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const deleteDepartmentByID = (id: Number, restore?: Boolean = false): Promise<Object> => {
  return apiClientBearerToken()
    .delete(`${DEPARTMENTS_PATH}/${id}`, {
      params: {
        restore,
      },
    })
    .then(() => {
      return Promise.resolve();
    })
    .catch(error => {
      Log.error(deleteDepartmentByID.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const updateDepartmentByID = (
  id: Number,
  name: String,
  deptoJda: String,
): Promise<Object> => {
  return apiClientBearerToken()
    .patch(`${DEPARTMENTS_PATH}/${id}`, {
      name,
      deptoJda,
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      if (error.response) {
        if (!error.response.data.errors) {
          const errorDuplicate = {
            departmentsNameErrorMessage: 'Clave de JDA duplicado.',
          };
          return Promise.reject(errorDuplicate);
        }
        const errorsR = {
          departmentsNameErrorMessage: null,
          departmentDeptoJdaErrorMessage: null,
        };
        error.response.data.errors.forEach(e => {
          if (e.field === 'department.name') {
            errorsR.departmentsNameErrorMessage = e.message;
          }
          if (e.field === 'department.deptoJda') {
            errorsR.departmentDeptoJdaErrorMessage = e.message;
          }
        });

        return Promise.reject(errorsR);
      }

      Log.error(updateDepartmentByID.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};
