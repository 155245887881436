export default {
  AppKey: 'AP_OCI&I',
  CreatePolicy: 'AP_OCI&I:AC_OOCAR',
  GetAllPolicies: 'AP_OCI&I:AC_GEOLL',
  GetPolicyById: 'AP_OCI&I:AC_OPIOT',
  DeletePolicy: 'AP_OCI&I:AC_DELTY',
  UpdatePolicy: 'AP_OCI&I:AC_COAAU',
  CreateRole: 'AP_OCI&I:AC_OLREE',
  GetAllRoles: 'AP_OCI&I:AC_LLSEL',
  GetRoleById: 'AP_OCI&I:AC_GEDOT',
  GetRolePoliciesById: 'AP_OCI&I:AC_YSLGG',
  DeleteRole: 'AP_OCI&I:AC_ELOET',
  UpdateRole: 'AP_OCI&I:AC_RUDPU',
};