import React, { useState, useRef } from 'react';
import { Drawer, TextField, Typography, Box, Button, CircularProgress, Tooltip, IconButton, Fab } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import {
  Edit as EditIcon,
  Add as AddIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
  Beenhere as PoliciesIcon,
} from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
// Components
import SnackBarMessage from '../../../components/SnackBarMessage';

// Data Api
import { getApplicationActions } from '../../../api/ApplicationsApi';
import {
  getApplications,
  createPolicy,
  getPoliciesApplicationActionById,
  updatePolicyById,
} from '../../../api/PoliciesApi';

const drawerWidth = 500;
const useStyles = makeStyles(theme => ({
  container: {
    width: drawerWidth,
  },
  containerPaper: {
    width: drawerWidth,
    padding: 20,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  headerIcon: {
    fontSize: 35,
    marginRight: theme.spacing(1),
  },
  headerCloseButton: {
    padding: theme.spacing(1),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  selectForm: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  selectStyle: {
    minWidth: 195,
    maxWidth: 195,
  },
  selectAddButton: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  bottomSpace: {
    paddingTop: theme.spacing(2),
  },
  optionList: {
    paddingLeft: theme.spacing(6),
    paddingTop: theme.spacing(2),
    minWidth: 300,
    minHeight: '1vh',
  },
}));

const initialState = {
  policyId: 0,
  policyName: ' ',
  policyNameError: false,
  policyNameErrorMessage: '',
  policyDescription: ' ',
  policyDescriptionError: false,
  policyDescriptionErrorMessage: '',
  policyAccessError: false,
  policyAccessMessage: '',
};

const applicationAction = {
  access: '',
  application_name: '',
  action_name: '',
};

function PolicyForm({ formOpen, toggleForm, refreshTable, dataObject, canUpdatePolicy }) {
  const { enqueueSnackbar }         = useSnackbar();
  const classes                     = useStyles();
  const [isLoading, setIsLoading]   = useState(false);
  const [form, setForm]             = useState(initialState);
  const [closeClick, setCloseClick] = useState('');
  const tablePoliciesRef            = useRef();
  const [editForm, setEditForm]     = useState(false);
  const [dataApp, setDataApp]       = useState([]);
  const [actionApp, setActionApp]   = useState([]);
  const [applicationActionForm, setApplicationActionForm] = useState(applicationAction);
  const [keyApplication, setKeyApplication] = useState('');
  const [updatePolicy, setUpdatePolicy]     = useState({ accessPolicy: [] });

  const messageError = (message, variant) => {
    enqueueSnackbar(message, {
      variant,
    });
  };

  const msgBin = (
    <Typography component="span" variant="caption">
      La política ya se encuentra registrada,si no puede verla en esta sección revise la
      <Link to="/more/bin/users?tab=2"> Papelera</Link>
    </Typography>
  );

  const applicationOptions = dataApp.map(option => {
    const firstLetter = option.application_name[0];
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });

  const actionOptions = actionApp.map(option => {
    //const firstLetter = option.actionKey[0];
      const firstLetter = option.action_name[0];
      return {
          firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
          ...option,
      };
  });

  const handleChange = event => {
    setForm({
      ...form,
      policyId: dataObject,
      [event.target.name]: event.target.value,
    });
    setCloseClick(true);
  };

  const handleSubmit = event => {
    event.preventDefault();
    setIsLoading(true);
    const access = [];
    console.log(updatePolicy);
    updatePolicy.accessPolicy.forEach(policy => {
      access.push(policy.access);
      console.log(policy);
    });
    console.log(access);
    if (dataObject === 0) {
      createPolicy(form.policyName, form.policyDescription, access)
        .then(() => {
          toggleForm();
          setForm(initialState);
          setIsLoading(false);
          refreshTable();
        })
        .catch(error => {
          if (error !== undefined) {
            setForm({
              ...form,
              policyNameError: !!error.policyNameErrorMessage,
              policyNameErrorMessage: error.policyNameErrorMessage,
              policyDescriptionError: !!error.policyDescriptionError,
              policyDescriptionErrorMessage: error.policyDescriptionError,
              policyAccessError: !!error.policyAccessMessage,
              policyAccessMessage: error.policyAccessMessage,
            });
          } else {
            messageError('Servicio no disponible.', 'warning');
            refreshTable();
          }
          setIsLoading(false);
        });
    } else {
      updatePolicyById(form.policyId, form.policyName, form.policyDescription, access)
        .then(() => {
          toggleForm();
          setForm(initialState);
          setIsLoading(false);
          refreshTable();
        })
        .catch(error => {
          if (error !== undefined) {
            setForm({
              ...form,
              policyNameError: !!error.policyNameErrorMessage,
              policyNameErrorMessage: error.policyNameErrorMessage,
            });
          } else {
            messageError('Servicio no disponible.', 'warning');
            refreshTable();
          }
          setIsLoading(false);
        });
    }
  };

  const clearData = () => {
    setForm(initialState);
    setCloseClick('');
    setEditForm(false);
    setUpdatePolicy({ accessPolicy: [] });
  };

  const initData = () => {
    if (dataObject === 0) {
      setForm({
        form,
        policyId: 0,
        policyName: '',
        policyDescription: '',
      });
    } else {
      getPoliciesApplicationActionById(dataObject)
        .then(response => {
          setForm({
            policyId: response.id,
            policyName: response.name,
            policyDescription: response.description,
          });
          return Promise.resolve(setUpdatePolicy(response));
        })
        .catch(() => Promise.resolve(setUpdatePolicy({ accessPolicy: [] })));
    }
    setCloseClick(false);
    getApplications()
        .then(response => {
            Promise.resolve(setDataApp(response)).then();
        })
        .catch(() => {
            Promise.resolve(setDataApp([])).then();
        });
    setActionApp([]);
    dataObject !== 0 ? setEditForm(false) : setEditForm(true);
  };

  const disableFormControl = () => {
    if (isLoading) {
      return true;
    }
    if (dataObject === 0) {
      return false;
    }
    if (dataObject !== 0 && editForm) {
      return false;
    }
    return true;
  };

  return (
    <Box>
      <Drawer
        anchor="right"
        open={formOpen}
        onClose={toggleForm}
        className={classes.container}
        classes={{ paper: classes.containerPaper }}
      >
        <Box className={classes.header}>
          <PoliciesIcon color="primary" className={classes.headerIcon} />
          <Box flexGrow={1}>
            <Typography variant="h5" color="primary">
              {dataObject === 0 ? ' Nueva Política' : 'Actualizar Política'}
            </Typography>
          </Box>
          {dataObject !== 0 && (
            <IconButton
              disabled={!canUpdatePolicy}
              color="primary"
              className={classes.headerCloseButton}
              onClick={() => {
                setEditForm(!editForm);
              }}
            >
              <EditIcon />
            </IconButton>
          )}
          <IconButton
            color="secondary"
            className={classes.headerCloseButton}
            onClick={() => {
              toggleForm();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Box component="form" className={classes.form} onSubmit={handleSubmit}>
          <Box>
            <TextField
              error={form.policyNameError}
              helperText={
                form.policyNameErrorMessage === 'Nombre de política duplicado.'
                  ? msgBin
                  : form.policyNameErrorMessage
              }
              name="policyName"
              label="Nombre"
              margin="normal"
              variant="outlined"
              fullWidth
              disabled={disableFormControl()}
              value={form.policyName}
              onChange={handleChange}
            />
            <TextField
              error={form.policyDescriptionError}
              helperText={form.policyDescriptionErrorMessage}
              name="policyDescription"
              label="Descripción"
              multiline
              rows="4"
              margin="normal"
              variant="outlined"
              fullWidth
              disabled={disableFormControl()}
              value={form.policyDescription}
              onChange={handleChange}
            />

            <Box className={classes.selectForm}>
              <Box className={classes.selectStyle}>
                <Autocomplete
                  id="combo-box-policy-application"
                  classes={{ popper: classes.optionList }}
                  options={applicationOptions.sort(
                    (a, b) => -b.firstLetter.localeCompare(a.firstLetter),
                  )}
                  renderOption={option => (
                    <option value={option.id} name={option.id} key={option.id}>
                      {`${option.application_name}`}
                    </option>
                  )}
                  disabled={disableFormControl()}
                  getOptionLabel={option => `${option.application_name}`}
                  noOptionsText="No hay aplicaciones disponibles"
                  autoComplete
                  // forcePopupIcon
                  onChange={(event, value) => {
                    console.log(value);
                    //TODO Revisar con detalle cuando se realice un cambio
                    setKeyApplication(value?.applicationKey || '');
                    setApplicationActionForm({
                      access: '',
                      application_name: value?.application_name || '',
                    });
                    value?.id === undefined
                      ? setActionApp([])
                      : getApplicationActions(Number(value?.id), false, 0, 0)
                          .then(response => {
                            return Promise.resolve(setActionApp(response.data));
                          })
                          .catch(() => {
                            return Promise.resolve(setActionApp([]));
                          });
                  }}
                  renderInput={params => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      id="input-application"
                      label="Aplicaciones"
                      placeholder="Agregar aplicación"
                      variant="standard"
                      fullWidth
                      multiline
                    />
                  )}
                />
              </Box>

              <Box className={classes.selectStyle}>
                <Autocomplete
                  id="combo-box-policy-actions"
                  options={actionOptions.sort(
                    (a, b) => -b.firstLetter.localeCompare(a.firstLetter),
                  )}
                  classes={{ popper: classes.optionList }}
                  renderOption={option => (
                    <option value={option.id} name={option.id} key={option.id}>
                      {`${option.action_name }`}
                    </option>
                  )}
                  clearOnEscape
                  disabled={disableFormControl()}
                  getOptionLabel={option => (actionApp.length > 0 ? `${option.action_name}` : '')}
                  noOptionsText="No hay acciones disponibles."
                  onChange={(event, value) => {
                    console.log(value,applicationActionForm);
                    setApplicationActionForm({
                      ...applicationActionForm,
                      access:
                        value?.actionKey === undefined
                          ? ''
                          : `${keyApplication}:${value.actionKey}`,
                      action_name: value?.action_name || '',
                    });
                  }}
                  renderInput={params => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      id="input-application"
                      label="Acciones"
                      variant="standard"
                      placeholder="Agregar acción"
                      fullWidth
                      multiline
                    />
                  )}
                />
              </Box>
              <Box>
                <Fab
                  size="small"
                  className={classes.selectAddButton}
                  color="primary"
                  onClick={() => {
                    const tempPolicy = { ...updatePolicy };
                    tempPolicy.accessPolicy.push(applicationActionForm);
                    setUpdatePolicy(tempPolicy);
                    setApplicationActionForm({ ...applicationActionForm, access: '' });
                  }}
                  disabled={applicationActionForm.access === ''}
                >
                  <Tooltip title="Agregar Acceso" aria-label="add">
                    <AddIcon />
                  </Tooltip>
                </Fab>
              </Box>
            </Box>

            <MaterialTable
              title="Accesos"
              tableRef={tablePoliciesRef}
              columns={[
                { title: 'Aplicación', field: 'application_name', type: 'string' },
                { title: 'Acción', field: 'action_name', type: 'string' },
              ]}
              data={updatePolicy.accessPolicy}
              options={{
                toolbar: true,
                search: false,
                paging: false,
                actionsColumnIndex: -1,
                toolbarButtonAlignment: 'right',
                sorting: true,
                addRowPosition: 'first',
                headerStyle: {
                  paddingTop: 0,
                },
                padding: 'dense',
                detailPanelColumnAlignment: 'right',
              }}
              localization={{
                body: {
                  emptyDataSourceMessage: 'No hay accesos que mostrar',
                  editRow: {
                    deleteText: '¿Esta seguro de borrar el acceso seleccionado?',
                    saveTooltip: 'Aceptar',
                    cancelTooltip: 'Cancelar',
                  },
                  deleteTooltip: 'Eliminar Acceso',
                },
                header: {
                  actions: 'Acciones',
                },
              }}
              icons={{
                Delete: () => <DeleteIcon color="secondary" className="icon-small" />,
              }}
              editable={
                !disableFormControl()
                  ? {
                      onRowDelete: oldData =>
                        new Promise(resolve => {
                          setTimeout(() => {
                            {
                              const deleteApplicationAction = { ...updatePolicy };
                              const index = deleteApplicationAction.accessPolicy.indexOf(oldData);
                              deleteApplicationAction.accessPolicy.splice(index, 1);
                              setUpdatePolicy(deleteApplicationAction);
                            }
                            resolve();
                          }, 200);
                        }),
                    }
                  : undefined
              }
            />
          </Box>

          <Box className={classes.bottomSpace}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={disableFormControl()}
              style={{ borderRadius: 30 }}
              size="small"
            >
              {dataObject === 0 ? 'Guardar Política' : 'Actualizar Política'}
            </Button>

            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            {formOpen && closeClick === '' && initData()}
            {!formOpen && closeClick !== '' && clearData()}
          </Box>
        </Box>
      </Drawer>

      <SnackBarMessage
        open={form.policyAccessError}
        message={form.policyAccessMessage}
        onClose={() => setForm({ ...form, policyAccessError: false })}
        variant="warning"
      />
    </Box>
  );
}

PolicyForm.defaultProps = {
  refreshTable: null,
  dataObject: 0,
  canUpdatePolicy: false,
};

PolicyForm.propTypes = {
  refreshTable: PropTypes.func,
  formOpen: PropTypes.bool.isRequired,
  toggleForm: PropTypes.func.isRequired,
  dataObject: PropTypes.number,
  canUpdatePolicy: PropTypes.bool,
};

export default PolicyForm;
