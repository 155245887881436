import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  Tooltip,
  DialogContent,
  TextField,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core';

export default function PasswordDialog({
  seePassword,
  open,
  password,
  userError,
  txtUserError,
  action,
  actionClose,
  actionChange,
  actionDataAdmin,
  hideDialog,
  reseted,
}) {
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={seePassword}
      onClose={() => {
        hideDialog();
      }}
    >
      {reseted ? (
        <DialogTitle>Contraseña Generada</DialogTitle>
      ) : (
        <DialogTitle>Contraseña Restablecida</DialogTitle>
      )}
      <DialogContent>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={() => {
            actionClose();
          }}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title="Texto Copiado"
        >
          <TextField
            error={!!userError}
            helperText={txtUserError}
            name="passUser"
            label="Contraseña"
            margin="normal"
            variant="outlined"
            fullWidth
            multiline
            value={password}
            onChange={actionChange}
          />
        </Tooltip>
        <DialogContent>
          <Typography color="error">
            Asegurate de guardar tu contraseña generada, ya que ésta será la única vez que el
            sistema la mostrará.
          </Typography>
        </DialogContent>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            actionClose();
            navigator.clipboard.writeText(password);
          }}
          color="primary"
        >
          Copiar Contraseña
        </Button>
        {reseted ? (
          <Button
            onClick={() => {
              hideDialog();
            }}
            color="primary"
          >
            Aceptar
          </Button>
        ) : (
          <Button
            onClick={() => {
              actionClose();
              action();
              hideDialog();
              actionDataAdmin(true);
            }}
            color="primary"
          >
            Siguiente
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

PasswordDialog.defaultProps = {
  seePassword: false,
  open: false,
  password: null,
  userError: null,
  txtUserError: null,
  action: null,
  actionClose: null,
  actionChange: null,
  actionDataAdmin: null,
  hideDialog: null,
  reseted: false,
};

PasswordDialog.propTypes = {
  seePassword: PropTypes.bool,
  open: PropTypes.bool,
  password: PropTypes.string,
  userError: PropTypes.bool,
  txtUserError: PropTypes.string,
  action: PropTypes.func,
  actionClose: PropTypes.func,
  actionChange: PropTypes.func,
  actionDataAdmin: PropTypes.func,
  hideDialog: PropTypes.func,
  reseted: PropTypes.bool,
};
