import { apiClientBearerToken } from '../../ApiClientConfig';

import Logger from '../../../Logger';

const Log = new Logger('src/api/ShopServices/Catalogues/ColorsApi.js');

const COLORS_PATH = '/shops/catalogues/colors';

export const getAllColors = (
  paginated: Boolean,
  size?: Number = null,
  page?: Number = null,
  search?: String = null,
  resourceView?: String = 'not_removed',
): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(COLORS_PATH, {
      params: {
        paginated,
        size,
        page,
        search: search?.trim(),
        resourceView,
      },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getAllColors.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getColorByID = (id: Number): Promise<Object> => {
  return apiClientBearerToken()
    .get(`${COLORS_PATH}/${id}`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(getColorByID.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const createColor = (
  hexadecimalColor: String,
  dictionaryLanguage: Array,
  setUser: Function = null,
): Promise<Object> => {
  return apiClientBearerToken()
    .post(COLORS_PATH, {
      hexadecimalColor,
      dictionaryLanguage,
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      if (error?.response?.status === 401) {
        setUser(true);
        return Promise.resolve();
      }
      if (error?.response?.data?.message?.includes('COLOR_INDEX')) {
        const errorDuplicate = {
          colorHexadecimalErrorMessage: 'Código de hexadecimal duplicado.',
        };
        return Promise.reject(errorDuplicate);
      }

      if (error?.response?.data?.errors?.length > 0) {
        const errorsR = {
          colorHexadecimalErrorMessage: null,
          dictionaryLanguageColorErrorMessage: null,
        };
        // eslint-disable-next-line no-unused-expressions
        error.response.data?.errors.forEach(e => {
          if (e.field === 'color.hexadecimalColor') {
            errorsR.colorHexadecimalErrorMessage = e.message;
          }
          if (e.field === 'color.dictionaryLanguage') {
            errorsR.dictionaryLanguageColorErrorMessage = e.message;
          }
        });
        return Promise.reject(errorsR);
      }

      Log.error(createColor.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const updateColorByID = (
  id: Number,
  hexadecimalColor: String,
  dictionaryLanguage: Array,
  setUser: Function = null,
): Promise<Object> => {
  return apiClientBearerToken()
    .patch(`${COLORS_PATH}/${id}`, {
      hexadecimalColor,
      dictionaryLanguage,
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      if (error?.response?.status === 401) {
        setUser(true);
        return Promise.resolve();
      }
      if (error?.response?.data?.message?.includes('COLOR_INDEX')) {
        const errorDuplicate = {
          colorHexadecimalErrorMessage: 'Código de hexadecimal duplicado.',
        };
        return Promise.reject(errorDuplicate);
      }

      if (error?.response?.data?.errors?.length > 0) {
        const errorsR = {
          colorHexadecimalErrorMessage: null,
          dictionaryLanguageColorErrorMessage: null,
        };
        // eslint-disable-next-line no-unused-expressions
        error.response.data?.errors.forEach(e => {
          if (e.field === 'color.hexadecimalColor') {
            errorsR.colorHexadecimalErrorMessage = e.message;
          }
          if (e.field === 'color.dictionaryLanguage') {
            errorsR.dictionaryLanguageColorErrorMessage = e.message;
          }
        });
        return Promise.reject(errorsR);
      }

      Log.error(createColor.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const deleteColorByID = (id: Number, restore?: Boolean = false): Promise<Object> => {
  return apiClientBearerToken()
    .delete(`${COLORS_PATH}/${id}`, {
      params: {
        restore,
      },
    })
    .then(() => {
      return Promise.resolve();
    })
    .catch(error => {
      Log.error(deleteColorByID.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};
