import React from 'react';
import { Box, Typography, CardMedia } from '@material-ui/core';

// Styles
import { AppStyles } from '../../../AppStyles';
import LoginStyles from '../../Login/styles/LoginStyles';

export default function UsersDashboardPage() {
  const loginStyles = LoginStyles();
  const appStyles = AppStyles();

  return (
    <Box id="app-users-dashboard-page" component="main" className={appStyles.pageRoot}>
      <Box className={loginStyles.divImage}>
        <CardMedia className={loginStyles.imageCard} image="/logo512.png" title="CCP Logo" />
      </Box>
      <Typography align="center" variant="h2">
        CCP Stores
      </Typography>
      <Typography align="center" variant="subtitle1">
        UsersDashboardPage...
      </Typography>
    </Box>
  );
}
