import moment from 'moment';
import { apiClientBearerToken } from './../ApiClientConfig';
import Logger from '../../Logger';

const Log = new Logger('src/api/UsersApi.js');

const PRIORITY_PATH = '/priority';

export const getAllPriorities = (
    size?: Number = null,
    page?: Number = null,
    search?: String = null,
    resourceView?: String = 'not_removed',
): Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`${PRIORITY_PATH}/all`, {
            params: {
                size,
                page,
                search: search?.trim(),
                resourceView,
            },
        })
        .then(response => {
            return Promise.resolve(response.data.data);
        })
        .catch(error => {
            Log.error(
                getAllPriorities.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};

export const deletePriority = (id: Number): Promise<Object> => {
    return apiClientBearerToken()
        .get(`${PRIORITY_PATH}/delete/${id}`, {
        })
        .then(() => {
            return Promise.resolve();
        })
        .catch(error => {
            Log.error(deletePriority.name, error.message, error.response ? error.response : error);
            return Promise.reject();
        });
};


export const createPriority = (
    name: String,
): Promise<Object> => {
    return apiClientBearerToken()
        .post(`${PRIORITY_PATH}/create`, {
            name,
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            if (error.response) {
                const errorDuplicate = {
                    userNameErrorMessage: 'El correo ya esta registrado',
                };
                return Promise.reject(errorDuplicate);
            }
            Log.error(createPriority.name, error.message, error.response ? error.response : error);
            return Promise.reject();
        });
};

export const getpriorityById = (id: Number): Promise<Object> => {
    return apiClientBearerToken()
        .get(`${PRIORITY_PATH}/show/${id}`)
        .then(response => {
            return Promise.resolve(response.data.priority);
        })
        .catch(error => {
            Log.error(getpriorityById.name, error.message, error.response ? error.response : error);
            return Promise.reject();
        });
};


export const updatePriorityById = (
    id: Number,
    name: String,
    prioritiesId: Array,
): Promise<Object> => {
    return apiClientBearerToken()
        .post(`${PRIORITY_PATH}/update`, {
            id,
            name,
            prioritiesId
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            return Promise.reject();
        });
};