import React, { useState, useRef } from 'react';
import { Drawer, Typography, Box, CircularProgress, IconButton, Avatar } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Edit as EditIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  CheckBoxOutlineBlank,
} from '@material-ui/icons';
import materialTableLocaleES from '../../../components/MaterialTableLocaleES';

// Components
import MaterialTable from 'material-table';
import { getWaveData } from '../../../api/WaveServices/WavesApi';
import SnackBarMessage from '../../../components/SnackBarMessage';

const drawerWidth = 1050;
const useStyles = makeStyles(theme => ({
  container: {
    width: drawerWidth,
  },
  containerPaper: {
    width: drawerWidth,
    padding: 20,
  },
  smallColor: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

const initialState = {
  orderGroupId: 0,
  waveData: [],
};

const renderOrdersDetail = rowData => {
  const columns = [
    { title: 'Orden', field: 'id' },
    { title: 'No. de tienda', field: 'storeNumber' },
    { title: 'Tienda', field: 'store' },
    { title: 'Ruta', field: 'storeRoute' },
    { title: 'Piezas', field: 'pieces' },
  ];
  return (
    <MaterialTable
      columns={columns}
      data={rowData.orders}
      title={"Distribución de tiendas "+rowData.description}
      options={{
        search: false,
        paging: false,
        padding: 'dense',
        selection: true,
        toolbar: true,
        rowStyle: {
          backgroundColor: '#EEE',
        },
      }}
      localization={materialTableLocaleES}
      actions={[
        {
          tooltip: 'Quitar tienda(s) seleccionada(s)?',
          icon: 'delete',
          onClick: (evt, data) => alert('Desea eliminar ' + data.length + ' tienda(s) de esta distribución?')
        }
      ]}
    />
  );
};

export default function WaveDetail({ formOpen, toggleForm, refreshTable, dataObject }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState(initialState);
  const [closeClick, setCloseClick] = useState('');
  const [editForm, setEditForm] = useState(false);

  const clearData = () => {
    setForm(initialState);
    setCloseClick('');
    setIsLoading(false);
  };

  const initData = () => {
    getWaveData(dataObject)
      .then(response => {
        setForm({ waveData: response.data });
      })
      .catch(error => console.log(error));

    setCloseClick(false);    
  };

  return (
    <Box>
      <Drawer
        anchor="right"
        open={formOpen}
        onClose={toggleForm}
        className={classes.container}
        classes={{ paper: classes.containerPaper }}
      >
        <Box className={classes.header}>
          <Box flexGrow={1}>
            <Typography variant="h5" color="primary">
              Detalle de Ola
            </Typography>
          </Box>
        </Box>
        <MaterialTable          
          columns={[
            { title: 'No. de Ruta', field: 'id' },
            { title: 'Nombre de Ruta', field: 'description' },
            {
              title: 'Piezas',
              field: 'pieces',
              type: 'numeric',
              render: rowData => {
                return rowData.pieces.toLocaleString();
              },
            },
            {
              title: 'Color',
              field: 'color',
              render: rowData => {
                return (
                  <Avatar variant="square" style={{ backgroundColor: rowData.color }} className={classes.smallColor}>
                    {' '}
                  </Avatar>
                );
              },
            },
          ]}
          localization={materialTableLocaleES}
          data={form.waveData}
          options={{
            search: false,
            paging: false,
            toolbar: false,
            padding: 'dense',            
          }}

          detailPanel={rowData => renderOrdersDetail(rowData)}
        />

        <Box component="form" className={classes.form}>
          <Box />
          <Box className={classes.bottomSpace}>
            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            {formOpen && closeClick === '' && initData()}
            {!formOpen && closeClick !== '' && clearData()}
          </Box>
        </Box>
      </Drawer>

      <SnackBarMessage
        open={form.policyAccessError}
        message={form.roleAccessMessage}
        onClose={() => setForm({ ...form, policyAccessError: false })}
        variant="warning"
      />
    </Box>
  );
}

WaveDetail.defaultProps = {
  refreshTable: null,
  dataObject: 0,
};

WaveDetail.propTypes = {
  refreshTable: PropTypes.func,
  formOpen: PropTypes.bool.isRequired,
  toggleForm: PropTypes.func.isRequired,
  dataObject: PropTypes.number,
};
