import React, { useState, useRef, useEffect } from 'react';
import {
    Drawer, FormControl,
    IconButton,
    InputLabel,
    makeStyles,
    MenuItem,
    TextField,
    Typography,
    Box,
    Button,
    CircularProgress,
    FormControlLabel,
    Switch,
    FormLabel,
    Card,
    CardContent,
    Tooltip,
    Select,
    Fab,
} from '@material-ui/core';


import {
    Visibility as ViewIcon,
    EventAvailable as AccountNonExpiredIcon,
    EventBusy as AccountExpiredIcon,
    Lock as AccountLockedIcon,
    LockOpen as AccountNonLockedIcon,
    RadioButtonChecked as EnableIcon,
    VerifiedUser as CredentialsNonExpiredIcon,
    Block as CredentialsExpiredIcon,
    KeyboardArrowRight,
    KeyboardArrowDown, People as UsersIcon, Edit as EditIcon, Close as CloseIcon,
} from '@material-ui/icons';

// Components
import AdminForm from '../components/ProfileForm';
import PageHeader from '../components/PageHeaderPerfil';
import WithSnackBarMessages from '../../../components/WithSnackBarMessages';
import DataTable from '../../../components/DataTable';

// Security
import Permissions from '../../../AppsPermissions/UsersAdministratorPermissions';
import { hasAuthority } from '../../AccessValidators';

// Styles
import { AppStyles } from '../../../AppStyles';

// Data Apis
import {getMeUser, updateUserById,} from '../../../api/UsersApi';
import {getAllPolicies} from "../../../api/PoliciesApi";
import {getAllRoles} from "../../../api/RoleApi";
import {getAllShops} from "../../../api/ShopServices/ShopApi";

// Routes
import { APP_ROUTES, USERS_ROUTES } from '../../../Routes';
import moment from "moment";
import {useSnackbar} from "notistack";

const breadcrumbsData = [
    {
        id: 1,
        label: 'Perfil',
        base: APP_ROUTES.USERS + USERS_ROUTES.ADMINISTRATORS,
    }
];
const stateInit = {
    enabled: true,
    accountNonExpired: false,
    credentialsNonExpired: false,
    accountNonLocked: false,
};

const initialState = {
    userId: 0,
    emailUser: ' ',
    nameUser: ' ',
    storeUser: '',
    extensionUser: ' ',
};
const drawerWidth = 'flex';
const useStyles = makeStyles(theme => ({
    form: {
        margin: 10,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'space-between',
    },
    container : {
        width: drawerWidth,
        height: 1000,
        background: 'white',
        margin: '10px',
        justifyContent: 'space-between',
        padding: 20,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
    },
    bottomSpace: {
        paddingTop: theme.spacing(2),
    },

}));

function ProfilePage() {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const appStyles = AppStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [form, setForm] = useState(initialState);
    const tableRef = useRef();
    const [openForm, setOpenForm] = useState(false);
    const [dataObject, setDataObject] = useState(0);
    const [canGetAllUsers, setCanGetAllUsers] = useState(true);
    const [canGetUser, setCanGetUser] = useState(true);
    const [canCreateUser, setCanCreateUser] = useState(true);
    const [canUpdateUser, setCanUpdateUser] = useState(true);
    const [canGetUserData, setCanGetUserData] = useState(true);
    const [canUpdateUserData, setCanUpdateUserData] = useState(true);
    const [canDeleteUser, setCanDeleteUser] = useState(true);
    const [state, setState] = useState(stateInit);
    const [shops, setShops] = useState([]);

    const messageError = (message, variant) => {
        enqueueSnackbar(message, {
            variant,
        });
    };
    const toggleForm = () => {
        if (tableRef.current) {
            tableRef.current.state.query.page = 0;
            if (openForm) {
                tableRef.current.onQueryChange();
            }
        }
        setOpenForm(!openForm);
        setDataObject(0);
    };

    useEffect(() => {
        /*
        hasAuthority(Permissions.GetAllUsers, setCanGetAllUsers);
        hasAuthority(Permissions.GetUserById, setCanGetUser);
        hasAuthority(Permissions.CreateUser, setCanCreateUser);
        hasAuthority(Permissions.UpdateUser, setCanUpdateUser);
        hasAuthority(Permissions.GetUserDataById, setCanGetUserData);
        hasAuthority(Permissions.UpdateUserDataById, setCanUpdateUserData);
        hasAuthority(Permissions.DeleteUser, setCanDeleteUser);
        */
    }, []);


    const initData = () => {
        getMeUser()
            .then(response => {

                setForm({
                    userId: response.id,
                    emailUser: response.email,
                    nameUser: response.name,
                    extensionUser: response.extension,
                    storeUser: response.store_id,
                });
                setState({
                    enabled: response.enabled,
                    accountNonExpired: response.accountNonExpired,
                    credentialsNonExpired: response.credentialsNonExpired,
                    accountNonLocked: response.accountNonLocked,
                });
            })
            .catch(() => {
                return Promise.resolve(setForm({}));
            });
        getAllShops()
            .then(response => {
                return Promise.resolve(setShops(response.stores));
            })
            .catch(() => {
                return Promise.resolve(setShops([]));
            });

    };

    const handleSubmit = event => {
        event.preventDefault();
        setIsLoading(true);
        const rolesAdd = [];
        const policesAdd = [];
        updateUserById(
            form.userId,
            form.nameUser,
            form.emailUser,
            form.extensionUser,
            form.storeUser,
            rolesAdd,
            policesAdd,
        )
            .then(() => {
                setIsLoading(false);
                window.location.reload();
                messageError('Se actualizo la información.', 'success');
            })
            .catch(error => {
                messageError('Verifique los campos.', 'error');
                setIsLoading(false);
            });
    };

    const handleChange = event => {
        let value = event.target.checked;
        if (
            event.target.name === 'accountNonExpired' ||
            event.target.name === 'accountNonLocked' ||
            event.target.name === 'credentialsNonExpired'
        ) {
            value = !value;
        }
        setState({ ...state, [event.target.name]: value });
        setForm({
            ...form,
            [event.target.name]: event.target.value,
        });
    };



    const deleteAdministratorData = query => {

    };

    useEffect(() => {
        tableRef.current && tableRef.current.onQueryChange();
        initData();

    }, [canGetAllUsers]);

    return (

        <Box id="app-users-admins-page" component="main" className={appStyles.pageRoot}>
            <PageHeader
                title="Perfil"
                titleButton="Cambiar Contraseña"
                displayButton
                canExecuteButton={canCreateUser}
                action={toggleForm}
                breadcrumbsData={breadcrumbsData}
            />

            <AdminForm
                formOpen={openForm}
                toggleForm={toggleForm}
                refreshTable={() => tableRef.current && tableRef.current.onQueryChange()}
                dataObject={dataObject}
                canUpdateUser={canUpdateUser}
                canGetUserData={canGetUserData}
                canUpdateUserData={canUpdateUserData}
            />
            <Box component="form" className={classes.container} onSubmit={handleSubmit}>
                <Box className={classes.header}>
                    <Box flexGrow={1}>
                        <Typography variant="h5" color="primary">
                            Actualiza Tú Información
                        </Typography>
                    </Box>
                </Box>

                <Box className={classes.form}>
                    <TextField
                        name="emailUser"
                        label="Email"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        disabled
                        value={form.emailUser}
                        onChange={handleChange}
                    />
                    <TextField
                        name="nameUser"
                        required
                        label="Nombre"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        value={form.nameUser}
                        onChange={handleChange}
                    />
                    <TextField
                        name="extensionUser"
                        required
                        label="Extencion"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        value={form.extensionUser}
                        onChange={handleChange}
                    />
                    <Box className={classes.bottomSpace}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            style={{ borderRadius: 30 }}
                            size="small"
                        >
                            Actualizar Información
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default WithSnackBarMessages(ProfilePage);
