import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(2),
  },
  divImage: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  imageCard: {
    width: 140,
    height: 140,
    resizeMode: 'center',
    alignSelf: 'center',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    position: 'relative',
  },
  cardForm: {
    marginTop: theme.spacing(1),
  },
  forgot: {
    fontSize: 12,
  },
}));
