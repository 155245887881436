import React from 'react';
import { Drawer, Button, ListItem, List } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { grey, purple } from '@material-ui/core/colors';
import { useHistory } from 'react-router-dom';
import {
  PeopleAlt as UsersIcon,
  Person as UserIcon,
  MoreHoriz as MoreIcon,
  ExitToApp,
  Waves as WavesIcon,
  ImportExport  as PriorityIcon,
} from '@material-ui/icons';
import AppConfig from '../AppConfig';

// Routes
import { APP_ROUTES } from '../Routes';

// Security
import { revokeToken } from '../api/AuthApi';

const AppButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    '&:hover': {
      backgroundColor: purple[700],
    },
    padding: theme.spacing(1, 1.5),
    minWidth: theme.spacing(0),
    fontWeight: 'bold',
  },
}))(Button);

const AdminButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(grey[600]),
    backgroundColor: grey[500],
    '&:hover': {
      backgroundColor: grey[700],
    },
    padding: theme.spacing(1, 1.5),
    minWidth: theme.spacing(0),
    fontWeight: 'bold',
  },
}))(Button);

const drawerWidth = 75;

const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: 'black',
    zIndex: 1201,
  },
  adminList: {
    height: 'inherit',
    display: 'flex',
    flexDirection: 'column-reverse',
  },
}));

export default function AppDrawer() {
  const classes = useStyles();
  const { push } = useHistory();

  const handleLogout = () => {
    revokeToken();
    window.location.reload();
  };

  return (
    <Drawer
      id="app-layout-drawer"
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      {/* Applications list */}
      <List>
        {/* <ListItem alignItems="center">
          <AppButton
            variant="contained"
            color="primary"
            onClick={() => {
              window.location.href = AppConfig.PRODUCTS_BASE_URL;
              return null;
            }}
          >
            PR
          </AppButton>
        </ListItem> */}
      </List>

      {/* Admin Buttons List */}
      <List className={classes.adminList}>
        <ListItem alignItems="center">
          <AdminButton variant="contained" color="primary" onClick={handleLogout}>
            <ExitToApp />
          </AdminButton>
        </ListItem>        
        <ListItem alignItems="center">
          <AdminButton variant="contained" color="primary" onClick={() => push(APP_ROUTES.WAVES)}>
            <WavesIcon />
          </AdminButton>
        </ListItem>
        <ListItem alignItems="center">
            <AdminButton variant="contained" color="primary" onClick={() => push(APP_ROUTES.PRIORITY)}>
                <PriorityIcon />
            </AdminButton>
        </ListItem>
        <ListItem alignItems="center">
          <AdminButton variant="contained" color="primary" onClick={() => push(APP_ROUTES.USERS)}>
            <UsersIcon />
          </AdminButton>
        </ListItem>
        <ListItem alignItems="center">
            <AdminButton variant="contained" color="primary" onClick={() => push(APP_ROUTES.PROFILE)}>
                <UserIcon />
            </AdminButton>
        </ListItem>
      </List>
    </Drawer>
  );
}
