import { apiClientBearerToken } from '../ApiClientConfig';
import Logger from '../../Logger';
import {paginated} from "../WaveServices/WavesApi";
import moment from "moment";
const Log = new Logger('src/api/WaveServices/WavesApi.js');

const TEAMS_PATH = '/team';
const DIVISION_PATH = '/divisions';
const DEPARTMENTS_PATH = '/departments';
const USER_ROLES = '/users';
const ROLE = '/role';
const USER_TEAM = '/userteam';

export const getAllDivisions = (
): Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`${DIVISION_PATH}/all`)
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(
                getAllDivisions.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};

export const getUserByRol = (idRol: Number): Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`${USER_ROLES}/roleusers/${idRol}`)
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(
                getUserByRol.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};

export const getAllRoles = (): Promise<Object> => {
    return apiClientBearerToken()
        .get(`${ROLE}`)
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(getAllRoles.name, error.message, error.response ? error.response : error);
            return Promise.reject();
        });
};

export const getAllTeams = (): Promise<Object> => {
    return apiClientBearerToken()
        .get(`${TEAMS_PATH}/all`)
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(getAllTeams.name, error.message, error.response ? error.response : error);
            return Promise.reject();
        });
};

export const getTeamsByDivision = (idDivision: Number): Promise<Object> => {
    return apiClientBearerToken()
        .get(`${TEAMS_PATH}/${idDivision}`)
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(getTeamsByDivision.name, error.message, error.response ? error.response : error);
            return Promise.reject();
        });
};

export const deleteTeam = (idTeam : Number) : Promise<Object> => {
    return apiClientBearerToken()
        .get(`${TEAMS_PATH}/delete/${idTeam}`, {
        })
        .then(() => {
            return Promise.resolve();
        })
        .catch(error => {
            Log.error(deleteTeam.name, error.message, error.response ? error.response : error);
            return Promise.reject();
        });
};


export const freeOperatorTeam = (idOperator : Number) : Promise<Object> => {
    return apiClientBearerToken()
        .get(`${USER_TEAM}/delete/${idOperator}`, {
        })
        .then(() => {
            return Promise.resolve();
        })
        .catch(error => {
            Log.error(freeOperatorTeam.name, error.message, error.response ? error.response : error);
            return Promise.reject();
        });
};

export const createTeam = (
    name: String,
    description: String,
    administrator: Number,
    department: Number
): Promise<Object> => {
    return apiClientBearerToken()
        .post(`${TEAMS_PATH}/create`, {
            name,
            description,
            administrator,
            department
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            if (error.response) {
                return Promise.reject(error.response);
            }

            Log.error(createTeam.name, error.message, error.response ? error.response : error);
            return Promise.reject();
        });
};

export const getTeamByID = (id: Number): Promise<Object> => {
    return apiClientBearerToken()
        .get(`${TEAMS_PATH}/show/${id}`)
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(getTeamByID.name, error.message, error.response ? error.response : error);
            return Promise.reject();
        });

};

export const updateTeamByID = (
    id: Number,
    name: String,
    description: String,
    administrator: Number,
    department: Number,
): Promise<Object> => {
    return apiClientBearerToken()
        .post(`${TEAMS_PATH}/update/${id}`, {
            name,
            description,
            administrator,
            department,
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            if (error.response) {
                return Promise.reject(error);
            }
            Log.error(updateTeamByID.name, error.message, error.response ? error.response : error);
            return Promise.reject();
        });
};

export const updateOperatorTeam = (
    id: Number,
    team: Number,
    operator: Number,
): Promise<Object> => {
    return apiClientBearerToken()
        .post(`${USER_TEAM}/update/${operator}`, {
            team:team,
            operator:operator,
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            if (error.response) {
                return Promise.reject(error);
            }
            Log.error(updateTeamByID.name, error.message, error.response ? error.response : error);
            return Promise.reject();
        });
};

export const getAllDepartments = (
): Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`${TEAMS_PATH}/departments/free`)
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(
                getAllDepartments.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};

export const getOperatorById = (id: Number): Promise<Object> => {
    return apiClientBearerToken()
        .get(`${USER_TEAM}/show/${id}`)
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(getOperatorById.name, error.message, error.response ? error.response : error);
            return Promise.reject();
        });
};

export const getOperatorTeam = (idTEam: Number): Promise<Object> => {
    return apiClientBearerToken()
        .get(`${USER_TEAM}/all/${idTEam}`)
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(getOperatorTeam.name, error.message, error.response ? error.response : error);
            return Promise.reject();
        });
};

export const getAllDepartmentsWhitNotFree = (
): Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`${DEPARTMENTS_PATH}/all`)
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(
                getAllDepartmentsWhitNotFree.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};