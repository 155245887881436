import axios from 'axios';
import AppConfig from '../AppConfig';
import { getCookie } from '../AppCookies';

export const apiClientBasicAuth = axios.create({
  baseURL: AppConfig.BASE_URL,
  responseType: 'json',
});

export const apiClientBearerToken = () => {
  return axios.create({
    baseURL: AppConfig.API_BASE_URL,
    responseType: 'json',
    headers: {
      Authorization: getCookie('user_token')
        ? `Bearer ${getCookie('user_token').access_token}`
        : '',
    },
  });
};
