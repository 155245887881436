import { apiClientBearerToken } from './ApiClientConfig';
import { getAllApplications } from './ApplicationsApi';
import Logger from '../Logger';

const Log = new Logger('src/api/PoliciesApi.js');

const POLICIES_PATH = '/permissions';

export const getAllPolicies = (
  paginated: Boolean,
  size?: Number,
  page?: Number,
  search?: String,
  resourceView?: String,
): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(POLICIES_PATH, {
      params: {
        paginated,
        size,
        page,
        search: search?.trim(),
        resourceView,
      },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getAllPolicies.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getPoliciesApplicationActionById = (id: Number): Promise<Object> => {
    return apiClientBearerToken()
        .get(`${POLICIES_PATH}/${id}/access`)
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(getPoliciesApplicationActionById.name, error.message, error.response ? error.response : error);
            return Promise.reject();
        });
};

export const createPolicy = (name: String, description: String, access: Array): Promise<Object> => {
  return apiClientBearerToken()
    .post(POLICIES_PATH, {
      name,
      description,
      access,
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      if (error.response) {
        if (!error.response.data.errors) {
          const errorDuplicate = {
            policyNameErrorMessage: 'Nombre de política duplicado.',
          };
          return Promise.reject(errorDuplicate);
        }
        const errors = {
          policyNameErrorMessage: null,
          policyDescriptionError: null,
          policyAccessMessage: null,
        };
        error.response.data.errors.forEach(e => {
          if (e.field === 'policy.name') {
            errors.policyNameErrorMessage = e.message;
          }
          if (e.field === 'policy.description') {
            errors.policyDescriptionError = e.message;
          }
          if (e.field === 'policy.access') {
            errors.policyAccessMessage = e.message;
          }
        });

        return Promise.reject(errors);
      }

      Log.error(createPolicy.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const deletePolicy = (id: Number, restore?: Boolean): Promise<Object> => {
  return apiClientBearerToken()
    .post(`${POLICIES_PATH}/${id}/delete`, {
      params: {
        restore,
      },
    })
    .then(() => {
      return Promise.resolve();
    })
    .catch(error => {
      Log.error(deletePolicy.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const getApplications = (): Promise<Object> => {
  return getAllApplications(false, 0, 0)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(getApplications.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const updatePolicyById = (
  id: Number,
  name: String,
  description: String,
  access: Array,
): Promise<Object> => {
  return apiClientBearerToken()
    .post(`${POLICIES_PATH}/${id}`, {
      name,
      description,
      access,
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      if (error.response) {
        const errorDuplicate = {
          policyNameErrorMessage: 'Nombre de política duplicado.',
        };
        return Promise.reject(errorDuplicate);
      }
      Log.error(updatePolicyById.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};
