import React, { useState, useRef, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { Visibility as ViewIcon, KeyboardArrowRight, KeyboardArrowDown } from '@material-ui/icons';

// Components
import PolicyForm from '../components/PolicyForm';
import PageHeader from '../../../components/PageHeader';
import WithSnackBarMessages from '../../../components/WithSnackBarMessages';
import DataDetail from '../../../components/DataDetail';

// Security
import Permissions from '../../../AppsPermissions/RolesAndPoliciesAdministratorPermissions';
import { hasAuthority } from '../../AccessValidators';

// Styles
import { AppStyles } from '../../../AppStyles';

// Data Api
import { getAllPolicies, deletePolicy } from '../../../api/PoliciesApi';

// Routes
import { APP_ROUTES, USERS_ROUTES } from '../../../Routes';
import DataTable from '../../../components/DataTable';

const breadcrumbsData = [
    { id: 1, label: 'Usuarios', base: APP_ROUTES.USERS },
    { id: 2, label: 'Roles y Políticas', base: APP_ROUTES.USERS + USERS_ROUTES.POLICIES },
    { id: 3, label: 'Políticas', base: APP_ROUTES.USERS + USERS_ROUTES.POLICIES },
];

function PoliciesPage() {
    const appStyles = AppStyles();
    const tableRef = useRef();
    const [openForm, setOpenForm] = useState(false);
    const [dataObject, setDataObject] = useState(0);
    const [canGetAllPolicies, setCanGetAllPolicies] = useState(false);
    const [canGetPolicy, setCanGetPolicy] = useState(false);
    const [canCreatePolicy, setCanCreatePolicy] = useState(false);
    const [canUpdatePolicy, setCanUpdatePolicy] = useState(false);
    const [canDeletePolicy, setCanDeletePolicy] = useState(false);

    const toggleForm = () => {
        if (tableRef.current) {
            tableRef.current.state.query.page = 0;
            if (openForm) {
                tableRef.current.onQueryChange();
            }
        }
        setOpenForm(!openForm);
        setDataObject(0);
    };

    useEffect(() => {
        hasAuthority(Permissions.GetAllPolicies, setCanGetAllPolicies);
        hasAuthority(Permissions.GetPolicyById, setCanGetPolicy);
        hasAuthority(Permissions.CreatePolicy, setCanCreatePolicy);
        hasAuthority(Permissions.UpdatePolicy, setCanUpdatePolicy);
        hasAuthority(Permissions.DeletePolicy, setCanDeletePolicy);
    }, []);

    const getPoliciesData = query =>
        canGetAllPolicies?
            getAllPolicies(true, query.pageSize, query.page, query.search)
                .then(response => ({
                    ...query,
                    data: response.data,
                    page: response.current_page-1,
                    totalCount: response.total,
                }))
                .catch(() => ({
                    ...query,
                    page: 0,
                    pageSize: 15,
                    totalCount: 0,
                    data: [],
                }))
            : new Promise(resolve => {
                resolve({ ...query, data: [], page: 0, totalCount: 0 });
            });

  const deletePolicyData = oldData =>
    new Promise((resolve, reject) => {
      setTimeout(() => {
        deletePolicy(oldData.id)
          .then(() => {
            if (tableRef.current) {
              tableRef.current.state.query.page = 0;
              tableRef.current.onQueryChange();
            }
          })
          .catch(() => reject());
        resolve();
      }, 1000);
    });

  useEffect(() => {
    tableRef.current && tableRef.current.onQueryChange();
  }, [canGetAllPolicies]);

  return (
    <Box id="app-users-policies-page" component="main" className={appStyles.pageRoot}>
      <PageHeader
        title="Políticas"
        titleButton="Nueva Política"
        displayButton
        canExecuteButton={canCreatePolicy}
        action={toggleForm}
        breadcrumbsData={breadcrumbsData}
      />

      <PolicyForm
        formOpen={openForm}
        toggleForm={toggleForm}
        refreshTable={() => tableRef.current && tableRef.current.onQueryChange()}
        dataObject={dataObject}
        canUpdatePolicy={canUpdatePolicy}
      />

      <DataTable
        nameEntity="Política"
        columns={[
          { title: 'Nombre', field: 'name', type: 'string' },
          { title: 'Descripción', field: 'description', type: 'string' },
        ]}
        tableActions={[
          {
            disabled: !canGetPolicy,
            icon: () => (
              <ViewIcon color={canGetPolicy ? 'primary' : 'disabled'} className="icon-small" />
            ),
            tooltip: canGetPolicy ? 'Ver Política' : undefined,
            onClick: (event, data) => {
              setDataObject(data.id);
              setOpenForm(!openForm);
            },
          },
        ]}
        tableRef={tableRef}
        getDataFunction={getPoliciesData}
        deleteDataFunction={deletePolicyData}
        canGetAll={canGetAllPolicies}
        canDelete={canDeletePolicy}
        tableDetailPanel={[
          {
            tooltip: 'Mas Información',
            icon: () => <KeyboardArrowRight color="primary" />,
            openIcon: () => <KeyboardArrowDown color="primary" />,
            render: rowData => {
              return <DataDetail rowData={rowData} />;
            },
          },
        ]}
      />
    </Box>
  );
}

export default WithSnackBarMessages(PoliciesPage);
