import React from 'react';
import { Box, Typography, CardMedia } from '@material-ui/core';
import PropTypes from 'prop-types';

// App Layout
import { WithPublicLayout } from '../components/AppLayout';

// Styles
import { AppStyles } from '../AppStyles';
import LoginStyles from './Login/styles/LoginStyles';

export function NotFoundPage({ message }) {
  const appStyles = AppStyles();
  const loginStyles = LoginStyles();

  return (
    <Box id="app-not-found-page" component="main" className={appStyles.pageRoot}>
      <Box className={loginStyles.divImage}>
        <CardMedia className={loginStyles.imageCard} image="/logo512.png" title="CCP Logo" />
      </Box>
      <Typography align="center" variant="h2">
        404 Not Found
      </Typography>
      <Typography align="center" variant="h5">
        {message}
      </Typography>
    </Box>
  );
}

NotFoundPage.defaultProps = {
  message: 'El contenido solicitado no existe',
};

NotFoundPage.propTypes = {
  message: PropTypes.string,
};

export default WithPublicLayout(NotFoundPage);
