import React, { useState, useRef, useEffect } from 'react';

import { makeStyles, Theme, useTheme, withStyles, createStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SwipeableViews from 'react-swipeable-views';

import LoginStyles from '../../Login/styles/LoginStyles';
import PageHeader from "../../../components/PageHeader";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import OrderPickingDetail from '../pages/OrderPickingDetail';
import MaterialTable from "material-table";
import PropTypes from 'prop-types';

// Styles
import { AppStyles } from '../../../AppStyles';
import materialTableLocaleES from "../../../components/MaterialTableLocaleES";
import {APP_ROUTES} from "../../../Routes";

// Data Apis
import {
    getAllDivisions,
    getTeamsByDivision,
    getUserByRol,
    freeOperatorTeam,
    getAllRoles,
    deleteTeam,
    getOperatorTeam} from '../../../api/TeamServices/TeamsApi';
import {
    Delete as DeleteIcon,
    Label as LabelIcon,
    Visibility as ShowIcon,
    Visibility as ViewIcon
} from "@material-ui/icons";


//Components
import TeamForm from '../components/TeamForm';
import OperatorTeamForm from "../components/OperatorTeamForm";
import WithSnackBarMessages from "../../../components/WithSnackBarMessages";
import {useSnackbar} from "notistack";

const breadcrumbsData = [
    {
        id: 1,
        label: 'Equipos de trabajo',
        base: APP_ROUTES.WAVES,
    }
];

const useStyles = makeStyles(theme => ({
    actionDescriptionEdit: {
        '& .MuiInput-root': {
            fontSize: 'small',
        },
    },
    root: {
        backgroundColor: theme.palette.background.paper,
        width: '100%',
    },
    paper: {
        padding: theme.spacing(3),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    typography: {
        textAlign: 'center',
    },
}));

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

function a11yProps(index: any) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}


const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: '#EEE',
        },
        body: {
            fontSize: 14,
        },
    }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: '#EEE',
            },
        },
    }),
)(TableRow);

function createData(pTotales: number, pProcesadas: number, pRestantes: number, progreso: number) {
    return { pTotales, pProcesadas, pRestantes, progreso };
}

const rows = [
    createData(21000, 20370, 630,97),
];




function TeamsPage() {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const appStyles = AppStyles();
    const [divisions, setDivisions] = useState([]);
    const [userRoles, setUserRoles] = useState([]);
    const [roles, setRoles] = useState([]);
    const [canUpdateTeam, setCanUpdateTeam] = useState(true);
    const [canUpdateOperator, setCanUpdateOperator] = useState(true);
    const theme = useTheme();

    const [openForm, setOpenForm] = useState(false);
    const [openFormOperator, setOpenFormOperator] = useState(false);
    const [openDetail, setOpenDetail] = useState(false);
    const tableRef = useRef();
    const tableRefOperator = useRef();
    const tableRefB = useRef();
    const [dataObject, setDataObject] = useState(0);
    const [dataObjectOperator, setDataObjectOperator] = useState(0);
    const [dataDetail, setDataDetail] = useState(0);
    const [value, setValue] = React.useState(0);
    const [valueA, setValueA] = React.useState(0);
    const [valueOperator, setValueOperator] = React.useState(0);

    const toggleForm = () => {
        if (tableRef.current) {
            tableRef.current.state.query.page = 0;
            if (openForm) {
                tableRef.current.onQueryChange();
            }
        }
        setOpenForm(!openForm);
        setDataObject(0);
    };
    const toggleFormOperator = () => {
        if (tableRefOperator.current) {
            tableRefOperator.current.state.query.page = 0;
            if (openFormOperator) {
                tableRefOperator.current.onQueryChange();
            }
        }
        setOpenFormOperator(!openFormOperator);
        setDataObjectOperator(0);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
        tableRef.current.onQueryChange();
    };

    const handleChangeIndex = (index: number) => {
        setValue(index);
    };

    const messageError = (message, variant) => {
        enqueueSnackbar(message, {
            variant,
        });
    };

    useEffect(() => {
        /*
        hasAuthority(Permissions.GetAllUsers, setCanGetAllUsers);
        hasAuthority(Permissions.GetUserById, setCanGetUser);
        hasAuthority(Permissions.CreateUser, setCanCreateUser);
        hasAuthority(Permissions.UpdateUser, setCanUpdateUser);
        hasAuthority(Permissions.GetUserDataById, setCanGetUserData);
        hasAuthority(Permissions.UpdateUserDataById, setCanUpdateUserData);
        hasAuthority(Permissions.DeleteUser, setCanDeleteUser);
        */
    }, []);

    const initData = () => {
       if (divisions.length === 0) {
           getAllDivisions()
               .then(response => {
                   let division = [];

                   response.forEach((key, p) => {
                       division.push(key);
                   });
                   setDivisions(division);
               })
               .catch(() => {
                   return Promise.resolve(setDivisions([
                       {id: 1, name: "Mujer"},
                       {id: 2, name: "Hombre"},
                       {id: 3, name: "Kids"},
                       {id: 4, name: "Interiores"},
                       {id: 5, name: "Accesorios y calzado"},
                       {id: 6, name: "Otros"}
                   ]));
               });
       }
       if (roles.length === 0) {
           getAllRoles()
               .then(response => {
                   let roles = [];
                   response.content.forEach((key, p) => {
                       if (key.name == "Lift truckers" || key.name == "Pickers" || key.name == "Pallet pullers") {
                           roles.push(key);
                       }
                   });
                   setRoles(roles);
               })
               .catch(() => {
                   return Promise.resolve(setRoles([]));
               });
       }
    };

    const  renderUsersTeam = rowData => {
        return ([
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Piezas totales</TableCell>
                                <TableCell align="center">Piezas procesadas</TableCell>
                                <TableCell align="center">Piezas restantes</TableCell>
                                <TableCell align="center">Progreso</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <StyledTableRow key={row.id}>
                                    <StyledTableCell align="center">{row.pTotales}</StyledTableCell>
                                    <StyledTableCell align="center">{row.pProcesadas}</StyledTableCell>
                                    <StyledTableCell align="center">{row.pRestantes}</StyledTableCell>
                                    <StyledTableCell align="center">

                                        <LinearProgress variant="determinate" value={row.progreso} />
                                        <Typography className={classes.typography}>{row.progreso}</Typography>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>,
                <MaterialTable
                    columns={[
                        { title: 'ID', field: 'id' },
                        { title: 'Nombre', field: 'name' },
                    ]}
                    title=" "
                    localization={materialTableLocaleES}
                    tableRef={tableRefOperator}
                    data={query => getUsersByTeam(query, rowData.id)}
                    options={{
                        search: false,
                        showTitle: false,
                        toolbar: false,
                        paging: false,
                        header: true,
                        actionsColumnIndex: -1,
                        rowStyle: {
                            backgroundColor: '#EEE',
                        },
                    }}
                    actions={[
                        {
                            icon: () => <ShowIcon color="primary" className="icon-small" />,
                            tooltip: 'Cambiar/Asignar Equipo',
                            onClick: (event, rowData) => {
                                setDataObjectOperator(rowData.id);
                                setOpenFormOperator(!openFormOperator);
                            },
                        },
                    ]}
                    icons={{
                        Delete: () => <DeleteIcon color="secondary" className="icon-small" />,
                    }}
                    editable={{
                        onRowDelete: oldData => deleteOperatorTeam(oldData),
                    }}
                />
            ]
        );
    };

    const renderUsersRol = rowData => {
        return (
            <MaterialTable
                columns={[
                    { title: 'ID', field: 'id' },
                    { title: 'Nombre', field: 'name', },
                    { title: 'Equipo', field: 'teamName', },
                ]}
                title=" "
                localization={materialTableLocaleES}
                tableRef={tableRefOperator}
                data={query => getUsersByRol(query, rowData.id)}
                options={{
                    actionsColumnIndex: -1,
                    search: false,
                    showTitle: false,
                    toolbar: false,
                    paging: false,
                    header: true
                }}
                actions={[
                    {
                        icon: () => <ShowIcon color="primary" className="icon-small" />,
                        tooltip: 'Cambiar/Asignar Equipo',
                        onClick: (event, rowData) => {
                            setDataObjectOperator(rowData.id);
                            setOpenFormOperator(!openFormOperator);
                        },
                    },
                ]}
                icons={{
                    Delete: () => <DeleteIcon color="secondary" className="icon-small" />,
                }}
                editable={{
                    onRowDelete: oldData => deleteOperatorTeam(oldData),
                }}
            />
        );
    };

    const getUsersByTeam = (query, idTeam) =>
        getOperatorTeam(idTeam)
            .then(response => {
                let users = [];
                response.forEach((key, p) => {
                    users.push(key);
                });
                console.log(users);
                return {
                    ...query,
                    page: 0,
                    pageSize: 15,
                    totalCount: 0,
                    data: users,
                }
            })
            .catch(() => ({
                ...query,
                page: 0,
                pageSize: 15,
                totalCount: 0,
                data: [],
            }));

    const getUsersByRol = (query, idRol) =>
        getUserByRol(idRol)
            .then(response => {
                let users = [];
                response.forEach((key, p) => {
                    users.push(key);
                });
                return {
                    ...query,
                    page: 0,
                    pageSize: 15,
                    totalCount: 0,
                    data: users,
                }
            })
            .catch(() => ({
                ...query,
                page: 0,
                pageSize: 15,
                totalCount: 0,
                data: [],
            }));

    const tabs = () => {
        if (divisions.length > 0) {
            let nameTabs = [];
            divisions.forEach((value, index) => {
                var tab = <Tab label={value.name} {...a11yProps({index})} />
                nameTabs.push(tab);
            });
            return nameTabs;
        } else {
            return [
                <Tab label="Mujer" {...a11yProps(0)} />,
                <Tab label="Hombre" {...a11yProps(1)} />,
                <Tab label="Kids" {...a11yProps(2)} />,
                <Tab label="Interiores" {...a11yProps(3)} />,
                <Tab label="Accesorios y calzado" {...a11yProps(4)} />,
                <Tab label="Otros" {...a11yProps(5)} />
            ]
        }
    };

    const deleteOperatorTeam = oldData =>
        new Promise((resolve, reject) => {
            setTimeout(() => {
                freeOperatorTeam(oldData.id)
                    .then(() => {
                        console.log(oldData.id);
                        if (tableRef.current) {
                            tableRef.current.state.query.page = 0;
                            tableRef.current.onQueryChange();
                        }
                        messageError('Operador liberado', 'warning');
                    })
                    .catch(() => reject());
                resolve();
            }, 1000);
        });


    const deleteTeamData = oldData =>
        new Promise((resolve, reject) => {
            setTimeout(() => {
                deleteTeam(oldData.id)
                    .then(() => {
                        if (tableRef.current) {
                            tableRef.current.state.query.page = 0;
                            tableRef.current.onQueryChange();
                        }
                        messageError('Equipo eliminado', 'warning');
                    })
                    .catch(() => reject());
                resolve();
            }, 1000);
        });


    const contentTabs = (valueI) => {
        let content = [];
        divisions.forEach((value, index) => {
            if (valueI === index){
                var tab =
                    <MaterialTable
                        columns={[
                            { title: 'ID Equipo', field: 'id', },
                            { title: 'NOMBRE', field: 'name' },
                            { title: 'Descripción', field: 'description'},
                        ]}
                        title=" "
                        localization={materialTableLocaleES}
                        tableRef={tableRef}
                        data={query => getDataTeamsDivision(query, value.id)}
                        options={{
                            actionsColumnIndex: -1,
                            search: false,
                            showTitle: false,
                            toolbar: false,
                            paging: false,
                            header: true
                        }}
                        detailPanel={rowData => renderUsersTeam(rowData)}
                        actions={[
                            {
                                icon: () => <ShowIcon color="primary" className="icon-small" />,
                                tooltip: 'Editar Equipo',
                                onClick: (event, rowData) => {
                                    setDataObject(rowData.id);
                                    setOpenForm(!openForm);
                                },
                            },
                        ]}
                        icons={{
                            Delete: () => <DeleteIcon color="secondary" className="icon-small" />,
                        }}
                        editable={{
                            onRowDelete: oldData => deleteTeamData(oldData),
                        }}
                    />;
                content.push(tab);
            }
        });
        return content;

    };

    const getDataTeamsDivision = (query, division) =>
        getTeamsByDivision(division)
            .then(response => ({
                ...query,
                data: response,
                pageSize: 15,
                page: 0,
                totalCount: 0,
            }))
            .catch(() => ({
                ...query,
                page: 0,
                pageSize: 15,
                totalCount: 0,
                data: [],
            }));

    return (
        <Box id="app-home-waves-page" component="main" className={appStyles.pageRoot}>
            <Grid container spacing={2}>
                {initData()}
                <Grid item xs={12}>
                    <PageHeader
                        title="Equipos de Trabajo"
                        titleButton="Nuevo Equipo"
                        displayButton
                        canExecuteButton
                        action={toggleForm}
                        breadcrumbsData={breadcrumbsData}
                    />
                    <TeamForm
                        formOpen={openForm}
                        toggleForm={toggleForm}
                        refreshTable={val => {
                            setValueA(val);
                            tableRef.current.onQueryChange();
                        }}
                        dataObject={dataObject}
                        canUpdateZone={canUpdateTeam}
                    />
                    <OperatorTeamForm
                        formOpen={openFormOperator}
                        toggleForm={toggleFormOperator}
                        refreshTable={val => {
                            setValueOperator(val);
                            tableRefOperator.current.onQueryChange();
                        }}
                        dataObject={dataObjectOperator}
                        canUpdateOperator={canUpdateOperator}
                    />
                </Grid>
                <Grid item xs={7}>
                    <div className={classes.root}>
                        <AppBar
                            position="static"
                            color="default"
                            style={{backgroundColor: 'white' }}
                        >
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"
                            >
                                {tabs()}
                            </Tabs>
                        </AppBar>
                        {contentTabs(value)}
                    </div>
                </Grid>
                <Grid item xs={5}>
                    <div className={classes.root}>
                        <MaterialTable
                            columns={[
                                { title: 'ID', field: 'id' },
                                { title: 'Tipo Operador', field: 'name', },
                            ]}
                            title="Colaboradores"
                            localization={materialTableLocaleES}
                            tableRef={tableRefB}
                            data={roles}
                            options={{
                                actionsColumnIndex: -1,
                                search: false,
                                paging: false,
                                header: true
                            }}
                            detailPanel={rowData => renderUsersRol(rowData)}
                        />
                    </div>
                </Grid>
            </Grid>
        </Box>

    );
}

export default WithSnackBarMessages(TeamsPage);