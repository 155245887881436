import { apiClientBearerToken } from '../ApiClientConfig';
import Logger from '../../Logger';

const Log = new Logger('src/api/WaveServices/WavesApi.js');

const WAVES_PATH = '/waves';
const ORDER_GROUPS_PATH = '/ordergroups';
const DIVISIONS_PATH = '/divisions';
const ROUTES_PATH = '/routes';
const ZONES_PATH = '/zones';

export const getAllWaves = (
  paginated: Boolean,
  size?: Number = null,
  page?: Number = null,
  search?: String = null,
): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${WAVES_PATH}/getall`, {
      params: {
        paginated,
        size,
        page,
        search: search?.trim(),
      },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getAllWaves.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getAllOrderGroups = (
  paginated: Boolean,
  size?: Number = null,
  page?: Number = null,
  search?: String = null,
): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${ORDER_GROUPS_PATH}/getcurrentweek`, {
      params: { paginated },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getAllOrderGroups.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getAllDepartments = (divisions: String): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${DIVISIONS_PATH}/getdepartments?divisions=${divisions}`, {
      params: {},
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getAllOrderGroups.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getAllRoutes = (
  paginated: Boolean,
  size?: Number = null,
  page?: Number = null,
  search?: String = null,
): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${ROUTES_PATH}/getall`, {
      params: {},
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getAllOrderGroups.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getWaveData = (wave_id: Number): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${ORDER_GROUPS_PATH}/getroutes`, {
      params: {
        wave_id,
      },
    })
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      Log.error(
        getWaveData.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getOrderGroupSkuDetail = (
  order_group: Number,
  sku: Number,
): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${ORDER_GROUPS_PATH}/getskudetail`, {
      params: {
        order_group,
        sku,
      },
    })
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      Log.error(
        getOrderGroupSkuDetail.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getOrderGroupExcel = (order_group: Number): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${ORDER_GROUPS_PATH}/getexcel`, {
      params: {
        order_group,
      },
      responseType: 'blob',
    })
    .then(response => {      
      return Promise.resolve(response);
    })
    .catch(error => {
      Log.error(
        getOrderGroupExcel.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getAllZones = (zoneType: Number, paginated: Boolean): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${ZONES_PATH}/getzones`, {
      params: {
        zoneType,
        paginated,
      },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getAllZones.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getAllZoneTypes = (): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${ZONES_PATH}/getallzonetypes`, {
      params: {        
      },
    })
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      Log.error(
        getAllZoneTypes.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const createWave = (
  orderGroupId: Number,
  divisions: Array,
  divisionsNames: Array,
  excludedDepartments: Array,
  excludedDepartmentsNames: Array,
  excludedRoutes: Array,
  excludedRoutesNames: Array,
): Promise<Object> => {
  return apiClientBearerToken()
    .post(`${WAVES_PATH}/create`, {
      order_group_id: orderGroupId,
      business_rules: {
        divisions,
        divisionsNames,
        excludedDepartments,
        excludedDepartmentsNames,
        excludedRoutes,
        excludedRoutesNames,
      },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      if (error.response) {
        return Promise.reject(error.response.data.errors);
      }
      Log.error(createWave.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const createZone = (
  zoneTypeId: Number,
  description: String,
  code: String,
  location: String,
): Promise<Object> => {
  return apiClientBearerToken()
    .post(`${ZONES_PATH}/createzone`, {
      zone_type_id: zoneTypeId,
      description,
      code,
      location
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      if (error.response) {
        return Promise.reject(error.response.data.errors);
      }
      Log.error(createZone.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const getZoneSticker = (zone_id: Number): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${ZONES_PATH}/${zone_id}/printsticker`, {      
      responseType: 'blob',
    })
    .then(response => {      
      return Promise.resolve(response);
    })
    .catch(error => {
      Log.error(
        getZoneSticker.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getZoneById = (id: Number): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${ZONES_PATH}/${id}`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getZoneById.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const updateZoneById = (
  id: Number,
  zoneTypeId: Number,
  description: String,
  code: String,
  location: String,
): Promise<Object> => {
  return apiClientBearerToken()
    .post(`${ZONES_PATH}/${id}/update`, {
      zone_type_id: zoneTypeId,
      description,
      code,
      location
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      if (error.response) {
        return Promise.reject(error);
      }
      return Promise.reject();
    });
};

export const deleteZone = (id: Number, restore?: Boolean = false): Promise<Object> => {
  return apiClientBearerToken()
    .delete(`${ZONES_PATH}/${id}`, {
      params: {
        restore,
      },
    })
    .then(() => {
      return Promise.resolve();
    })
    .catch(error => {
      Log.error(deleteZone.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const deleteWave = (id: Number, restore?: Boolean = false): Promise<Object> => {
  return apiClientBearerToken()
    .delete(`${WAVES_PATH}/${id}`, {
      params: {
        restore,
      },
    })
    .then(() => {
      return Promise.resolve();
    })
    .catch(error => {
      Log.error(deleteWave.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};
