import { apiClientBearerToken } from '../ApiClientConfig';
import Logger from '../../Logger';

const Log = new Logger('src/api/ShopServices/RoutesApi.js');

const ROUTES_PATH = '/shops/routes';

export const getAllRoutes = (
  paginated: Boolean,
  size?: Number = null,
  page?: Number = null,
  search?: String = null,
  resourceView?: String = 'not_removed',
): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(ROUTES_PATH, {
      params: {
        paginated,
        size,
        page,
        search: search?.trim(),
        resourceView,
      },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getAllRoutes.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getRouteById = (id: Number): Promise<Object> => {
  return apiClientBearerToken()
    .get(`${ROUTES_PATH}/${id}`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(getRouteById.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const updateRouteById = (
  id: Number,
  name: String,
  description: String,
  routeKey: String,
  idColor: Number,
): Promise<Object> => {
  return apiClientBearerToken()
    .patch(`${ROUTES_PATH}/${id}`, {
      name,
      description,
      routeKey,
      color: { id: idColor },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      if (error.response) {
        // if (!error.response.data.errors) {
        //   const errorDuplicate = {
        //     routeDescriptionError: 'Nombre del rol duplicado.',
        //   };
        //   return Promise.reject(errorDuplicate);
        // }
        if (error?.response?.data?.message?.includes('ROUTE_KEY_INDEX')) {
          const errorDuplicate = {
            routeKeyMessageError: 'LLave de route duplicada.',
          };
          return Promise.reject(errorDuplicate);
        }
        const errors = {
          routeNameErrorMessage: null,
          routeDescriptionError: null,
          routeKeyMessageError: null,
          routeColorIdMessageError: null,
        };
        error.response.data.errors.forEach(e => {
          if (e.field === 'route.name') {
            errors.routeNameErrorMessage = e.message;
          }
          if (e.field === 'route.description') {
            errors.routeDescriptionError = e.message;
          }
          if (e.field === 'route.routeKey') {
            errors.routeKeyMessageError = e.message;
          }
          if (e.field === 'route.color.id') {
            errors.routeColorIdMessageError = e.message;
          }
        });

        return Promise.reject(errors);
      }

      Log.error(updateRouteById.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const createRoute = (
  name: String,
  description: String,
  routeKey: String,
  idColor: Number,
): Promise<Object> => {
  return apiClientBearerToken()
    .post(ROUTES_PATH, {
      name,
      description,
      routeKey,
      color: { id: idColor },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      if (error.response) {
        // if (!error.response.data.errors) {
        //   const errorDuplicate = {
        //     routeNameErrorMessage: 'Nombre del rol duplicado.',
        //   };
        //   return Promise.reject(errorDuplicate);
        // }
        if (error?.response?.data?.message?.includes('ROUTE_KEY_INDEX')) {
          const errorDuplicate = {
            routeKeyMessageError: 'LLave de route duplicada.',
          };
          return Promise.reject(errorDuplicate);
        }
        const errors = {
          routeNameErrorMessage: null,
          routeDescriptionError: null,
          routeKeyMessageError: null,
          routeColorIdMessageError: null,
        };
        error.response.data.errors.forEach(e => {
          if (e.field === 'route.name') {
            errors.routeNameErrorMessage = e.message;
          }
          if (e.field === 'route.description') {
            errors.routeDescriptionError = e.message;
          }
          if (e.field === 'route.routeKey') {
            errors.routeKeyMessageError = e.message;
          }
          if (e.field === 'route.color.id') {
            errors.routeColorIdMessageError = e.message;
          }
        });

        return Promise.reject(errors);
      }

      Log.error(createRoute.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const deleteRoute = (id: Number, restore?: Boolean = false): Promise<Object> => {
  return apiClientBearerToken()
    .delete(`${ROUTES_PATH}/${id}`, {
      params: {
        restore,
      },
    })
    .then(() => {
      return Promise.resolve();
    })
    .catch(error => {
      Log.error(deleteRoute.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const getShopRouteByID = (id: Number): Promise<Object> => {
  return apiClientBearerToken()
    .get(`${ROUTES_PATH}/${id}/shops`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(getShopRouteByID.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const addShopRouteByID = (idRoute: Number, id: Number): Promise<Object> => {
  return apiClientBearerToken()
    .patch(`${ROUTES_PATH}/${idRoute}/shops`, {
      id,
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      if (error.response) {
        if (!error.response.data.errors) {
          const errorDuplicate = {
            shopErrorMessage: error.response.data.message,
          };
          return Promise.reject(errorDuplicate);
        }
        const errors = {
          shopIdErrorMessage: null,
        };
        error.response.data.errors.forEach(e => {
          if (e.field === 'route.shop.id') {
            errors.shopIdErrorMessage = e.message;
          }
        });

        return Promise.reject(errors);
      }

      Log.error(addShopRouteByID.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const removeShopByIDRouteById = (id: Number, idShop: Number): Promise<Object> => {
  return apiClientBearerToken()
    .delete(`${ROUTES_PATH}/${id}/shops/${idShop}`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        removeShopByIDRouteById.name,
        error.message,
        error.response ? error.response : error,
      );
      return Promise.reject();
    });
};

export const getAllPortageRouteByID = (id: Number): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${ROUTES_PATH}/${id}/portages`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getAllPortageRouteByID.name,
        error.message,
        error.response ? error.response : error,
      );
      return Promise.reject();
    });
};
