import React, { useState, useRef, useEffect } from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import LoginStyles from '../../Login/styles/LoginStyles';
import PageHeader from "../../../components/PageHeader";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import OrderPickingDetail from '../pages/OrderPickingDetail';
import MaterialTable from "material-table";
import PropTypes from 'prop-types';

// Styles
import { AppStyles } from '../../../AppStyles';
import materialTableLocaleES from "../../../components/MaterialTableLocaleES";

// Routes
import { APP_ROUTES, USERS_ROUTES } from '../../../Routes';
import WithSnackBarMessages from "../../../components/WithSnackBarMessages";
import {getAllOrderGroups} from "../../../api/WaveServices/WavesApi";


const breadcrumbsData = [
    {
        id: 1,
        label: 'Ordenes de Picking',
        base: APP_ROUTES.WAVES,
    }
];


const useStyles = makeStyles(theme => ({
    actionDescriptionEdit: {
        '& .MuiInput-root': {
            fontSize: 'small',
        },
    },
    root: {
        marginTop: '15px',
        marginLeft: '19px',
        marginRight: '19px',
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(3),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    typography: {
        textAlign: 'center',
    },
}));

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

function a11yProps(index: any) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

function a11yPropsTwo(index: any) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}




function PickingPage() {
    const classes = useStyles();
    const appStyles = AppStyles();
    const [openForm, setOpenForm] = useState(false);
    const [openDetail, setOpenDetail] = useState(false);
    const tableRef = useRef();
    const [dataObject, setDataObject] = useState(0);
    const [dataDetail, setDataDetail] = useState(0);
    const [value, setValue] = React.useState(0);
    const [valueA, setValueA] = React.useState(0);

    const toggleForm = () => {
        if (tableRef.current) {
            tableRef.current.state.query.page = 0;
            if (openForm) {
                tableRef.current.onQueryChange();
            }
        }
        setOpenForm(!openForm);
        setDataObject(0);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeTabTwo = (event, newValue) => {
        setValueA(newValue);
    };

    useEffect(() => {
        /*
        hasAuthority(Permissions.GetAllUsers, setCanGetAllUsers);
        hasAuthority(Permissions.GetUserById, setCanGetUser);
        hasAuthority(Permissions.CreateUser, setCanCreateUser);
        hasAuthority(Permissions.UpdateUser, setCanUpdateUser);
        hasAuthority(Permissions.GetUserDataById, setCanGetUserData);
        hasAuthority(Permissions.UpdateUserDataById, setCanUpdateUserData);
        hasAuthority(Permissions.DeleteUser, setCanDeleteUser);
        */
    }, []);

    function createData(id, depto, sku, pzas, prepack, cajas, tarimas, avance, estatus, ubicacion) {
        return { id, depto, sku, pzas, prepack, cajas, tarimas, avance, estatus, ubicacion };
    }

    const rows = [
        createData('OL-200417-1', 'MUJER', 12345678, 2, 2000, 50, 2, 30,  'PICKING', 'STAGING'),
        createData('OL-200417-2', 'MUJER', 12345678, 2, 2000, 50, 2, 15,  'PICKING', 'STAGING'),
        createData('OL-200430-1', 'MUJER', 12345678, 2, 2000, 50, 2, 90,  'PICKING', 'STAGING'),        
    ];

    const renderOrderGroupsDetail = rowData => {

        return (
            <TableContainer >
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">No. Orden Picking</TableCell>
                            <TableCell align="center">Departamento</TableCell>
                            <TableCell align="center">SKU</TableCell>
                            <TableCell align="center">Prepack</TableCell>
                            <TableCell align="center">Piezas</TableCell>
                            <TableCell align="center">Cajas</TableCell>
                            <TableCell align="center">Tarimas</TableCell>
                            <TableCell align="center">Avance</TableCell>
                            <TableCell align="center">Estatus</TableCell>
                            <TableCell align="center">Ubicación</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(row => (
                            <TableRow key={row.id}>
                                <TableCell component="th" scope="row">
                                    <Button
                                        href="#text-buttons"
                                        color="primary"
                                        onClick={
                                            (event, rowData) => {
                                                setDataObject('QWERTY689');
                                                setOpenForm(!openForm);
                                            }
                                        }
                                    >
                                        {row.id}
                                    </Button>
                                </TableCell>
                                <TableCell align="center">{row.depto}</TableCell>
                                <TableCell align="center">{row.sku}</TableCell>
                                <TableCell align="center">{row.prepack}</TableCell>
                                <TableCell align="center">{row.pzas}</TableCell>
                                <TableCell align="center">{row.cajas}</TableCell>
                                <TableCell align="center">{row.tarimas}</TableCell>
                                <TableCell align="center">
                                    <LinearProgress variant="determinate" value={row.avance} />
                                    <Typography className={classes.typography}>{row.avance}%</Typography>
                                </TableCell>
                                <TableCell align="center">{row.estatus}</TableCell>
                                <TableCell align="center">{row.ubicacion}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    const getSupplyOrders = query =>
        getAllOrderGroups(true, query.pageSize, query.page, query.search)

            .then(response => ({
                ...query,
                data: [
                    {id : 'OL-200417-1', piezas: 37909, avance: 40},
                    {id : 'OL-200417-2', piezas: 37909, avance: 20},
                    {id : 'OL-200430-1', piezas: 0, avance: 100},                    
                ],
                page: response.current_page - 1,
                totalCount: response.total,
            }))
            .catch(() => ({
                ...query,
                page: 0,
                pageSize: 15,
                totalCount: 0,
            }));


    return (

        <div className={classes.root}>
            <AppBar position="static" color="default" style={{alignItems:'center', backgroundColor: 'white'}}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    <Tab label="Ordenes Por Olas" {...a11yProps(0)} />
                    <Tab label="Todas las Ordenes" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <PageHeader
                    title="Ordenes de Picking"
                    breadcrumbsData={breadcrumbsData}
                />
                <OrderPickingDetail
                    formOpen={openForm}
                    toggleForm={toggleForm}
                    refreshTable={() => tableRef.current && tableRef.current.onQueryChange()}
                    dataObject={dataObject}
                />
                <Typography variant="h5" color="primary">
                    Olas
                </Typography>
                <MaterialTable
                    columns={[
                        { title: 'ID OLA', field: 'id', },
                        { title: 'Piezas', field: 'piezas' },
                        { title: 'Surtido', field: 'avance',render: rowData => {
                            return [<LinearProgress variant="determinate" value={rowData.avance} /> , <Typography className={classes.typography}>{rowData.avance}%</Typography>]
                            }
                        },
                        { title: '', field: 'piezas',render: rowData => {
                                return [<Button variant="contained" color="primary"  style={{ borderRadius: 30 }}>
                                            <PlayCircleOutlineIcon fontSize="small" style={{marginRight: 4}}/>
                                            LANZAR OLA
                                        </Button>]
                            }
                        },
                        { title: '', field: 'piezas',render: rowData => {
                                return [
                                    <IconButton aria-label="delete" className={classes.margin}>
                                        <PauseCircleOutlineIcon fontSize="large" />
                                    </IconButton>
                                ]
                            }
                        },
                    ]}
                    title=" "
                    localization={materialTableLocaleES}
                    tableRef={tableRef}
                    data={getSupplyOrders}
                    options={{
                        search: false,
                        paging: false,
                        toolbar: true,
                        padding: 'dense',
                    }}
                    detailPanel={rowData => renderOrderGroupsDetail(rowData)}
                />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <PageHeader
                    title="Ordenes de Picking"
                    breadcrumbsData={breadcrumbsData}
                />
                <OrderPickingDetail
                    formOpen={openForm}
                    toggleForm={toggleForm}
                    refreshTable={() => tableRef.current && tableRef.current.onQueryChange()}
                    dataObject={dataObject}
                />
                <AppBar position="static" color="default" style={{alignItems:'center', backgroundColor: 'white'}}>
                    <Tabs
                        value={valueA}
                        onChange={handleChangeTabTwo}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        <Tab label="Mujer" {...a11yPropsTwo(0)} />
                        <Tab label="Hombre" {...a11yPropsTwo(1)} />
                        <Tab label="Kids" {...a11yPropsTwo(2)} />
                        <Tab label="Interiores" {...a11yPropsTwo(3)} />
                        <Tab label="Accesorios" {...a11yPropsTwo(4)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={valueA} index={0}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">No. Orden Picking</TableCell>
                                    <TableCell align="center">Departamento</TableCell>
                                    <TableCell align="center">SKU</TableCell>
                                    <TableCell align="center">Prepack</TableCell>
                                    <TableCell align="center">Piezas</TableCell>
                                    <TableCell align="center">Cajas</TableCell>
                                    <TableCell align="center">Tarimas</TableCell>
                                    <TableCell align="center">Avance</TableCell>
                                    <TableCell align="center">Estatus</TableCell>
                                    <TableCell align="center">Ubicación</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map(row => (
                                    <TableRow key={row.id}>
                                        <TableCell component="th" scope="row">
                                            <Button
                                                href="#text-buttons"
                                                color="primary"
                                                onClick={
                                                    (event, rowData) => {
                                                        setDataObject('OL-200417-1');
                                                        setOpenForm(!openForm);
                                                    }
                                                }
                                            >
                                                {row.id}
                                            </Button>
                                        </TableCell>
                                        <TableCell align="center">{row.depto}</TableCell>
                                        <TableCell align="center">{row.sku}</TableCell>
                                        <TableCell align="center">{row.pzas}</TableCell>
                                        <TableCell align="center">{row.prepack}</TableCell>
                                        <TableCell align="center">{row.cajas}</TableCell>
                                        <TableCell align="center">{row.tarimas}</TableCell>
                                        <TableCell align="center">
                                            <LinearProgress variant="determinate" value={row.avance} />
                                            <Typography className={classes.typography}>{row.avance}%</Typography>
                                        </TableCell>
                                        <TableCell align="center">{row.estatus}</TableCell>
                                        <TableCell align="center">{row.ubicacion}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>
                <TabPanel value={valueA} index={1}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">No. Orden Picking</TableCell>
                                    <TableCell align="center">Departamento</TableCell>
                                    <TableCell align="center">SKU</TableCell>
                                    <TableCell align="center">Prepack</TableCell>
                                    <TableCell align="center">Piezas</TableCell>
                                    <TableCell align="center">Cajas</TableCell>
                                    <TableCell align="center">Tarimas</TableCell>
                                    <TableCell align="center">Avance</TableCell>
                                    <TableCell align="center">Estatus</TableCell>
                                    <TableCell align="center">Ubicación</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map(row => (
                                    <TableRow key={row.id}>
                                        <TableCell component="th" scope="row">
                                            <Button
                                                href="#text-buttons"
                                                color="primary"
                                                onClick={
                                                    (event, rowData) => {
                                                        setDataObject('OL-200417-1');
                                                        setOpenForm(!openForm);
                                                    }
                                                }
                                            >
                                                {row.id}
                                            </Button>
                                        </TableCell>
                                        <TableCell align="center">{row.depto}</TableCell>
                                        <TableCell align="center">{row.sku}</TableCell>
                                        <TableCell align="center">{row.pzas}</TableCell>
                                        <TableCell align="center">{row.prepack}</TableCell>
                                        <TableCell align="center">{row.cajas}</TableCell>
                                        <TableCell align="center">{row.tarimas}</TableCell>
                                        <TableCell align="center">
                                            <LinearProgress variant="determinate" value={row.avance} />
                                            <Typography className={classes.typography}>{row.avance}%</Typography>
                                        </TableCell>
                                        <TableCell align="center">{row.estatus}</TableCell>
                                        <TableCell align="center">{row.ubicacion}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>
            </TabPanel>
        </div>
    );

}

export default PickingPage;