import React, { useState, useRef, useEffect } from 'react';

// Components
import MomentUtils from '@date-io/moment';
import { useSnackbar } from 'notistack';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    Drawer,
    TextField,
    Typography,
    Box,
    Button,
    CircularProgress,
    FormControlLabel,
    Switch,
    FormLabel,
    Card,
    CardContent,
    Tooltip,
    FormControl,
    InputLabel,
    Select,
    IconButton,
    Fab,
    MenuItem,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { FixedSizeList } from 'react-window';
import { Link } from 'react-router-dom';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import {
    ImportExport as PriorityIcon,
    Close as CloseIcon,
    Add as AddIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
    LibraryBooks as UserDataIcon,
    ViewHeadline as LineDraw, Visibility as ViewIcon
} from '@material-ui/icons';
import LayersIcon from '@material-ui/icons/Layers';
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableContainer from "@material-ui/core/TableContainer/TableContainer";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table/Table";
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableBody from "@material-ui/core/TableBody/TableBody";
import {getAllOrderGroups} from "../../../api/WaveServices/WavesApi";
import materialTableLocaleES from "../../../components/MaterialTableLocaleES";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PlayCircleOutlineIcon from "@material-ui/core/SvgIcon/SvgIcon";

// Data Api
const drawerWidth = "35%";
const useStyles = makeStyles(theme => ({
    container: {
        width: drawerWidth,
    },
    containerPaper: {
        width: drawerWidth,
        padding: 20,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
    },
    headerIcon: {
        fontSize: 35,
        marginRight: theme.spacing(1),
    },
    headerCloseButton: {
        padding: theme.spacing(1),
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'space-between',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    selectForm: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    table: {
        minWidth: 650,
    },
    selectStyle: {
        minWidth: 195,
        maxWidth: 195,
    },
    pickerStyle: {
        minWidth: 225,
        maxWidth: 225,
    },
    selectAddButton: {
        padding: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    addRolesPolices: {
        margin: theme.spacing(4),
    },
    card: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    cardContent: {
        padding: theme.spacing(2),
    },
    formCardInput: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    bottomSpace: {
        paddingTop: theme.spacing(2),
    },
    progressWave : {
        marginTop: theme.spacing(1),
        padding: 20,
        alignItems: 'rigth',
    },
    typography: {
        textAlign: 'center',
    },
    inputWave : {
        marginRight: '10px',
    },
    footerBox : {
        marginRight: '10px',
        marginTop: '80%',
    }
}));
const initialState = {
    priorityId : 0,
    namePriority: '',
    orderPriority : 0,
    nameError: false,
    UserNameErrorMessage: '',
    accountDate: moment()
        .add(1, 'M')
        .startOf('day'),
    accountDateError: false,
    accountDateErrorMessage: '',
    credentialDate: moment()
        .add(1, 'M')
        .startOf('day'),
    credentialDateError: false,
    credentialDateErrorMessage: '',
};

function OrderPickingDetail({
                               formOpen,
                               toggleForm,
                               refreshTable,
                               dataObject,
                           }) {

    const classes = useStyles();
    const tableRef = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [form, setForm] = useState(initialState);
    const [closeClick, setCloseClick] = useState('');
    const [editForm, setEditForm] = useState(false);
    const [open, setOpen] = useState(false);

    function createData(id, sku, cajas) {
        return { id, sku, cajas };
    }
    const rows = [
        createData(1, 12345678, 50),
        createData(2, 12345678, 50),
        createData(3, 12345678, 50),
        createData(4, 12345678, 50),
        createData(5, 12345678, 50),
    ];

    const renderOrderGroupsDetail = rowData => {

        return (
            <TableContainer>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">ID</TableCell>
                            <TableCell align="center">SKU</TableCell>
                            <TableCell align="center">Cajas</TableCell>
                            <TableCell align="center"> </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(row => (
                            <TableRow key={row.id}>
                                <TableCell component="th" scope="row">{row.id}</TableCell>
                                <TableCell align="center">{row.sku}</TableCell>
                                <TableCell align="center">{row.cajas}</TableCell>
                                <TableCell align="center">
                                    <IconButton aria-label="delete" className={classes.margin}>
                                        <DeleteIcon color='error' className="icon-small" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    const getSupplyOrders = query =>
        getAllOrderGroups(true, query.pageSize, query.page, query.search)

            .then(response => ({
                ...query,
                data: [
                    {id : 1, lpn: 'QWERTY111', cajas: 25, piezas: 420, ubicacion : 'Staging', status: 1},
                    {id : 2, lpn: 'QWERTY123', cajas: 25, piezas: 623, ubicacion : 'Staging', status: 1},
                    {id : 3, lpn: 'QWERTY456', cajas: 25, piezas: 312, ubicacion : 'Staging', status: 1},
                    {id : 4, lpn: 'QWERTY789', cajas: 25, piezas: 620, ubicacion : 'Staging', status: 1},
                    {id : 5, lpn: 'QWERTY112', cajas: 25, piezas: 540, ubicacion : 'Staging', status: 1},
                    {id : 6, lpn: 'QWERTY432', cajas: 25, piezas: 304, ubicacion : 'Piso', status: 0},
                    {id : 7, lpn: 'QWERTY114', cajas: 25, piezas: 803, ubicacion : 'Piso', status: 0},
                ],
                page: response.current_page - 1,
                totalCount: response.total,
            }))
            .catch(() => ({
                ...query,
                page: 0,
                pageSize: 15,
                totalCount: 0,
            }));


    return (
        <Box>
            <Drawer
                anchor="right"
                open={formOpen}
                onClose={toggleForm}
                className={classes.container}
                classes={{ paper: classes.containerPaper }}
            >
                <Box className={classes.header}>
                    <LayersIcon color="primary" className={classes.headerIcon} />
                    <Box flexGrow={4}>
                        <Typography variant="h5" color="primary">
                            QWERTY123
                        </Typography>
                    </Box>
                    <IconButton
                        color="secondary"
                        className={classes.headerCloseButton}
                        onClick={() => {
                            toggleForm();
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={12}>

                    </Grid>
                    <Grid item xs={12}>
                        <Box component="form" className={classes.form} style={{borderBottom:'1px solid rgb(222,205,205)'}}>
                            <Box className={classes.inputWave}>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            name="waveDivision"
                                            label="División"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            value="Mujer"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            name="waveSku"
                                            label="SKU"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            value="1234567"
                                            disabled
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box className={classes.inputWave}>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            name="wavePiezas"
                                            label="Piezas"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            value="2000"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            name="wavePrepack"
                                            label="Prepack"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            value="2"
                                            disabled
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box className={classes.inputWave}>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            name="wavePrepackCaja"
                                            label="Prepack x Caja"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            value="25"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            name="wavePiezasCaja"
                                            label="Piezas x caja"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            value="50"
                                            disabled
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box className={classes.inputWave} style={{marginBottom:'20px'}}>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            name="waveTarima"
                                            label="Tarima"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            value="16"
                                            disabled
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5" color="primary">
                            Tarimas
                        </Typography>
                        <MaterialTable
                            columns={[
                                { title: 'No', field: 'id', },
                                { title: 'LPN', field: 'lpn' },
                                { title: 'Cajas', field: 'cajas' },
                                { title: 'Piezas', field: 'piezas' },
                                { title: 'Ubicacion', field: 'ubicacion' },
                                { title: '', field: 'status',render: rowData => {
                                        return (rowData.status === 1) ? <AssignmentTurnedInIcon  color='primary' fontSize="small" style={{marginRight: 4}}/> : <AssignmentIcon color='primary' fontSize="small" style={{marginRight: 4}}/>
                                    }
                                },
                            ]}
                            title=" "
                            localization={materialTableLocaleES}
                            tableRef={tableRef}
                            data={getSupplyOrders}
                            options={{
                                search: false,
                                paging: false,
                                toolbar: true,
                                padding: 'dense',
                            }}
                            detailPanel={rowData => renderOrderGroupsDetail(rowData)}
                        />
                    </Grid>
                </Grid>
            </Drawer>
        </Box>
    );
}


OrderPickingDetail.defaultProps = {
    refreshTable: null,
    dataObject: 0,
};

OrderPickingDetail.propTypes = {
    refreshTable: PropTypes.func,
    formOpen: PropTypes.bool.isRequired,
    toggleForm: PropTypes.func.isRequired,
    dataObject: PropTypes.number,
};

export default OrderPickingDetail;