import { Cookies } from 'react-cookie';
import SimpleCrypto from 'simple-crypto-js';
import AppConfig from './AppConfig';

const simpleCrypto = new SimpleCrypto(AppConfig.COOKIE_SECRET);
const cookies = new Cookies();
const cookiesOptions = {
  path: '/',
  domain: AppConfig.COOKIE_DOMAIN,
};

export const setCookie = (name: String, value: any) => {
  const n = name === 'user_token' && AppConfig.COOKIE_ENCRYPT ? AppConfig.COOKIE_USER_TOKEN : name;
  const v = AppConfig.COOKIE_ENCRYPT ? simpleCrypto.encrypt(value) : value;
  cookies.set(n, v, cookiesOptions);
};

export const getCookie = (name: String) => {
  const n = name === 'user_token' && AppConfig.COOKIE_ENCRYPT ? AppConfig.COOKIE_USER_TOKEN : name;
  if (cookies.get(n)) {
    return AppConfig.COOKIE_ENCRYPT ? simpleCrypto.decrypt(cookies.get(n), true) : cookies.get(n);
  }
  return undefined;
};

export const removeCookie = (name: String) => {
  const n = name === 'user_token' && AppConfig.COOKIE_ENCRYPT ? AppConfig.COOKIE_USER_TOKEN : name;
  cookies.remove(n, cookiesOptions);
};
