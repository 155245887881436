import React, { useState, useRef } from 'react';
import { Drawer, TextField, Typography, Box, Button, CircularProgress, Tooltip, IconButton, Fab} from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import {
  Edit as EditIcon,
  Close as CloseIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  HowToReg as RolesIcon,
} from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
// Components
import SnackBarMessage from '../../../components/SnackBarMessage';
// Data Api
import { getAllPolicies } from '../../../api/PoliciesApi';
import { createRole, getRoleById, getPoliciesRoleById, updateRoleById } from '../../../api/RoleApi';

const drawerWidth = 450;
const useStyles = makeStyles(theme => ({
  container: {
    width: drawerWidth,
  },
  containerPaper: {
    width: drawerWidth,
    padding: 20,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  headerIcon: {
    fontSize: 35,
    marginRight: theme.spacing(1),
    alignSelf: 'baseline',
  },
  headerCloseButton: {
    padding: theme.spacing(1),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  selectForm: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  selectStyle: {
    minWidth: 355,
    maxWidth: 355,
  },
  selectAddButton: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  bottomSpace: {
    paddingTop: theme.spacing(2),
  },
}));

const initialState = {
  roleId: 0,
  roleName: ' ',
  roleNameError: false,
  roleNameErrorMessage: '',
  roleDescription: ' ',
  roleDescriptionError: false,
  roleDescriptionErrorMessage: '',
  policyAccessError: false,
  roleAccessMessage: '',
  roleDuplicateMessageError: false,
  roleDuplicateMessage: '',
};

const roleData = {
  id: '',
  name: '',
  description: '',
};

function RoleForm({ formOpen, toggleForm, refreshTable, dataObject, canUpdateRole }) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState(initialState);
  const [closeClick, setCloseClick] = useState('');
  const tablePoliciesRef = useRef();
  const [editForm, setEditForm] = useState(false);
  const [dataApp, setDataApp] = useState([]);
  const [roleForm, setRoleForm] = useState(roleData);
  const [updateRole, setUpdateRole] = useState({ accessPolicy: [] });

  const messageError = (message, variant) => {
    enqueueSnackbar(message, {
      variant,
    });
  };

  const msgBin = (
    <Typography component="span" variant="caption">
      El rol ya se encuentra registrado,si no puede verlo en esta sección revise la
      <Link to="/more/bin/users?tab=1"> Papelera</Link>
    </Typography>
  );

  const policyOptions = dataApp.map(option => {
    const firstLetter = option.name[0];
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });

  const handleChange = event => {
    setForm({
      ...form,
      roleId: dataObject,
      [event.target.name]: event.target.value,
    });
    setCloseClick(true);
  };

  const handleSubmit = event => {
    event.preventDefault();
    setIsLoading(true);
    const roles = [];
    updateRole.accessPolicy.forEach(role => {
      roles.push(role.id);
    });

    if (dataObject === 0) {
      createRole(form.roleName, form.roleDescription, roles)
        .then(() => {
          toggleForm();
          setForm(initialState);
          setIsLoading(false);
          refreshTable();
        })
        .catch(error => {
          if (error !== undefined) {
            setForm({
              ...form,
              roleNameError: !!error.roleNameErrorMessage,
              roleNameErrorMessage: error.roleNameErrorMessage,
              roleDescriptionError: !!error.roleDescriptionError,
              roleDescriptionErrorMessage: error.roleDescriptionError,
              policyAccessError: !!error.roleAccessMessage,
              roleAccessMessage: error.roleAccessMessage,
              roleDuplicateMessageError: !!error.roleDuplicateMessage,
              roleDuplicateMessage: error.roleDuplicateMessage,
            });
          } else {
            messageError('Servicio no disponible.', 'warning');
            refreshTable();
          }
          setIsLoading(false);
        });
    } else {
      updateRoleById(form.roleId, form.roleName, form.roleDescription, roles)
        .then(() => {
          toggleForm();
          setForm(initialState);
          setIsLoading(false);
          refreshTable();
        })
        .catch(error => {
          if (error !== undefined) {
            setForm({
              ...form,
              roleNameError: !!error.roleNameErrorMessage,
              roleNameErrorMessage: error.roleNameErrorMessage,
            });
          } else {
            messageError('Servicio no disponible.', 'warning');
            refreshTable();
          }
          setIsLoading(false);
        });
    }
  };

  const clearData = () => {
    setForm(initialState);
    setCloseClick('');
    setEditForm(false);
    setUpdateRole({ accessPolicy: [] });
  };

  const initData = () => {
    console.log(dataObject);
    if (dataObject === 0) {
      setForm({
        form,
        roleId: 0,
        roleName: '',
        roleDescription: '',
      });
    } else {
      getRoleById(dataObject)
        .then(response => {
          setForm({
            roleId: response.id,
            roleName: response.name,
            roleDescription: response.description,
          });
        })
        .catch(() => {
          return Promise.resolve(setUpdateRole({ accessPolicy: [] }));
        });
      getPoliciesRoleById(dataObject)
        .then(response => {
          return Promise.resolve(setUpdateRole({ accessPolicy: response }));
        })
        .catch(() => {
          return Promise.resolve({ accessPolicy: [] });
        });
    }

    setCloseClick(false);
    getAllPolicies(false, 0, 0)
        .then(response => {
          const policies = [];
          if (response.content.length) {
            response.content.forEach(policy => {
              //if (policy.name_access !== 'MOTUAccess') {
              if (policy.name !== 'ALL') {
                policies.push(policy);
              }
            });
          }
          return Promise.resolve(setDataApp(policies));
        })
        .catch(() => {
          return Promise.resolve(setDataApp([]));
        });
    dataObject !== 0 ? setEditForm(false) : setEditForm(true);
  };

  const disableFormControl = () => {
    if (isLoading) {
      return true;
    }
    if (dataObject === 0) {
      return false;
    }
    if (dataObject !== 0 && editForm) {
      return false;
    }
    return true;
  };

  return (
    <Box>
      <Drawer
        anchor="right"
        open={formOpen}
        onClose={toggleForm}
        className={classes.container}
        classes={{ paper: classes.containerPaper }}
      >
        <Box className={classes.header}>
          <RolesIcon color="primary" className={classes.headerIcon} />
          <Box flexGrow={1}>
            <Typography variant="h5" color="primary">
              {dataObject === 0 ? ' Nuevo Rol' : 'Actualizar Rol'}
            </Typography>
          </Box>
          {dataObject !== 0 && (
            <IconButton
              disabled={!canUpdateRole}
              color="primary"
              className={classes.headerCloseButton}
              onClick={() => {
                setEditForm(!editForm);
              }}
            >
              <EditIcon />
            </IconButton>
          )}
          <IconButton
            color="secondary"
            className={classes.headerCloseButton}
            onClick={() => {
              toggleForm();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Box component="form" className={classes.form} onSubmit={handleSubmit}>
          <Box>
            <TextField
              error={form.roleNameError}
              helperText={
                form.roleNameErrorMessage === 'Nombre del rol duplicado.'
                  ? msgBin
                  : form.roleNameErrorMessage
              }
              name="roleName"
              label="Nombre"
              margin="normal"
              variant="outlined"
              fullWidth
              disabled={disableFormControl()}
              value={form.roleName}
              onChange={handleChange}
            />
            <TextField
              error={form.roleDescriptionError}
              helperText={form.roleDescriptionErrorMessage}
              name="roleDescription"
              label="Descripción"
              multiline
              rows="4"
              margin="normal"
              variant="outlined"
              fullWidth
              disabled={disableFormControl()}
              value={form.roleDescription}
              onChange={handleChange}
            />

            <Box className={classes.selectForm}>
              <Box className={classes.selectStyle}>
                <Autocomplete
                  id="combo-box-roles"
                  options={policyOptions.sort(
                    (a, b) => -b.firstLetter.localeCompare(a.firstLetter),
                  )}
                  renderOption={option => (
                    <option value={option.id} name={option.id} key={option.id}>
                      {`${option.name}`}
                    </option>
                  )}
                  disabled={disableFormControl()}
                  getOptionLabel={option => `${option.name}`}
                  noOptionsText="No hay políticas disponibles"
                  autoComplete
                  onChange={(event, value) =>
                    setRoleForm({
                      id: value?.id || '',
                      name: value?.name || '',
                      description: value?.description || '',
                    })
                  }
                  renderInput={params => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      label="Políticas"
                      variant="standard"
                      fullWidth
                      multiline
                    />
                  )}
                />
              </Box>
              <Box>
                <Fab
                  size="small"
                  className={classes.selectAddButton}
                  color="primary"
                  onClick={() => {
                    const tempPolicy = { ...updateRole };
                    tempPolicy.accessPolicy.push(roleForm);
                    setUpdateRole(tempPolicy);
                    setRoleForm({ ...roleForm, id: '' });
                  }}
                  disabled={roleForm.id === ''}
                >
                  <Tooltip title="Agregar Política" aria-label="add">
                    <AddIcon />
                  </Tooltip>
                </Fab>
              </Box>
            </Box>

            <MaterialTable
              title="Políticas"
              tableRef={tablePoliciesRef}
              columns={[
                { title: 'Política', field: 'name', type: 'string' },
                { title: 'Descripción', field: 'description', type: 'string' },
              ]}
              data={updateRole.accessPolicy}
              options={{
                toolbar: true,
                search: false,
                paging: false,
                actionsColumnIndex: -1,
                toolbarButtonAlignment: 'right',
                sorting: true,
                addRowPosition: 'first',
                headerStyle: {
                  headerStyle: {
                    paddingTop: 0,
                  },
                },
                padding: 'dense',
                detailPanelColumnAlignment: 'right',
              }}
              localization={{
                body: {
                  emptyDataSourceMessage: 'No hay políticas que mostrar',
                  editRow: {
                    deleteText: '¿Esta seguro de borrar la política seleccionada?',
                    saveTooltip: 'Aceptar',
                    cancelTooltip: 'Cancelar',
                  },
                  deleteTooltip: 'Eliminar Política',
                },
                header: {
                  actions: 'Acciones',
                },
              }}
              icons={{
                Delete: () => <DeleteIcon color="secondary" />,
              }}
              editable={
                !disableFormControl()
                  ? {
                      onRowDelete: oldData =>
                        new Promise(resolve => {
                          setTimeout(() => {
                            {
                              const deleteApplicationAction = { ...updateRole };
                              const index = deleteApplicationAction.accessPolicy.indexOf(oldData);
                              deleteApplicationAction.accessPolicy.splice(index, 1);
                              setUpdateRole(deleteApplicationAction);
                            }
                            resolve();
                          }, 200);
                        }),
                    }
                  : undefined
              }
            />
          </Box>

          <Box className={classes.bottomSpace}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={disableFormControl()}
              style={{ borderRadius: 30 }}
              size="small"
            >
              {dataObject === 0 ? 'Guardar Rol' : 'Actualizar Rol'}
            </Button>

            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            {formOpen && closeClick === '' && initData()}
            {!formOpen && closeClick !== '' && clearData()}
          </Box>
        </Box>
      </Drawer>

      <SnackBarMessage
        open={form.policyAccessError}
        message={form.roleAccessMessage}
        onClose={() => setForm({ ...form, policyAccessError: false })}
        variant="warning"
      />
    </Box>
  );
}

RoleForm.defaultProps = {
  refreshTable: null,
  dataObject: 0,
  canUpdateRole: false,
};

RoleForm.propTypes = {
  refreshTable: PropTypes.func,
  formOpen: PropTypes.bool.isRequired,
  toggleForm: PropTypes.func.isRequired,
  dataObject: PropTypes.number,
  canUpdateRole: PropTypes.bool,
};

export default RoleForm;
