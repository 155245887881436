import React, { useState, useRef } from 'react';
import {
  Drawer,
  TextField,
  Typography,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Checkbox,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import {
  Edit as EditIcon,
  Close as CloseIcon,
  HowToReg as RolesIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  CheckBoxOutlineBlank,
} from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
// Components
import SnackBarMessage from '../../../components/SnackBarMessage';

// Data Api
import { getRouteById, updateRouteById, createRoute } from '../../../api/ShopServices/RoutesApi';
import { getAllOrderGroups, getAllDepartments, getAllRoutes, createWave } from '../../../api/WaveServices/WavesApi';
import { getAllColors } from '../../../api/ShopServices/Catalogues/ColorsApi';

const drawerWidth = 450;
const icon = <CheckBoxOutlineBlank fontSize="small" />;
const useStyles = makeStyles(theme => ({
  container: {
    width: drawerWidth,
  },
  containerPaper: {
    width: drawerWidth,
    padding: 20,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  selectOrderGroup: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  selectFormOrderGroup: {
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  spaceOption: { padding: theme.spacing(1) },
  headerIcon: {
    fontSize: 35,
    marginRight: theme.spacing(1),
    alignSelf: 'baseline',
  },
  headerCloseButton: {
    padding: theme.spacing(1),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  selectForm: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  selectStyle: {
    minWidth: '100%',
    maxWidth: '100%',
  },
  selectShops: {
    minWidth: 355,
    maxWidth: 355,
  },

  selectAddButton: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  bottomSpace: {
    paddingTop: theme.spacing(2),
  },
  actionDescriptionEdit: {
    '& .MuiInput-root': {
      fontSize: 'small',
    },
  },
}));

const initialState = {
  orderGroupId: 0,
  classification: "1",
  divisions: [],
  divisionsNames: [],
  excludedDepartments: [],
  excludedDepartmentsNames: [],
  excludedRoutes: [],
  excludedRoutesNames: [],
  orderGroupError: false,
  orderGroupErrorMessage: '',
};

export default function WaveForm({
  formOpen,
  toggleForm,
  refreshTable,
  dataObject,
  canUpdateRole,
  match,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState(initialState);
  const [closeClick, setCloseClick] = useState('');
  const [allOrderGroups, setAllOrderGroups] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [editForm, setEditForm] = useState(false);

  const messageError = (message, variant) => {
    enqueueSnackbar(message, {
      variant,
    });
  };

  const handleChange = event => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });

    setCloseClick(true);
  };

  const handleSubmit = event => {
    event.preventDefault();
    setIsLoading(true);

    if (dataObject === 0) {
      createWave(
        form.orderGroupId,
        form.divisions,
        form.divisionsNames,
        form.excludedDepartments,
        form.excludedDepartmentsNames,
        form.excludedRoutes,
        form.excludedRoutesNames,
      )
        .then(() => {
          toggleForm();
          setForm(initialState);
          setIsLoading(false);
          refreshTable();
        })
        .catch(error => {
          if (error !== undefined) {
            console.log(error);
          } else {
            refreshTable();
            messageError('Servicio no disponible.', 'warning');
          }
          setIsLoading(false);
        });
    } else {
      updateRouteById(
        form.routeId,
        form.routeName,
        form.routeDescription,
        form.routeKey,
        form.colorId === 0 ? '' : form.colorId,
      )
        .then(() => {
          toggleForm();
          setForm(initialState);
          setIsLoading(false);
          refreshTable();
        })
        .catch(error => {
          if (error !== undefined) {
            setForm({
              ...form,
              routeNameError: !!error.routeNameErrorMessage,
              routeNameErrorMessage: error.routeNameErrorMessage,
              routeDescriptionError: !!error.routeDescriptionError,
              routeDescriptionErrorMessage: error.routeDescriptionError,
              routeKeyError: !!error.routeKeyMessageError,
              routeKeyErrorMessage: error.routeKeyMessageError,
              orderGroupError: !!error.routeColorIdMessageError,
              orderGroupErrorMessage: error.routeColorIdMessageError,
            });
          } else {
            refreshTable();
            messageError('Servicio no disponible.', 'warning');
          }
          setIsLoading(false);
        });
    }
  };

  const options = allOrderGroups.map(option => {
    const firstLetter = option.order_group[0];
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });

  const clearData = () => {
    setForm(initialState);
    setCloseClick('');
    setEditForm(false);
    setIsLoading(false);
  };

  const optionStartOrderGroup = () => {
    let selectedOrderGroup = null;
    if (allOrderGroups.length > 0)
      allOrderGroups.forEach(orderGroup => {
        if (orderGroup.order_group_id === form.orderGroupId) {
          selectedOrderGroup = orderGroup;
        }
      });
    return selectedOrderGroup;
  };

  const initData = () => {
    if (dataObject === 0) {
      setForm(initialState);
    } else {
      getRouteById(dataObject)
        .then(response => {
          setForm({
            routeId: response.id,
            routeName: response.name,
            routeDescription: response.description,
            routeKey: response.routeKey,
            colorId: response.color.id,
          });
        })
        .catch(() => {
          refreshTable();
          toggleForm();
          messageError('Servicio no disponible.', 'warning');
        });
    }

    getAllOrderGroups(false, 0, 0)
      .then(response => {
        setAllOrderGroups(response);
      })
      .catch(error => {
        console.log(error);
        setAllOrderGroups([]);
      });

    getAllRoutes(false, 0, 0)
      .then(response => {
        setRoutes(response);
      })
      .catch(error => {
        console.log(error);
        setRoutes([]);
      });

    getAllDepartments('1,2,3,4,5')
      .then(response => {
        let divisions = response;
        let departments = [];
        divisions.forEach(division => {
          division.departments.forEach(department => {            
            departments.push({id: department.id, department: department.name, division: division.name});
          });            
        });
        setDepartments(departments);
      })
      .catch(error => {
        console.log(error);
        setDepartments([]);
      });

    const divs = [
      { id: 1, division: 'Mujer', pieces: 0 },
      { id: 2, division: 'Hombre', pieces: 0 },
      { id: 3, division: 'Kids', pieces: 0 },
      { id: 4, division: 'Interiores', pieces: 0 },
      { id: 5, division: 'Accesorios', pieces: 0 },
    ];
    setDivisions(divs);

    setCloseClick(false);

    dataObject !== 0 ? setEditForm(false) : setEditForm(true);
  };

  const disableFormControl = () => {
    if (isLoading) {
      return true;
    }
    if (dataObject === 0) {
      return false;
    }
    if (dataObject !== 0 && editForm) {
      return false;
    }
    return true;
  };

  return (
    <Box>
      <Drawer
        anchor="right"
        open={formOpen}
        onClose={toggleForm}
        className={classes.container}
        classes={{ paper: classes.containerPaper }}
      >
        <Box className={classes.header}>
          <RolesIcon color="primary" className={classes.headerIcon} />
          <Box flexGrow={1}>
            <Typography variant="h5" color="primary">
              {dataObject === 0 ? ' Nueva Ola' : 'Actualizar Ola'}
            </Typography>
          </Box>
          {dataObject !== 0 && (
            <IconButton
              disabled={!canUpdateRole}
              color="primary"
              className={classes.headerCloseButton}
              onClick={() => {
                setEditForm(!editForm);
              }}
            >
              <EditIcon />
            </IconButton>
          )}
          <IconButton
            color="secondary"
            className={classes.headerCloseButton}
            onClick={() => {
              toggleForm();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className={classes.selectFormOrderGroup}>
          <Autocomplete
            id="combo-box-ordergroup"
            options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
            renderOption={option => (
              <div className={classes.selectOrderGroup}>
                <Box className={classes.spaceOption}>
                  <option value={option.title} name={option.id} key={option.id}>
                    {`${option.order_group} / ${option.total_pending.toLocaleString()} Pzs.`}
                  </option>
                </Box>
              </div>
            )}
            noOptionsText="No hay ordenes de surtido disponibles"
            disabled={disableFormControl()}
            getOptionLabel={option =>
              `${option.order_group} / ${option.total_pending.toLocaleString()} Pzs.`
            }
            value={optionStartOrderGroup()}
            autoComplete
            onChange={(event, value) => {
              setForm({ ...form, orderGroupId: value?.order_group_id || 0 });
              setDivisions(value?.divisions);
            }}
            renderInput={params => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                label="Ordenes de surtido"
                variant="outlined"
                placeholder="Seleccione una orden de surtido"
                fullWidth
                error={form.orderGroupError}
                helperText={form.orderGroupErrorMessage}
              />
            )}
          />
        </Box>
        <Box className={classes.selectFormOrderGroup}>
          <Autocomplete
            multiple
            id="divisions-select"
            options={divisions}
            disableCloseOnSelect
            getOptionLabel={option =>
              `${option.division[0].toUpperCase() + option.division.slice(1)}`
            }            
            renderOption={(option, { selected }) => (
              <div className={classes.selectOrderGroup}>
                <Box>
                  <Checkbox
                    icon={icon}
                    style={{ marginRight: 8 }}
                    color="primary"
                    checked={selected}

                  />
                  {`${option.division[0].toUpperCase() +
                    option.division.slice(1)} - ${option.pending.toLocaleString()} Pzs.`}
                </Box>
              </div>
            )}
            onChange={(event, values) => {              
              let divisions = [];
              let divisionsNames = [];
              values.forEach(value => {
                divisions.push(value.id);
                divisionsNames.push(value.division[0].toUpperCase() + value.division.slice(1));
              })
              setForm({ ...form, divisions, divisionsNames });
            }}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label="Divisiones"
                placeholder="Seleccione una o mas divisiones"
                fullWidth
              />
            )}
          />
        </Box>

        <FormControl
          component="fieldset"
          style={{ margin: '20px' }}
          className={classes.formControl}
        >
          <FormLabel component="legend">Clasificación</FormLabel>
          <RadioGroup
            aria-label="classification"
            name="classification"
            value={form.classification}
            onChange={(event, value) => {
              setForm({ ...form, classification: value });
            }}
            row
          >
            <FormControlLabel value="1" control={<Radio color="primary" />} label="Partes Altas" />
            <FormControlLabel value="2" control={<Radio color="primary" />} label="Partes Bajas" />
          </RadioGroup>
        </FormControl>

        <Box className={classes.selectFormOrderGroup}>
          <Autocomplete
            multiple
            id="departments-select"
            options={departments}
            disableCloseOnSelect
            getOptionLabel={option =>
              `${option.department}`
            }
            groupBy={option => option.division}
            onChange={(event, values) => {
              let excludedDepartments = [];
              let excludedDepartmentsNames = [];
              values.forEach(value => {
                excludedDepartments.push(value.id);
                excludedDepartmentsNames.push(value.department);
              })
              setForm({ ...form, excludedDepartments, excludedDepartmentsNames });
            }}
            renderOption={(option, { selected }) => (
              <div className={classes.selectOrderGroup}>
                <Box>
                  <Checkbox
                    icon={icon}
                    style={{ marginRight: 8 }}
                    color="primary"
                    checked={!selected}
                  />
                  {option.department}
                </Box>
              </div>
            )}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label="Excluir los departamentos..."
                placeholder="Seleccione uno o más departamentos a excluir"
                fullWidth
              />
            )}
          />
        </Box>

        <Box className={classes.selectFormOrderGroup}>
          <Autocomplete
            multiple
            id="routes-select"
            options={routes}
            disableCloseOnSelect
            getOptionLabel={option =>
              `${option.name} - ${option.description}`
            }
            onChange={(event, values) => {
              let excludedRoutes = [];
              let excludedRoutesNames = [];
              values.forEach(value => {
                excludedRoutes.push(value.id);
                excludedRoutesNames.push(value.name);
              })
              setForm({ ...form, excludedRoutes, excludedRoutesNames });
            }}
            renderOption={(option, { selected }) => (
              <div className={classes.selectOrderGroup}>
                <Box>
                  <Checkbox
                    icon={icon}
                    style={{ marginRight: 8 }}
                    color="primary"
                    checked={!selected}
                  />
                  {`${option.name} - ${option.description}`}
                </Box>
              </div>
            )}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label="Excluir las rutas..."
                placeholder="Seleccione una o más rutas a excluir"
                fullWidth
              />
            )}
          />
        </Box>

        <Box component="form" className={classes.form} onSubmit={handleSubmit}>
          <Box />
          <Box className={classes.bottomSpace}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={disableFormControl()}
              style={{ borderRadius: 30 }}
              size="small"
            >
              {dataObject === 0 ? 'Guardar Ola' : 'Actualizar Ola'}
            </Button>

            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            {formOpen && closeClick === '' && initData()}
            {!formOpen && closeClick !== '' && clearData()}
          </Box>
        </Box>
      </Drawer>

      <SnackBarMessage
        open={form.policyAccessError}
        message={form.roleAccessMessage}
        onClose={() => setForm({ ...form, policyAccessError: false })}
        variant="warning"
      />
    </Box>
  );
}

WaveForm.defaultProps = {
  refreshTable: null,
  dataObject: 0,
  canUpdateRole: false,
  canAddShopToRoute: false,
  canRemoveShopFromRoute: false,
};

WaveForm.propTypes = {
  refreshTable: PropTypes.func,
  formOpen: PropTypes.bool.isRequired,
  toggleForm: PropTypes.func.isRequired,
  dataObject: PropTypes.number,
  canUpdateRole: PropTypes.bool,
};
