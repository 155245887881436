import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  loader: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  gridItem: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  dataText: {
    color: grey[900],
  },
}));

export default function DataTimeDetail({ fullRow, rowData }) {
  const classes = useStyles();

  return (
    <Grid container>
      {rowData.createdAt && rowData.createdBy ? (
        <Grid item sm={12} md={fullRow ? 12 : 4} className={classes.gridItem}>
          <Typography variant="subtitle2">Fecha de Creación</Typography>
          <Typography className={classes.dataText}>
            {moment
              .utc(rowData.createdAt)
              .local()
              .format('MMM DD YYYY - hh:mm:ss a')}
          </Typography>
          <Typography variant="subtitle2">Creado por</Typography>
          <Typography className={classes.dataText}>
            {rowData.createdByUsername ? rowData.createdByUsername : '- - - -'}
          </Typography>
        </Grid>
      ) : (
        <Grid item sm={12} md={fullRow ? 12 : 4} className={classes.gridItem} />
      )}
      {rowData.updatedAt && rowData.updatedBy ? (
        <Grid item sm={12} md={fullRow ? 12 : 4} className={classes.gridItem}>
          <Typography variant="subtitle2">Ultima Modificación</Typography>
          <Typography className={classes.dataText}>
            {moment
              .utc(rowData.updatedAt)
              .local()
              .format('MMM DD YYYY - hh:mm:ss a')}
          </Typography>
          <Typography variant="subtitle2">Modificado por</Typography>
          <Typography className={classes.dataText}>
            {rowData.updatedByUsername ? rowData.updatedByUsername : '- - - -'}
          </Typography>
        </Grid>
      ) : (
        <Grid item sm={12} md={fullRow ? 12 : 4} className={classes.gridItem} />
      )}
      {rowData.deletedAt && rowData.deletedBy ? (
        <Grid item sm={12} md={fullRow ? 12 : 4} className={classes.gridItem}>
          <Typography variant="subtitle2">
            {rowData.isDeleted ? 'Eliminado' : 'Restaurado'}
          </Typography>
          <Typography className={classes.dataText}>
            {moment
              .utc(rowData.deletedAt)
              .local()
              .format('MMM DD YYYY - hh:mm:ss a')}
          </Typography>
          <Typography variant="subtitle2">
            {rowData.isDeleted ? 'Eliminado por' : 'Restaurado por'}
          </Typography>
          <Typography className={classes.dataText}>
            {rowData.deletedByUsername ? rowData.deletedByUsername : '- - - -'}
          </Typography>
        </Grid>
      ) : (
        <Grid item sm={12} md={fullRow ? 12 : 4} className={classes.gridItem} />
      )}
    </Grid>
  );
}

DataTimeDetail.defaultProps = {
  fullRow: false,
};

DataTimeDetail.propTypes = {
  rowData: PropTypes.objectOf(PropTypes.object).isRequired,
  fullRow: PropTypes.bool,
};
