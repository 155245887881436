import React, { useState, useRef } from 'react';
import { Drawer, Typography, Box, CircularProgress, IconButton, Avatar, TextField, InputAdornment } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Edit as EditIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  CheckBoxOutlineBlank,
  Search as SearchIcon,
} from '@material-ui/icons';
import materialTableLocaleES from '../../../components/MaterialTableLocaleES';

// Components
import MaterialTable from 'material-table';
import { getOrderGroupSkuDetail } from '../../../api/WaveServices/WavesApi';
import SnackBarMessage from '../../../components/SnackBarMessage';

const drawerWidth = 700;
const useStyles = makeStyles(theme => ({
  container: {
    width: drawerWidth,
  },
  containerPaper: {
    width: drawerWidth,
    padding: 20,
  },
  smallColor: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

const initialState = {
  orderGroupId: 0,
  skuInput: '',
  skuDetail: [],
};

export default function OrderGroupDetail({ formOpen, toggleForm, refreshTable, dataObject }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState(initialState);
  const [closeClick, setCloseClick] = useState('');
  const [editForm, setEditForm] = useState(false);
  const [ods, setOds] = useState(false);

  const clearData = () => {
    setForm(initialState);
    setCloseClick('');
    setIsLoading(false);
  };

  const initData = () => {
    setCloseClick(false);    
    setOds(dataObject);
  };

  const handleChange = (event) => {    
    setForm({ 'skuInput': event.target.value});
  };

  const getSkuDetail = (e) => {
    if (e.key === 'Enter') {
      getOrderGroupSkuDetail(dataObject.order_group_id, form.skuInput)
        .then(response => {
          setForm({ skuDetail: response.data });
        })
        .catch(error => console.log(error)); 
    }
  }

  return (
    <Box>
      <Drawer
        anchor="right"
        open={formOpen}
        onClose={toggleForm}
        className={classes.container}
        classes={{ paper: classes.containerPaper }}
      >
        <Box className={classes.header}>
          <Box flexGrow={1}>
            <Typography variant="h5" color="primary">
              {'Detalle de orden de surtido '+ods.order_group}
            </Typography>
          </Box>
        </Box>
        <Box style={{ margin: 10}}>
          <TextField
            label="Buscar Sku"
            id="outlined-size-small"
            value={form.skuInput}
            variant="outlined"
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onKeyDown={getSkuDetail}
          />
        </Box>
        <MaterialTable          
          columns={[
            { title: 'Tienda #', field: 'order.storeNumber' },
            { title: 'Ranking tda', field: 'order.storePriority' },
            { title: 'Piezas', field: 'pieces' },
            { title: 'Piezas reales', field: 'expected_pieces' },
            { title: 'Cajas eq.', field: 'equivalent_boxes' },            
            { title: 'PPK', field: 'ppk' },
          ]}
          localization={materialTableLocaleES}
          data={form.skuDetail}
          options={{
            search: false,
            paging: false,
            toolbar: false,
            padding: 'dense',            
          }}          
        />

        <Box component="form" className={classes.form}>
          <Box />
          <Box className={classes.bottomSpace}>
            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            {formOpen && closeClick === '' && initData()}
            {!formOpen && closeClick !== '' && clearData()}
          </Box>
        </Box>
      </Drawer>

      <SnackBarMessage
        open={form.policyAccessError}
        message={form.roleAccessMessage}
        onClose={() => setForm({ ...form, policyAccessError: false })}
        variant="warning"
      />
    </Box>
  );
}

OrderGroupDetail.defaultProps = {
  refreshTable: null,
  dataObject: 0,
};

OrderGroupDetail.propTypes = {
  refreshTable: PropTypes.func,
  formOpen: PropTypes.bool.isRequired,
  toggleForm: PropTypes.func.isRequired,
  dataObject: PropTypes.object,
};
