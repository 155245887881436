import React, { useState, useRef, useEffect } from 'react';
import { Box, Chip, Tabs, Tab, AppBar } from '@material-ui/core';
import { Label as LabelIcon, Visibility as ShowIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

// Components
import MaterialTable from 'material-table';
import PageHeader from '../../../components/PageHeader';
import WithSnackBarMessages from '../../../components/WithSnackBarMessages';
import ZoneForm from '../components/ZoneForm';

// Security
import Permissions from '../../../AppsPermissions/ShopsAdministratorPermissions';
import { hasAuthority } from '../../AccessValidators';

// Styles
import { AppStyles } from '../../../AppStyles';
import materialTableLocaleES from '../../../components/MaterialTableLocaleES';

// Data Apis
import { getAllZones, getZoneSticker, deleteZone } from '../../../api/WaveServices/WavesApi';

// Routes
import { APP_ROUTES, WAVES_ROUTES } from '../../../Routes';

const breadcrumbsData = [
  { id: 1, label: 'Listado', base: APP_ROUTES.WAVES },
  {
    id: 2,
    label: 'Ordenes de Surtido',
    base: APP_ROUTES.WAVES + WAVES_ROUTES.ZONES,
  },
];

const useStyles = makeStyles(() => ({
  actionDescriptionEdit: {
    '& .MuiInput-root': {
      fontSize: 'small',
    },
  },
}));

function ZonesPage() {
  const classes = useStyles();
  const appStyles = AppStyles();
  const [openForm, setOpenForm] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const tableRef = useRef();
  const [dataObject, setDataObject] = useState(0);
  const [dataDetail, setDataDetail] = useState({});
  const [canGetAllZones, setCanGetAllZones] = useState(true);
  const [canDeleteZone, setCanDeleteZone] = useState(true);
  const [canUpdateZone, setCanUpdateZone] = useState(true);
  const [value, setValue] = useState(0);
  const [valueA, setValueA] = useState(0);

  const toggleForm = () => {
    if (tableRef.current) {
      tableRef.current.state.query.page = 0;
      if (openForm) {
        tableRef.current.onQueryChange();
      }
    }
    setDataObject(0);
    setOpenForm(!openForm);
  };

  const handleChangeTabTwo = (event, newValue) => {
    setValueA(newValue);
    tableRef.current.onQueryChange();
  };

  function a11yProps(index: any) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  const downloadSticker = zone_id => {
    getZoneSticker(zone_id).then(response => {
      const contentDisposition = `inline; filename="zona_${zone_id}.pdf"`;
      const contentType = 'application/pdf';
      const filename = contentDisposition.match(/filename="(.+)"/)[1];
      const file = new Blob([response.data], { type: contentType });
      // Bind blob on disk to ObjectURL
      const data = URL.createObjectURL(file);
      const a = document.createElement('a');
      a.style = 'display: none';
      a.href = data;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      // For Firefox
      setTimeout(function() {
        document.body.removeChild(a);
        // Release resource on disk after triggering the download
        window.URL.revokeObjectURL(data);
      }, 100);
    });
  };

  const toggleDetailDrawer = () => {
    setDataDetail(0);
    setOpenDetail(!openDetail);
  };

  useEffect(() => {
    // hasAuthority(Permissions.GetAllRoutes, setCanGetAllRoutes);
    // hasAuthority(Permissions.CreateRoute, setCanCreateRoute);
    // hasAuthority(Permissions.UpdateRouteByID, setCanUpdateRoute);
    // hasAuthority(Permissions.DeleteRouteByID, setCanDeleteRoute);
    // hasAuthority(Permissions.AddShopToRouteByID, setCanAddShopToRoute);
    // hasAuthority(Permissions.RemoveShopFromRouteByID, setRemoveShopFromRoute);
  }, []);

  const getZones = (query, type) =>
    getAllZones(type + 1, true)
      .then(response => ({
        ...query,
        data: response.data,
        page: response.current_page - 1,
        totalCount: response.total,
      }))
      .catch(() => ({
        ...query,
        page: 0,
        pageSize: 15,
        totalCount: 0,
        data: [],
      }));

  const deleteZoneData = oldData =>
    new Promise((resolve, reject) => {
      setTimeout(() => {
        deleteZone(oldData.id)
          .then(() => {
            if (tableRef.current) {
              tableRef.current.state.query.page = 0;
              tableRef.current.onQueryChange();
            }
          })
          .catch(() => reject());
        resolve();
      }, 1000);
    });

  return (
    <Box id="app-routes-page" component="main" className={appStyles.pageRoot}>
      <PageHeader
        title="Zonas"
        titleButton="Nueva Zona"
        displayButton
        canExecuteButton
        action={toggleForm}
        breadcrumbsData={breadcrumbsData}
      />

      <ZoneForm
        formOpen={openForm}
        toggleForm={toggleForm}
        refreshTable={val => {
          setValueA(val);
          tableRef.current.onQueryChange();
        }}
        dataObject={dataObject}
        canUpdateZone={canUpdateZone}
      />

      <AppBar
        position="static"
        color="default"
        style={{ alignItems: 'center', backgroundColor: 'white' }}
      >
        <Tabs
          value={valueA}
          onChange={handleChangeTabTwo}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="Elija tipo de zona"
        >
          <Tab label="Rack" {...a11yProps(1)} />
          <Tab label="Pasillo" {...a11yProps(2)} />
          <Tab label="Staging" {...a11yProps(3)} />
          <Tab label="Buffer" {...a11yProps(4)} />
          <Tab label="Inducción" {...a11yProps(5)} />
        </Tabs>
      </AppBar>
      <MaterialTable
        columns={[
          { title: 'Fecha de creación', field: 'created_at', type: 'datetime' },
          { title: 'Ubicación', field: 'location' },
          { title: 'Descripción', field: 'description' },
          { title: 'Código', field: 'code' },
          { title: 'Tipo', field: 'zonetype.name' },
        ]}
        title="Zonas"
        localization={materialTableLocaleES}
        tableRef={tableRef}
        data={query => getZones(query, valueA)}
        options={{
          actionsColumnIndex: -1,
          paging: true,
          toolbar: true,
          showTitle: false,
        }}
        actions={[
          {
            icon: () => <LabelIcon color="primary" className="icon-small" />,
            tooltip: 'Imprimir Etiqueta',
            onClick: (event, rowData) => {
              downloadSticker(rowData.id);
            },
          },
          {
            icon: () => <ShowIcon color="primary" className="icon-small" />,
            tooltip: 'Editar Zona',
            onClick: (event, rowData) => {
              setDataObject(rowData.id);
              setOpenForm(!openForm);
            },
          },
        ]}
        icons={{
          Delete: () => <DeleteIcon color="secondary" className="icon-small" />,
        }}
        editable={{
          onRowDelete: canDeleteZone ? oldData => deleteZoneData(oldData) : undefined,
        }}
      />
    </Box>
  );
}

export default WithSnackBarMessages(ZonesPage);
