import { validateAppAccess, validateAuthority } from '../api/AuthApi';

export const canAppAccess = async (appKey: String, setShow: Function) => {
  await validateAppAccess(appKey)
    .then(res => setShow(res))
    .catch(() => setShow(false));
};

export const hasAuthority = async (authority: String, setShow: Function) => {
  await validateAuthority(authority)
    .then(res => setShow(res))
    .catch(() => setShow(false));
};
