import React from 'react';
import { Box } from '@material-ui/core';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

// Routes
import { WAVES_ROUTES } from '../../Routes';

// App Layout
import { WithPublicLayout } from '../../components/AppLayout';

// Styles
import { AppStyles } from '../../AppStyles';

// Components
import WavesDrawer from './components/WavesDrawer';

// Pages
import WavesDashboardPage from './pages/WavesDashboardPage';
import { NotFoundPage } from '../NotFoundPage';
import WavesPage from './pages/WavesPage';
import PickingPage from './pages/PickingPage';
import SupplyOrdersPage from './pages/SupplyOrdersPage';
import ZonesPage from './pages/ZonesPage';
import TeamsPage from './pages/TeamsPage';
import WaveDetailPage from './pages/WaveDetailPage';
import WithSnackBarMessages from '../../components/WithSnackBarMessages';

function WavesRouter() {
  const appStyles = AppStyles();
  const { path } = useRouteMatch();

  return (
    <Box id="app-waves-router" component="main" className={appStyles.pageRouter}>
      <WavesDrawer />
      <Switch>
        <Route exact path={path} component={WavesDashboardPage} />        
        <Route exact path={`${path}${WAVES_ROUTES.WAVES}`} component={WavesPage} />
        <Route exact path={`${path}${WAVES_ROUTES.PICKING}`} component={PickingPage} />
        <Route exact path={`${path}${WAVES_ROUTES.SUPPLYORDERS}`} component={SupplyOrdersPage} />
        <Route exact path={`${path}${WAVES_ROUTES.ZONES}`} component={ZonesPage} />
        <Route exact path={`${path}${WAVES_ROUTES.TEAMS}`} component={TeamsPage} />
        <Route
          exact
          path={`${path}${WAVES_ROUTES.WAVE_NOT_FOUND}`}
          render={props => (
            <NotFoundPage
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              message={
                // eslint-disable-next-line react/prop-types
                props.location.state !== undefined && props.location.state !== null
                  ? // eslint-disable-next-line react/prop-types
                    props.location.state.message
                  : 'El contenido solicitado no existe'
              }
            />
          )}
        />        
        <Route
          exact
          path={`${path}${WAVES_ROUTES.WAVES}/:waveId`}
          component={WithSnackBarMessages(WaveDetailPage)}
        />        

        <Route component={NotFoundPage} />
      </Switch>
    </Box>
  );
}

export default WithPublicLayout(WavesRouter);
