import React, {useRef, useState} from 'react';
import {Box, Typography, CardMedia} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Styles
import LoginStyles from '../../Login/styles/LoginStyles';
import { AppStyles } from '../../../AppStyles';
import PageHeader from "../../../components/PageHeader";
import {APP_ROUTES, WAVES_ROUTES} from "../../../Routes";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import WaveFormDashboard from '../pages/WaveFormDashboard';



const breadcrumbsData = [
    {
        id: 1,
        label: 'Olas',
        base: APP_ROUTES.WAVES + WAVES_ROUTES.WAVES,
    },
    { id: 1, label: 'Métricas de Ola', base: APP_ROUTES.WAVES },
];

const useStyles = makeStyles(theme => ({
    actionDescriptionEdit: {
        '& .MuiInput-root': {
            fontSize: 'small',
        },
    },
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(3),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    typography: {
        textAlign: 'center',
    },
}));

export default function WavesDashboardPage() {
    const classes = useStyles();
    const appStyles = AppStyles();
    const [openForm, setOpenForm] = useState(false);
    const [openDetail, setOpenDetail] = useState(false);
    const tableRef = useRef();
    const [dataObject, setDataObject] = useState(0);
    const [dataDetail, setDataDetail] = useState(0);
    const [canGetAllWaves, setCanGetAllWaves] = useState(true);
    const [canCreateRoute, setCanCreateRoute] = useState(true);
    const [canUpdateRoute, setCanUpdateRoute] = useState(true);
    const [canDeleteRoute, setCanDeleteRoute] = useState(true);
    const [canAddShopToRoute, setCanAddShopToRoute] = useState(true);
    const [canRemoveShopFromRoute, setRemoveShopFromRoute] = useState(true);

    const toggleForm = () => {
        if (tableRef.current) {
            tableRef.current.state.query.page = 0;
            if (openForm) {
                tableRef.current.onQueryChange();
            }
        }
        setDataObject(0);
        setOpenForm(!openForm);
    };

    function createData(pTotales, pProcesadas, pRestantes, progreso) {
        return { pTotales, pProcesadas, pRestantes, progreso };
    }

    const rows = [
        createData(21000, 20370, 630, 97),
    ];

    function createDataBuffer(id, piezas, cajas, progreso) {
        return { id, piezas, cajas, progreso };
    }

    const rowsBuffer = [
        createDataBuffer('OL-200403-1', 237,  37, 22),
        createDataBuffer('OL-200403-2', 262,  24, 23),
        createDataBuffer('OL-200403-3', 305,  67, 44),
        createDataBuffer('OL-200403-4', 356,  49, 99),
    ];


    return (
        <Box id="app-home-waves-page" component="main" className={appStyles.pageRoot}>
          <PageHeader
            title="Dashboard Olas"
            breadcrumbsData={breadcrumbsData}
          />
          <WaveFormDashboard
            formOpen={openForm}
            toggleForm={toggleForm}
            refreshTable={() => tableRef.current && tableRef.current.onQueryChange()}
            dataObject={dataObject}
          />
            <Grid container spacing={3} style={{marginTop:'20px'}}>

                <Grid item xs={4}>
                    <Typography variant="h5">
                        Sorter 1 :
                        <Button href="#text-buttons"
                                color="primary"
                                onClick={
                                    (event, rowData) => {
                                        setDataObject('OL-200401-1');
                                        setOpenForm(!openForm);
                                    }
                                }>
                            <Typography variant="h5">
                                OL-200401-1
                            </Typography>
                        </Button>
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Piezas Totales</TableCell>
                                    <TableCell align="center">Piezas Procesadas</TableCell>
                                    <TableCell align="center">Piezas Restantes</TableCell>
                                    <TableCell align="center">Progreso</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map(row => (
                                    <TableRow key={row.id}>
                                        <TableCell align="center">{row.pTotales}</TableCell>
                                        <TableCell align="center">{row.pProcesadas}</TableCell>
                                        <TableCell align="center">{row.pRestantes}</TableCell>
                                        <TableCell align="center">
                                            <LinearProgress variant="determinate" value={row.progreso} />
                                            <Typography className={classes.typography}>{row.progreso}%</Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={4}>
                    <Typography variant="h5">
                        Sorter 3 :
                        <Button href="#text-buttons"
                                color="primary"
                                onClick={
                                    (event, rowData) => {
                                        setDataObject('OL-200401-2');
                                        setOpenForm(!openForm);
                                    }
                                }>
                            <Typography variant="h5">
                                OL-200401-2
                            </Typography>
                        </Button>
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Piezas Totales</TableCell>
                                    <TableCell align="center">Piezas Procesadas</TableCell>
                                    <TableCell align="center">Piezas Restantes</TableCell>
                                    <TableCell align="center">Progreso</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map(row => (
                                    <TableRow key={row.id}>
                                        <TableCell align="center">{row.pTotales}</TableCell>
                                        <TableCell align="center">{row.pProcesadas}</TableCell>
                                        <TableCell align="center">{row.pRestantes}</TableCell>
                                        <TableCell align="center">
                                            <LinearProgress variant="determinate" value={row.progreso} />
                                            <Typography className={classes.typography}>{row.progreso}%</Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={4}>
                    <Typography variant="h5">
                        PTL :
                        <Button href="#text-buttons"
                              color="primary"
                              onClick={
                                  (event, rowData) => {
                                      setDataObject('OL-200402-3');
                                      setOpenForm(!openForm);
                                  }
                              }>
                            <Typography variant="h5">
                                OL-200402-3
                            </Typography>
                        </Button>
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Piezas Totales</TableCell>
                                    <TableCell align="center">Piezas Procesadas</TableCell>
                                    <TableCell align="center">Piezas Restantes</TableCell>
                                    <TableCell align="center">Progreso</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map(row => (
                                    <TableRow key={row.id}>
                                        <TableCell align="center">{row.pTotales}</TableCell>
                                        <TableCell align="center">{row.pProcesadas}</TableCell>
                                        <TableCell align="center">{row.pRestantes}</TableCell>
                                        <TableCell align="center">
                                            <LinearProgress variant="determinate" value={row.progreso} />
                                            <Typography className={classes.typography}>{row.progreso}%</Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={4}>
                    <Typography variant="h5" >
                        Ola en Buffer Sorter 1
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">ID Ola</TableCell>
                                    <TableCell align="center">Piezas</TableCell>
                                    <TableCell align="center">Cajas</TableCell>
                                    <TableCell align="center">Progreso</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rowsBuffer.map(row => (
                                    <TableRow key={row.id}>
                                        <TableCell component="th" scope="row">
                                            <Button href="#text-buttons"
                                                    color="primary"
                                                    onClick={
                                                        (event, rowData) => {
                                                            setDataObject('OL-200401-4');
                                                            setOpenForm(!openForm);
                                                        }
                                                    }>
                                                {row.id}
                                            </Button>
                                        </TableCell>
                                        <TableCell align="center">{row.piezas}</TableCell>
                                        <TableCell align="center">{row.cajas}</TableCell>
                                        <TableCell align="center">
                                            <LinearProgress variant="determinate" value={row.progreso} />
                                            <Typography className={classes.typography}>{row.progreso}%</Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={4}>
                    <Typography variant="h5" >
                        Ola en Buffer Sorter 3
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">ID Ola</TableCell>
                                    <TableCell align="center">Piezas</TableCell>
                                    <TableCell align="center">Cajas</TableCell>
                                    <TableCell align="center">Progreso</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rowsBuffer.map(row => (
                                    <TableRow key={row.id}>
                                        <TableCell component="th" scope="row">
                                            <Button href="#text-buttons"
                                                    color="primary"
                                                    onClick={
                                                        (event, rowData) => {
                                                            setDataObject('OL-200401-1');
                                                            setOpenForm(!openForm);
                                                        }
                                                    }>
                                                {row.id}
                                            </Button>
                                        </TableCell>
                                        <TableCell align="center">{row.piezas}</TableCell>
                                        <TableCell align="center">{row.cajas}</TableCell>
                                        <TableCell align="center">
                                            <LinearProgress variant="determinate" value={row.progreso} />
                                            <Typography className={classes.typography}>{row.progreso}%</Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={4}>
                    <Typography variant="h5">
                        Ola en Buffer en PTL
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">ID Ola</TableCell>
                                    <TableCell align="center">Piezas</TableCell>
                                    <TableCell align="center">Cajas</TableCell>
                                    <TableCell align="center">Progreso</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rowsBuffer.map(row => (
                                    <TableRow key={row.id}>
                                        <TableCell component="th" scope="row">
                                            <Button href="#text-buttons"
                                                    color="primary"
                                                    onClick={
                                                        (event, rowData) => {
                                                            setDataObject('OL-200401-2');
                                                            setOpenForm(!openForm);
                                                        }
                                                    }>
                                                {row.id}
                                            </Button>
                                        </TableCell>
                                        <TableCell align="center">{row.piezas}</TableCell>
                                        <TableCell align="center">{row.cajas}</TableCell>
                                        <TableCell align="center">
                                            <LinearProgress variant="determinate" value={row.progreso} />
                                            <Typography className={classes.typography}>{row.progreso}%</Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Typography variant="h5">
                        Olas Encoladas
                </Typography>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            ID OLA :
                                            <Button
                                                href="#text-buttons"
                                                color="primary"
                                                onClick={
                                                    (event, rowData) => {
                                                        setDataObject('OL-200401-3');
                                                        setOpenForm(!openForm);
                                                    }
                                                }
                                            >
                                            OL-200402-1
                                        </Button>
                                        </TableCell>
                                        <TableCell align="center">PIEZAS: 75000</TableCell>
                                        <TableCell align="center">PZAS. RESTANTES: 12500</TableCell>
                                        <TableCell align="center">
                                            SURTIDO:
                                            <LinearProgress variant="determinate" value="33" />
                                            <Typography className={classes.typography}>33%</Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Button variant="contained" color="secondary"  style={{ borderRadius: 30 }}>
                                                <CancelPresentationIcon fontSize="small" style={{marginRight: 4}}/>
                                                CANCELAR OLA
                                            </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                            <IconButton aria-label="delete" className={classes.margin}>
                                                <PauseCircleOutlineIcon fontSize="large" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        ID OLA : <Button href="#text-buttons" color="primary">
                                        OL-200402-2
                                    </Button>
                                    </TableCell>
                                    <TableCell align="center">PIEZAS: 75000</TableCell>
                                    <TableCell align="center">PZAS. RESTANTES: 12500</TableCell>
                                    <TableCell align="center">
                                        SURTIDO:
                                        <LinearProgress variant="determinate" value="33" />
                                        <Typography className={classes.typography}>33%</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Button variant="contained" color="secondary"  style={{ borderRadius: 30 }}>
                                            <CancelPresentationIcon fontSize="small" style={{marginRight: 4}}/>
                                            CANCELAR OLA
                                        </Button>
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton aria-label="delete" className={classes.margin}>
                                            <PauseCircleOutlineIcon fontSize="large" />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        ID OLA : <Button href="#text-buttons" color="primary">
                                        OL-200402-3
                                    </Button>
                                    </TableCell>
                                    <TableCell align="center">PIEZAS: 75000</TableCell>
                                    <TableCell align="center">PZAS. RESTANTES: 12500</TableCell>
                                    <TableCell align="center">
                                        SURTIDO:
                                        <LinearProgress variant="determinate" value="33" />
                                        <Typography className={classes.typography}>33%</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Button variant="contained" color="secondary"  style={{ borderRadius: 30 }}>
                                            <CancelPresentationIcon fontSize="small" style={{marginRight: 4}}/>
                                            CANCELAR OLA
                                        </Button>
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton aria-label="delete" className={classes.margin}>
                                            <PauseCircleOutlineIcon fontSize="large" />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

            </Grid>
        </Box>
    );
}
