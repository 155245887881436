import React, { useState, useRef, useEffect } from 'react';

// Components
import MomentUtils from '@date-io/moment';
import { useSnackbar } from 'notistack';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    Drawer,
    TextField,
    Typography,
    Box,
    Button,
    CircularProgress,
    FormControlLabel,
    Switch,
    FormLabel,
    Card,
    CardContent,
    Tooltip,
    FormControl,
    InputLabel,
    Select,
    IconButton,
    Fab,
    MenuItem,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { FixedSizeList } from 'react-window';
import { Link } from 'react-router-dom';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import {
    ImportExport as PriorityIcon,
    Close as CloseIcon,
    Add as AddIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
    LibraryBooks as UserDataIcon,
    ViewHeadline as LineDraw
} from '@material-ui/icons';
import LayersIcon from '@material-ui/icons/Layers';
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableContainer from "@material-ui/core/TableContainer/TableContainer";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table/Table";
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableBody from "@material-ui/core/TableBody/TableBody";
import materialTableLocaleES from "../../../components/MaterialTableLocaleES";
import {getAllOrderGroups} from "../../../api/WaveServices/WavesApi";
import CardActions from '@material-ui/core/CardActions';


// Data Api
const drawerWidth = "70%";
const useStyles = makeStyles(theme => ({
    container: {
        width: drawerWidth,
    },
    containerPaper: {
        width: drawerWidth,
        padding: 20,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
    },
    headerIcon: {
        fontSize: 35,
        marginRight: theme.spacing(1),
    },
    headerCloseButton: {
        padding: theme.spacing(1),
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'space-between',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    selectForm: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    selectStyle: {
        minWidth: 195,
        maxWidth: 195,
    },
    pickerStyle: {
        minWidth: 225,
        maxWidth: 225,
    },
    selectAddButton: {
        padding: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    addRolesPolices: {
        margin: theme.spacing(4),
    },
    card: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    cardContent: {
        padding: theme.spacing(2),
    },
    formCardInput: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    bottomSpace: {
        paddingTop: theme.spacing(2),
    },
    progressWave : {
        marginTop: theme.spacing(1),
        padding: 20,
        alignItems: 'rigth',
    },
    typography: {
        textAlign: 'center',
    },
    inputWave : {
        marginRight: '10px',
    },
    footerBox : {
        marginRight: '10px',
        marginTop: '80%',
    }
}));
const initialState = {
    priorityId : 0,
    namePriority: '',
    orderPriority : 0,
    nameError: false,
    UserNameErrorMessage: '',
    accountDate: moment()
        .add(1, 'M')
        .startOf('day'),
    accountDateError: false,
    accountDateErrorMessage: '',
    credentialDate: moment()
        .add(1, 'M')
        .startOf('day'),
    credentialDateError: false,
    credentialDateErrorMessage: '',
};

function WaveFormDashboard({
      formOpen,
      toggleForm,
      refreshTable,
      dataObject,
  }) {

    const classes = useStyles();
    const tableUserRef = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const [form, setForm] = useState(initialState);
    const [closeClick, setCloseClick] = useState('');
    const [editForm, setEditForm] = useState(false);
    const [open, setOpen] = useState(false);
    const tableRef = useRef();

    function createData(id, depto, sku, prepack, pzas, cajas, tarimas, avance, estatus, ubicacion) {
        return { id, depto, sku, prepack, pzas, cajas, tarimas, avance, estatus, ubicacion };
    }

    const rows = [
        createData('QWERTY123', 'MUJER', 12333234, 2, 2000, 50, 2, 30,  'PICKING', 'STAGING'),
        createData('QWERTY456', 'MUJER', 43243232, 2, 2000, 50, 2, 15,  'PICKING', 'STAGING'),
        createData('QWERTY789', 'MUJER', 54354354, 2, 2000, 50, 2, 90,  'PICKING', 'STAGING'),
        createData('QWERTY112', 'MUJER', 34533334, 2, 2000, 50, 2, 73,  'PICKING', 'STAGING'),
        createData('QWERTY432', 'MUJER', 17655655, 2, 2000, 50, 2, 20,  'ON WAIT', 'RACK'),
    ];

    const renderOrderGroupsDetail = rowData => {

        return (
            <TableContainer >
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">ID</TableCell>
                            <TableCell align="center">SKU</TableCell>
                            <TableCell align="center">Cajas</TableCell>
                            <TableCell align="center">Piezas</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(row => (
                            <TableRow key={row.id}>
                                <TableCell align="center">{row.id}</TableCell>
                                <TableCell align="center">{row.sku}</TableCell>
                                <TableCell align="center">{row.cajas}</TableCell>
                                <TableCell align="center">{row.pzas}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    const getSupplyOrders = query =>
        getAllOrderGroups(true, query.pageSize, query.page, query.search)

            .then(response => ({
                ...query,
                data: [
                    {id : 1, lpn: 'QWETY321', ubicacion: 'Staging'},
                    {id : 2, lpn: 'QWETY211', ubicacion: 'Staging'},
                    {id : 3, lpn: 'QWETY322', ubicacion: 'Staging'},
                    {id : 4, lpn: 'QWETY838', ubicacion: 'Staging'},
                    {id : 5, lpn: 'QWETY322', ubicacion: 'Staging'},
                    {id : 6, lpn: 'QWETY121', ubicacion: 'Staging'},
                    {id : 7, lpn: 'QWETY444', ubicacion: 'Staging'},
                    {id : 8, lpn: 'QWETY030', ubicacion: 'N/A'},
                    {id : 9, lpn: 'QWETY234', ubicacion: 'N/A'},
                    {id : 10, lpn: 'QWETY133', ubicacion: 'N/A'},
                ],
                page: response.current_page - 1,
                totalCount: response.total,
            }))
            .catch(() => ({
                ...query,
                page: 0,
                pageSize: 15,
                totalCount: 0,
            }));

    return (
        <Box>
            <Drawer
                anchor="right"
                open={formOpen}
                onClose={toggleForm}
                className={classes.container}
                classes={{ paper: classes.containerPaper }}
            >
                <Box className={classes.header}>
                    <LayersIcon color="primary" className={classes.headerIcon} />
                    <Box flexGrow={4}>
                        <Typography variant="h5" color="primary">
                           OLA
                        </Typography>
                    </Box>
                    <Typography>Progreso:</Typography>
                    <Grid item xs={6} className={classes.progressWave} style={{textAlign:'rigth'}}>

                        <LinearProgress variant="determinate" value="19" />
                        <Typography className={classes.typography}>19%</Typography>
                    </Grid>
                    <IconButton
                        color="secondary"
                        className={classes.headerCloseButton}
                        onClick={() => {
                            toggleForm();
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={12}>

                    </Grid>
                    <Grid item xs={6}>
                        <Box component="form" className={classes.form} style={{borderRight:'1px solid rgb(222,205,205)', marginBottom:'10px'}}>
                            <Box className={classes.inputWave} style={{marginBottom:'10px'}}>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <Card className={classes.root}>
                                            <CardContent>
                                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                    División
                                                </Typography>
                                                <Typography variant="h5" component="h2">
                                                    Mujer
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Card className={classes.root}>
                                            <CardContent>
                                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                    SKU
                                                </Typography>
                                                <Typography variant="h5" component="h2">
                                                    1234567
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box className={classes.inputWave} style={{marginBottom:'10px'}}>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <Card className={classes.root}>
                                            <CardContent>
                                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                    Piezas
                                                </Typography>
                                                <Typography variant="h5" component="h2">
                                                    20000
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Card className={classes.root}>
                                            <CardContent>
                                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                    Cajas
                                                </Typography>
                                                <Typography variant="h5" component="h2">
                                                    800
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box className={classes.inputWave}>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <Card className={classes.root}>
                                            <CardContent>
                                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                    Tarimas
                                                </Typography>
                                                <Typography variant="h5" component="h2">
                                                    40
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box className={classes.footerBox}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    style={{ borderRadius: 30, marginRight: 10 }}
                                    size="small"
                                >
                                    CANCELAR ORDEN
                                </Button>
                            </Box>

                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h5" color="primary">
                            Tarimas
                        </Typography>
                        <MaterialTable
                            columns={[
                                { title: 'No', field: 'id', },
                                { title: 'LPN', field: 'lpn' },
                                { title: 'Ubicación', field: 'ubicacion' },
                            ]}
                            title=" "
                            localization={materialTableLocaleES}
                            tableRef={tableRef}
                            data={getSupplyOrders}
                            options={{
                                search: false,
                                paging: false,
                                toolbar: true,
                                padding: 'dense',
                            }}
                            detailPanel={rowData => renderOrderGroupsDetail(rowData)}
                        />
                    </Grid>
                </Grid>
            </Drawer>
        </Box>
    );
}


WaveFormDashboard.defaultProps = {
    refreshTable: null,
    dataObject: 0,
};

WaveFormDashboard.propTypes = {
    refreshTable: PropTypes.func,
    formOpen: PropTypes.bool.isRequired,
    toggleForm: PropTypes.func.isRequired,
    dataObject: PropTypes.number,
};

export default WaveFormDashboard;