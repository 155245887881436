import { createMuiTheme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

export default theme => {
  return createMuiTheme({
    palette: {
      type: theme.paletteType,
      primary: {
        light: '#3765fa',
        main: '#063ff9',
        dark: '#042cae',
        contrastText: '#ffffff',
      },
      background: {
        default: theme.paletteType === 'light' ? '#f6f6f6' : '#303030',
      },
      error: red,
    },
  });
};
