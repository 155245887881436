import React, { createRef, Component } from 'react';
import { Button } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import { SnackbarProvider } from 'notistack';

export default function WithSnackBarMessages(WrappedComponent: Component) {
  return function withSnackBarMessages() {
    const notistackRef = createRef();
    const onClickDismiss = key => () => {
      notistackRef.current.closeSnackbar(key);
    };

    return (
      <SnackbarProvider
        maxSnack={5}
        ref={notistackRef}
        action={key => (
          <Button onClick={onClickDismiss(key)}>
            <CloseOutlined />
          </Button>
        )}
      >
        <WrappedComponent />
      </SnackbarProvider>
    );
  };
}
