import React, { useState, useRef, useEffect } from 'react';
import { Box, Tooltip } from '@material-ui/core';
import {
  Visibility as ViewIcon,
  EventAvailable as AccountNonExpiredIcon,
  EventBusy as AccountExpiredIcon,
  Lock as AccountLockedIcon,
  LockOpen as AccountNonLockedIcon,
  RadioButtonChecked as EnableIcon,
  VerifiedUser as CredentialsNonExpiredIcon,
  Block as CredentialsExpiredIcon,
  KeyboardArrowRight,
  KeyboardArrowDown,
} from '@material-ui/icons';

// Components
import { useSnackbar } from 'notistack';
import AdminForm from '../components/AdministratorForm';
import PageHeader from '../../../components/PageHeader';
import WithSnackBarMessages from '../../../components/WithSnackBarMessages';
import DataTable from '../../../components/DataTable';
import DataDetail from '../../../components/DataDetail';

// Security
import Permissions from '../../../AppsPermissions/UsersAdministratorPermissions';
import { hasAuthority } from '../../AccessValidators';

// Styles
import { AppStyles } from '../../../AppStyles';

// Data Apis
import { getAllUsersAdministrators, deleteUser } from '../../../api/UsersApi';

// Routes
import { APP_ROUTES, USERS_ROUTES } from '../../../Routes';

const breadcrumbsData = [
  {
    id: 1,
    label: 'Administradores',
    base: APP_ROUTES.USERS + USERS_ROUTES.ADMINISTRATORS,
  },
];

function AdminUsersPage() {
  const appStyles = AppStyles();
  const tableRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [openForm, setOpenForm] = useState(false);
  const [dataObject, setDataObject] = useState(0);

  const [canGetAllUsers, setCanGetAllUsers] = useState(false);
  const [canGetUser, setCanGetUser] = useState(false);
  const [canCreateUser, setCanCreateUser] = useState(false);
  const [canUpdateUser, setCanUpdateUser] = useState(false);
  const [canGetUserData, setCanGetUserData] = useState(false);
  const [canUpdateUserData, setCanUpdateUserData] = useState(false);
  const [canDeleteUser, setCanDeleteUser] = useState(false);

  const toggleForm = () => {
    if (tableRef.current) {
      tableRef.current.state.query.page = 0;
      if (openForm) {
        tableRef.current.onQueryChange();
      }
    }
    setOpenForm(!openForm);
    setDataObject(0);
  };
  const messageError = (message, variant) => {
    enqueueSnackbar(message, {
      variant,
    });
  };

  useEffect(() => {
    hasAuthority(Permissions.GetAllUsers, setCanGetAllUsers);
    hasAuthority(Permissions.GetUserById, setCanGetUser);
    hasAuthority(Permissions.CreateUser, setCanCreateUser);
    hasAuthority(Permissions.UpdateUser, setCanUpdateUser);
    hasAuthority(Permissions.GetUserDataById, setCanGetUserData);
    hasAuthority(Permissions.UpdateUserDataById, setCanUpdateUserData);
    hasAuthority(Permissions.DeleteUser, setCanDeleteUser);
  }, []);

  const getAdministratorsData = query =>
    // canGetAllUsers ?

    getAllUsersAdministrators(query.pageSize, query.page, query.search)
      .then(response => {
        return {
          ...query,
          data: response.users.data,
          page: response.users.current_page - 1,
          totalCount: response.users.total,
        };
      })
      .catch(error => {
        console.log(error);
        return {
          ...query,
          page: 0,
          pageSize: 15,
          totalCount: 0,
          data: [],
        };
      });

  const deleteAdministratorData = oldData =>
    new Promise((resolve, reject) => {
      setTimeout(() => {
        deleteUser(oldData.id)
          .then(() => {
            if (tableRef.current) {
              tableRef.current.state.query.page = 0;
              tableRef.current.onQueryChange();
            }
            messageError('Administrador deshabilitado', 'warning');
          })
          .catch(() => reject());
        resolve();
      }, 1000);
    });

  useEffect(() => {
    tableRef.current && tableRef.current.onQueryChange();
  }, [canGetAllUsers]);

  return (
    <Box id="app-users-admins-page" component="main" className={appStyles.pageRoot}>
      <PageHeader
        title="Administradores"
        titleButton="Nuevo Administrador"
        displayButton
        canExecuteButton={canCreateUser}
        action={toggleForm}
        breadcrumbsData={breadcrumbsData}
      />

      <AdminForm
        formOpen={openForm}
        toggleForm={toggleForm}
        refreshTable={() => tableRef.current && tableRef.current.onQueryChange()}
        dataObject={dataObject}
        canUpdateUser={canUpdateUser}
        canGetUserData={canGetUserData}
        canUpdateUserData={canUpdateUserData}
      />

      <DataTable
        nameEntity="Administrador"
        columns={[
          {
            title: 'Correo',
            field: 'email',
            type: 'string',
          },
          {
            title: 'Nombre',
            field: 'name',
            type: 'string',
          },
          {
            title: 'Estatus',
            render: rowData => (
              <div>
                <Tooltip
                  title={
                    rowData.active ? 'Administrador habilitado' : 'Administrador deshabilitado'
                  }
                  aria-label="add"
                >
                  <EnableIcon style={{ color: rowData.active ? 'green' : 'gray' }} />
                </Tooltip>
              </div>
            ),
          },
        ]}
        tableActions={[
          {
            disabled: !canGetUser,
            icon: () => (
              <ViewIcon color={canGetUser ? 'primary' : 'disabled'} className="icon-small" />
            ),
            tooltip: canGetUser ? 'Ver Administrador' : undefined,
            onClick: (event, rowData) => {
              setDataObject(rowData.id);
              setOpenForm(!openForm);
            },
          },
        ]}
        tableRef={tableRef}
        getDataFunction={getAdministratorsData}
        deleteDataFunction={deleteAdministratorData}
        canGetAll={canGetAllUsers}
        canDelete={canDeleteUser}
      />
    </Box>
  );
}

export default WithSnackBarMessages(AdminUsersPage);
