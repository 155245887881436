import moment from 'moment';
import { apiClientBearerToken } from './ApiClientConfig';
import Logger from '../Logger';

const Log = new Logger('src/api/UsersApi.js');

const USERS_PATH = '/users/all';
const USER_PATH = '/user';

export const getAllUsersAdministrators = ( size?: Number, page?: Number, search?: String, resourceView?: String,): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(USERS_PATH, {
      params: {
        size,
        page,
        search: search?.trim(),
        resourceView,
      },
    })
    .then(response => {
      return Promise.resolve(response.data.data);
    })
    .catch(error => {
      Log.error(
        getAllUsersAdministrators.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getMeUser = () : Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`${USER_PATH}/showlogued`)
        .then(response => {
            return Promise.resolve(response.data.user);
        })
        .catch(error => {
            Log.error(
               getMeUser.name,
               error.message,
               error.response ? error.response : error,
               !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};

export const getUserById = (id: Number): Promise<Object> => {
  return apiClientBearerToken()
    .get(`${USER_PATH}/edit/${id}`)
    .then(response => {
      return Promise.resolve(response.data.user);
    })
    .catch(error => {
      Log.error(getUserById.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });

};

export const getUserAuthorityById = (id: Number): Promise<Object> => {
  return apiClientBearerToken()
    .get(`${USER_PATH}/${id}/authorities`)
    .then(response => {
      return Promise.resolve(response.data.user);
    })
    .catch(error => {
      Log.error(getUserAuthorityById.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const getUserDataById = (id: Number): Promise<Object> => {
  return apiClientBearerToken()
    .get(`${USER_PATH}/edit/${id}`)
    .then(response => {
      return Promise.resolve(response.data.user);
    })
    .catch(error => {
      Log.error(getUserDataById.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const updateUserDataById = (
  id: Number,
  firstName: String,
  fatherLastName: String,
  motherLastName: String,
  phoneNumber: String,
  phoneExtension: String,
): Promise<Object> => {
  return apiClientBearerToken()
    .patch(`${USERS_PATH}/${id}/data`, {
      firstName,
      fatherLastName,
      motherLastName,
      phoneNumber,
      phoneExtension,
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      if (error.response) {
        const errors = {
          firstNameError: null,
          fatherLastNameError: null,
          motherLastNameError: null,
          phoneNumberError: null,
          phoneExtensionError: null,
        };
        error.response.data.errors.forEach(e => {
          if (e.field === 'Administrator.firstName') {
            errors.firstNameError = e.message;
          }
          if (e.field === 'Administrator.fatherLastName') {
            errors.fatherLastNameError = e.message;
          }
          if (e.field === 'Administrator.motherLastName') {
            errors.motherLastNameError = e.message;
          }
          if (e.field === 'Administrator.phoneNumber') {
            errors.phoneNumberError = e.message;
          }
          if (e.field === 'Administrator.phoneExtension') {
            errors.phoneExtensionError = e.message;
          }
        });

        return Promise.reject(errors);
      }
      Log.error(updateUserDataById.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const createUser = (
  name: String,
  email: String,
  password: String,
  extension: String,
  store_id : Number,
  accountExpirationDate: Date,
  credentialsExpirationDate: Date,
  roles: Array,
  policies: Array,
): Promise<Object> => {
  return apiClientBearerToken()
    .post(`${USER_PATH}/create`, {
      name,
      email,
      password,
      extension,
      store_id,
      accountExpirationDate: moment(accountExpirationDate)
        .utc()
        .format('YYYY-MM-DD HH:mm:ss'),
      credentialsExpirationDate: moment(credentialsExpirationDate)
        .utc()
        .format('YYYY-MM-DD HH:mm:ss'),
      active: true,
      accountNonExpired: true,
      credentialsNonExpired: true,
      accountNonLocked: true,
      authority: { roles, policies },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      if (error.response) {
          return Promise.reject(error.response);
      }

      Log.error(createUser.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const deleteUser = (id: Number): Promise<Object> => {
  return apiClientBearerToken()
    .get(`${USER_PATH}/delete/${id}`, {
    })
    .then(() => {
      return Promise.resolve();
    })
    .catch(error => {
      Log.error(deleteUser.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const updateUserById = (
  id: Number,
  name: String,
  email: String,
  extension: String,
  departments: Array,
  active: Boolean,
  roles: Array,
  policies: Array,
): Promise<Object> => {
  return apiClientBearerToken()
    .post(`${USER_PATH}/update`, {
      authority: { roles, policies },
      id : id,
      name : name,
      email: email,
      extension: extension,
      department : departments,
      active : active,
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      const errorDuplicate = {
        userNameErrorMessage: 'Este correo ya esta registrado',
      };

      Log.error(updateUserById.name, error.message, error.response ? error.response : error);
      return Promise.reject(errorDuplicate);
    });
};

// Update Password by User
export const changePasswordByUser = (
    oldPassword: String,
    newPassword: String,
    newPasswordConfirmation: String,
): Promise<Object> => {
    return apiClientBearerToken()
        .post(`${USER_PATH}/changepassword`, {
            old_password: oldPassword,
            new_password: newPassword,
            new_password_confirmation: newPasswordConfirmation
        })
        .then( response => {
            return Promise.resolve(response.data);
        })
        .catch( error => {
            const errorDuplicate = {
                userNameErrorMessage: 'Error al actualizar la contraseña.',
            };
            Log.error(changePasswordByUser.name, error.message, error.response ? error.response : error);
            return Promise.reject(error);
        })
};

// Reset Password
export const resetPasswordByEmail = (
  email: String,
  newPassword: String,
  newPasswordConfirmation: String,
): Promise<Object> => {
  return apiClientBearerToken()
    .post(`${USER_PATH}/changepasswordadmin`, {
      email: email,
      new_password : newPassword,
      new_password_confirmation: newPasswordConfirmation,
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      const errorDuplicate = {
        userNameErrorMessage: 'Error al actualizar la contraseña.',
      };

      Log.error(resetPasswordByEmail.name, error.message, error.response ? error.response : error);
      return Promise.reject(errorDuplicate);
    });
};
