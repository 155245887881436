import React, { useState, useRef, useEffect } from 'react';

// Components
import MomentUtils from '@date-io/moment';
import {
    sortableContainer,
    sortableElement,
    sortableHandle,
} from 'react-sortable-hoc';

import arrayMove from 'array-move';
import "../styles/list.css";

import { useSnackbar } from 'notistack';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    Drawer,
    TextField,
    Typography,
    Box,
    Button,
    CircularProgress,
    FormControlLabel,
    Switch,
    FormLabel,
    Card,
    CardContent,
    Tooltip,
    FormControl,
    InputLabel,
    Select,
    IconButton,
    Fab,
    MenuItem,
} from '@material-ui/core';

import { FixedSizeList } from 'react-window';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import {
    ImportExport as PriorityIcon,
    Close as CloseIcon,
    Add as AddIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
    LibraryBooks as UserDataIcon,
    ViewHeadline as LineDraw
} from '@material-ui/icons';



// Data Api
import {
    createPriority, getAllPriorities,
    getpriorityById,
    updatePriorityById,
} from '../../../api/PriorityServices/PriorityApi';
import { hasAuthority } from '../../AccessValidators';
import {getAllShops} from "../../../api/ShopServices/ShopApi";

const drawerWidth = 500;
const useStyles = makeStyles(theme => ({
    container: {
        width: drawerWidth,
    },
    containerPaper: {
        width: drawerWidth,
        padding: 20,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
    },
    headerIcon: {
        fontSize: 35,
        marginRight: theme.spacing(1),
    },
    headerCloseButton: {
        padding: theme.spacing(1),
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'space-between',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    selectForm: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    selectStyle: {
        minWidth: 195,
        maxWidth: 195,
    },
    pickerStyle: {
        minWidth: 225,
        maxWidth: 225,
    },
    selectAddButton: {
        padding: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    addRolesPolices: {
        margin: theme.spacing(4),
    },
    card: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    cardContent: {
        padding: theme.spacing(2),
    },
    formCardInput: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    bottomSpace: {
        paddingTop: theme.spacing(2),
    },
}));

const initialState = {
    priorityId : 0,
    namePriority: '',
    orderPriority : 0,
    nameError: false,
    UserNameErrorMessage: '',
    accountDate: moment()
        .add(1, 'M')
        .startOf('day'),
    accountDateError: false,
    accountDateErrorMessage: '',
    credentialDate: moment()
        .add(1, 'M')
        .startOf('day'),
    credentialDateError: false,
    credentialDateErrorMessage: '',
};

const rolePolicyData = {
    id: '',
    type: '',
    name: '',
};

const stateInit = {
    enabled: true,
    accountNonExpired: false,
    credentialsNonExpired: false,
    accountNonLocked: false,
};


function PriorityForm({
   formOpen,
   toggleForm,
   refreshTable,
   dataObject,
   canUpdateUser,
   canGetUserData,
   canUpdateUserData,
}) {
    const classes = useStyles();
    const tableUserRef = useRef();
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const [form, setForm] = useState(initialState);
    const [closeClick, setCloseClick] = useState('');
    const [editForm, setEditForm] = useState(false);
    const [administratorForm, setAdministratorForm] = useState(rolePolicyData);
    const [updateRol, setUpdateRol] = useState({ accessPolicy: [] });
    const [state, setState] = useState(stateInit);
    const [password, setPassword] = useState('prueba');
    const [seePassword, setSeePassword] = useState(false);
    const [open, setOpen] = useState(false);
    const [rolesPoliciesAdd, setRolesPoliciesAdd] = useState('Add');
    const [policies, setPolicies] = useState([]);
    const [roles, setRoles] = useState([]);
    const [priorities, setPriority] = useState([]);
    const [prioritiesId, setPriorityId] = useState([]);
    const [agregar, setAgregar] = useState(true);
    const [dataAdministratorOpen, setDataAdministratorOpen] = useState(false);
    const [resetPasswordByEmail, setResetPasswordByEmail] = useState(true);
    const [reseted, setReseted] = useState(false);
    const [confirmationPassword, setConfirmationPassword] = useState({
        confirmationOpen: false,
        confirmationChange: false,
    });

    const messageError = (message, variant) => {
        enqueueSnackbar(message, {
            variant,
        });
    };

    const onSortEnd = ({oldIndex, newIndex}) => {

        setPriority(arrayMove(priorities, oldIndex, newIndex));
        setPriorityId(arrayMove(prioritiesId, oldIndex, newIndex));
        console.log(priorities);
        console.log(prioritiesId);
    };

    const SortableItem = sortableElement(({value}) =>
        <li className="SortableItem" id={disableFormControl() === true ? "disabled" : ""}>{value}</li>
    );

    const SortableList = sortableContainer(({items}) => {
        return (
            <ul className="SortableList">
                {items.map((value, index) => (
                    <SortableItem key={`item-${index}`} index={index} value={value} className="sortableHelper" disabled={disableFormControl()}/>
                ))}
            </ul>
        );
    });

    useEffect(() => {
        //hasAuthority(Permissions.ResetPasswordByEmail, setResetPasswordByEmail);
    }, []);

    const handleAccountDateChange = date => {
        let err = false;
        let msj = '';
        setForm({
            ...form,
            accountDateError: err,
            accountDateErrorMessage: msj,
        });
        setForm({
            ...form,
            accountDate: date,
            accountDateError: err,
            accountDateErrorMessage: msj,
        });
    };

    const handleChange = event => {
        let value = event.target.checked;

        setState({ ...state, [event.target.name]: value });
        setForm({
            ...form,
            priorityId: dataObject,
            [event.target.name]: event.target.value,
        });
        setCloseClick(true);
    };

    const handleSubmit = event => {
        event.preventDefault();
        setIsLoading(true);
        if (dataObject === 0) {

            createPriority(
                form.namePriority,
            )
                .then(response => {
                    setForm({
                        ...form,
                        priorityId: response.id,
                    });
                    toggleForm();
                    setForm(initialState);
                    setIsLoading(false);
                    refreshTable();
                })
                .catch(error => {
                    if (error !== undefined) {
                        setForm({
                            ...form,
                        });
                    } else {
                        messageError('Servicio no disponible.', 'warning');
                        refreshTable();
                    }
                    setIsLoading(false);
                });

        } else {
            updatePriorityById(
                form.priorityId,
                form.namePriority,
                prioritiesId,
            )
                .then(() => {
                    toggleForm();
                    setForm(initialState);
                    setIsLoading(false);
                    messageError('Prioridad actualizada', 'success');
                    refreshTable();
                })
                .catch(error => {
                    if (error !== undefined) {
                        setForm({
                            ...form,
                        });
                    } else {
                        refreshTable();
                        messageError('Servicio no disponible.', 'warning');
                    }
                    setIsLoading(false);
                });
        }
    };

    const closeAdministratorDataForm = () => {
        setDataAdministratorOpen(!dataAdministratorOpen);
    };

    const clearData = () => {
        setCloseClick('');
        setEditForm(false);
        setUpdateRol({ accessPolicy: [] });
        setState(stateInit);
        setAgregar(true);
        setRolesPoliciesAdd('Add');
        setForm({
            ...form,
        });
    };

    const initData = () => {
        if (dataObject === 0) {
            setForm({
                ...form,
                priorityId: 0,
                namePriority: '',
                orderPriority: 0,
            });
        } else {
            getpriorityById(dataObject)
                .then(response => {
                    setForm({
                        priorityId: response.id,
                        namePriority: response.name,
                        orderPriority: response.order,
                    });
                })
                .catch(() => {
                    return Promise.resolve(setForm({}));
                });
            getAllPriorities(100, 1, null)
                .then(response => {
                    let priority = [];
                    let priorityId = [];
                    response.priorities.data.forEach((key, p) => {
                        var desc = key.name;
                        priority.push(desc);
                        priorityId.push(key.id);
                    });
                    setPriorityId(priorityId);
                    setPriority(priority);
                })
                .catch((error) => {
                    return Promise.resolve(setPriority([]));
                });
        }
        setCloseClick(false);
        dataObject !== 0 ? setEditForm(false) : setEditForm(true);

    };

    const disableFormControl = () => {
        if (isLoading) {
            return true;
        }
        if (dataObject === 0) {
            return false;
        }
        if (dataObject !== 0 && editForm) {
            return false;
        }
        return true;
    };

    const actionClose = () => {
        setOpen(!open);
    };

    const setDataAdmin = () => {
        setDataAdministratorOpen(true);
    };

    const hide = () => {
        setSeePassword(false);
    };

    return (
        <Box>
            <Drawer
                anchor="right"
                open={formOpen}
                onClose={toggleForm}
                className={classes.container}
                classes={{ paper: classes.containerPaper }}
            >
                <Box className={classes.header}>
                    <PriorityIcon color="primary" className={classes.headerIcon} />
                    <Box flexGrow={1}>
                        <Typography variant="h5" color="primary">
                            {dataObject === 0 ? 'Nueva Prioridad' : 'Actualizar Prioridad'}
                        </Typography>
                    </Box>
                    {dataObject !== 0 && (
                        <IconButton
                            disabled={!canUpdateUser}
                            color="primary"
                            className={classes.headerCloseButton}
                            onClick={() => {
                                setEditForm(!editForm);
                            }}
                        >
                            <EditIcon />
                        </IconButton>
                    )}
                    <IconButton
                        color="secondary"
                        className={classes.headerCloseButton}
                        onClick={() => {
                            toggleForm();
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box component="form" className={classes.form} onSubmit={handleSubmit}>
                    <Box>
                        <TextField
                            error={!!form.UserNameErrorMessage}
                            required
                            name="namePriority"
                            label="Nombre"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            disabled={disableFormControl()}
                            value={form.namePriority}
                            onChange={handleChange}
                        />
                        {dataObject === 0 ? null :
                            <Typography id={disableFormControl() === true ? "disabled" : ""}>
                                Arrastra para obtener el orden requerido
                            </Typography>
                        }
                        {dataObject === 0 ? null :
                            <SortableList items={priorities} onSortEnd={onSortEnd} className="SortableHelper"/>
                        }
                    </Box>
                    <Box className={classes.bottomSpace}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={disableFormControl()}
                            style={{ borderRadius: 30 }}
                            size="small"
                        >
                            {dataObject === 0 ? 'Guardar Prioridad' : 'Actualizar Prioridad'}
                        </Button>
                        {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        {formOpen && closeClick === '' && initData()}
                        {!formOpen && closeClick !== '' && clearData()}
                    </Box>
                </Box>
            </Drawer>
        </Box>
    );
}

PriorityForm.defaultProps = {
    refreshTable: null,
    dataObject: 0,
    canUpdateUser: false,
    canGetUserData: false,
    canUpdateUserData: false,
};

PriorityForm.propTypes = {
    refreshTable: PropTypes.func,
    formOpen: PropTypes.bool.isRequired,
    toggleForm: PropTypes.func.isRequired,
    dataObject: PropTypes.number,
    canUpdateUser: PropTypes.bool,
    canGetUserData: PropTypes.bool,
    canUpdateUserData: PropTypes.bool,
};

export default PriorityForm;
