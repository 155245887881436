import { version } from '../package.json';

const LOCAL = {
  API_BASE_URL: 'http://ordermanager.test/api/v1',
  BASE_URL: 'http://ordermanager.test',
  PRODUCTS_BASE_URL: 'http://localhost:3001',
  COOKIE_DOMAIN: 'localhost',
  COOKIE_ENCRYPT: true,
  COOKIE_SECRET: 'uwbZ4HyyXBQBmeMW',
  COOKIE_USER_TOKEN: 'HWtcXsA7rLv2XQyL',
  CLIENT_ID: 2,
  CLIENT_SECRET: 'LE4EAgmyIkqFPZIBzOx2mO2qKEVVpQkBr6ms9x11',
  SENTRY_DSN: '',
};

const DEVELOPMENT = {
  API_BASE_URL: 'https://dev-api.ccp.supply/api/v1',
  BASE_URL: 'https://dev-api.ccp.supply',
  COOKIE_DOMAIN: 'dev.ccp.supply',
  COOKIE_ENCRYPT: true,
  COOKIE_SECRET: 'FJyAewq7fMFhQh4TW6Fy4MP8QLjVzFeb',
  COOKIE_USER_TOKEN: '2BL3KqeeShK3XJpRK5W2WKYAzgpeBj5p',
  CLIENT_ID: 6,
  CLIENT_SECRET: 'fyKQJep5L2aWDJj4OFo8lMTn7gz2lI8vrCf4OYJ1',
  SENTRY_DSN: '',
};

const PRODUCTION = {
  API_BASE_URL: 'https://api.ccp.supply/api/v1',  
  BASE_URL: 'https://api.ccp.supply',
  COOKIE_DOMAIN: 'ccp.supply',
  COOKIE_ENCRYPT: true,
  COOKIE_SECRET: 'VsTxEqcnL3t76r9DwLfYJdZER9M4Vxavmw5kq7Xt8AYnmBZ22ATEXFuasNd4JT5b',
  COOKIE_USER_TOKEN: 'dkWTQZtpNjV5ZcVDFa8sJ6xEXdHenMn8DVqGyNy2EdBUYMH8AjM5Y3SEnWjmna7U',
  CLIENT_ID: 4,
  CLIENT_SECRET: 'nJ5CiOfLtlp44byCjj04KId7EpBK78SsrqikyFnb',
  SENTRY_DSN: '',
};

let config = {};

switch (process.env.REACT_APP_PROFILE) {
  case 'DEVELOPMENT':
    config = DEVELOPMENT;
    break;

  case 'PRODUCTION':
    config = PRODUCTION;
    break;

  default:
    config = LOCAL;
    break;
}

export default {
  APP_VERSION: version,
  API_CLIENT_ID: 'web',
  API_CLIENT_SECRET: '4TvsdRgdCtYM7QD6',
  CHANGE_LOG_BASE_URL: '',
  COOKIE_DOMAIN: '.ccp.tienda',
  ...config,
};
