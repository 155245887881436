import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core';
import { BrowserRouter as Router } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import * as Sentry from '@sentry/browser';
import * as serviceWorker from './serviceWorker';

import AppConfig from './AppConfig';
import App from './App';
import Theme from './Theme';

Sentry.init({
  environment: process.env.REACT_APP_PROFILE,
  dsn: AppConfig.SENTRY_DSN,
  release: AppConfig.APP_VERSION,
});

ReactDOM.render(
  <MuiThemeProvider theme={Theme({ paletteType: 'light' })}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <Router>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </Router>
  </MuiThemeProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
