import React from 'react';
import { Box } from '@material-ui/core';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

// Routes
import { USERS_ROUTES } from '../../Routes';

// Styles
import { AppStyles } from '../../AppStyles';

// Components
import { WithPublicLayout } from '../../components/AppLayout';
import UsersDrawer from './components/UsersDrawer';
import AdminUsersPage from './pages/AdminUsersPage';
import PoliciesPage from './pages/PoliciesPage';
import RolesPage from './pages/RolesPage';
import { NotFoundPage } from '../NotFoundPage';
import UsersDashboardPage from './pages/UsersDashboardPage';

function UsersRouter() {
  const appStyles = AppStyles();
  const { path } = useRouteMatch();

  return (
    <Box id="app-users-router" component="main" className={appStyles.pageRouter}>
      <UsersDrawer />
      <Switch>
        <Route exact path={path} component={UsersDashboardPage} />
        <Route exact path={`${path}${USERS_ROUTES.ADMINISTRATORS}`} component={AdminUsersPage} />
        <Route exact path={`${path}${USERS_ROUTES.POLICIES}`} component={PoliciesPage} />
        <Route exact path={`${path}${USERS_ROUTES.ROLES}`} component={RolesPage} />
        <Route component={NotFoundPage} />
      </Switch>
    </Box>
  );
}

export default WithPublicLayout(UsersRouter);
