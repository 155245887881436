import React, { useState, useRef, useEffect } from 'react';
import {
    Drawer,
    TextField,
    Typography,
    Box,
    Button,
    CircularProgress,
    FormControlLabel,
    Switch,
    FormLabel,
    Card,
    CardContent,
    Tooltip,
    FormControl,
    InputLabel,
    Select,
    IconButton,
    Fab,
    MenuItem,
    Checkbox,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import {
  People as UsersIcon,
  Close as CloseIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  LibraryBooks as UserDataIcon,
} from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import Autocomplete from '@material-ui/lab/Autocomplete';
// Components

import CopyPasswordDialog from './CopyPasswordDialog';

// Data Api
import {
  createUser,
  getUserById,
  updateUserById,
  getUserAuthorityById,
  resetPasswordByEmail as resetPassword,
} from '../../../api/UsersApi';
import { getAllRoles } from '../../../api/RoleApi';
import { getAllPolicies } from '../../../api/PoliciesApi';
import Permissions from '../../../AppsPermissions/UsersAdministratorPermissions';
import { hasAuthority } from '../../AccessValidators';
import Confirmation from './Confirmation';
import {getAllDepartments} from "../../../api/ShopServices/Catalogues/DepartmentsApi";
import {getRouteById} from "../../../api/ShopServices/RoutesApi";

const drawerWidth = 500;
const useStyles = makeStyles(theme => ({
  container: {
    width: drawerWidth,
  },
  containerPaper: {
    width: drawerWidth,
    padding: 20,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  headerIcon: {
    fontSize: 35,
    marginRight: theme.spacing(1),
  },
  headerCloseButton: {
    padding: theme.spacing(1),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  selectForm: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  selectStyle: {
    minWidth: 195,
    maxWidth: 195,
  },
  pickerStyle: {
    minWidth: 225,
    maxWidth: 225,
  },
  selectAddButton: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  addRolesPolices: {
    margin: theme.spacing(4),
  },
  card: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  cardContent: {
    padding: theme.spacing(2),
  },
  formCardInput: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bottomSpace: {
    paddingTop: theme.spacing(2),
  },
}));

const initialState = {
  userId: 0,
  emailUser: ' ',
  nameUser: ' ',
  departmentUser: [],
  extensionUser: ' ',
  nameError: false,
  UserNameErrorMessage: '',
  accountDate: moment()
    .add(1, 'M')
    .startOf('day'),
  accountDateError: false,
  accountDateErrorMessage: '',
  credentialDate: moment()
    .add(1, 'M')
    .startOf('day'),
  credentialDateError: false,
  credentialDateErrorMessage: '',
};

const rolePolicyData = {
  id: '',
  type: '',
  name: '',
};

const stateInit = {
  enabled: true,
  accountNonExpired: false,
  credentialsNonExpired: false,
  accountNonLocked: false,
};

function AdministratorForm({
  formOpen,
  toggleForm,
  refreshTable,
  dataObject,
  canUpdateUser,
  canGetUserData,
  canUpdateUserData,
}) {
  const classes = useStyles();
  const tableUserRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState(initialState);
  const [closeClick, setCloseClick] = useState('');
  const [editForm, setEditForm] = useState(false);
  const [administratorForm, setAdministratorForm] = useState(rolePolicyData);
  const [updateRol, setUpdateRol] = useState({ accessPolicy: [] });
  const [state, setState] = useState(stateInit);
  const [password, setPassword] = useState('prueba');
  const [seePassword, setSeePassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [rolesPoliciesAdd, setRolesPoliciesAdd] = useState('Add');
  const [policies, setPolicies] = useState([]);
  const [roles, setRoles] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [agregar, setAgregar] = useState(true);
  const [dataAdministratorOpen, setDataAdministratorOpen] = useState(false);
  const [resetPasswordByEmail, setResetPasswordByEmail] = useState(true);
  const [reseted, setReseted] = useState(false);
  const [confirmationPassword, setConfirmationPassword] = useState({
    confirmationOpen: false,
    confirmationChange: false,
  });

  const messageError = (message, variant) => {
    enqueueSnackbar(message, {
      variant,
    });
  };

  const msgBin = (
    <Typography component={'span'} variant={'caption'}>
      El correo ya se encuentra registrado,si no puede verlo en esta sección revise la
      <Link to={`/more/bin/users`}> Papelera</Link>
    </Typography>
  );

  useEffect(() => {
    //hasAuthority(Permissions.ResetPasswordByEmail, setResetPasswordByEmail);
  }, []);

  const policyOptions = policies.map(option => {
    const firstLetter = option.name[0];
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });

  const roleOptions = roles.map(option => {
    const firstLetter = option.name[0];
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });

  const isValidEmail = (email: String) => {
    const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return reg.test(email);
  };

  const validateAccountDate = () => {
    if (form.accountDate.diff(moment(), 'days') < 6) {
      setForm({
        ...form,
        accountDateError: true,
        accountDateErrorMessage: 'La fecha de expiración de la cuenta debe ser mayor a una semana',
      });
      return false;
    }
    return true;
  };

  const validateCredentialsDate = () => {
    if (form.credentialDate.diff(moment(), 'days') < 6) {
      setForm({
        ...form,
        credentialDateError: true,
        credentialDateErrorMessage:
          'La fecha de expiración de las credenciales del usuario debe ser mayor a una semana',
      });
      return false;
    }
    return true;
  };

  const handleAccountDateChange = date => {
    let err = false;
    let msj = '';

    setForm({
      ...form,
      accountDateError: err,
      accountDateErrorMessage: msj,
    });

    if (date == null) {
      err = true;
      msj = 'Introduce una fecha';
    } else if (date.diff(moment(), 'days') < 6) {
      err = true;
      msj = 'La fecha de expiración de la cuenta debe ser mayor a una semana';
    }

    setForm({
      ...form,
      accountDate: date,
      accountDateError: err,
      accountDateErrorMessage: msj,
    });
  };

  const handleCredentialsDateChange = date => {
    let err = false;
    let msj = '';

    setForm({
      ...form,
      credentialDateError: err,
      credentialDateErrorMessage: msj,
    });

    if (date == null) {
      err = true;
      msj = 'Introduce una fecha';
    } else if (date.diff(moment(), 'days') < 6) {
      err = true;
      msj = 'La fecha de expiración de la cuenta debe ser mayor a una semana';
    }

    setForm({
      ...form,
      credentialDate: date,
      credentialDateError: err,
      credentialDateErrorMessage: msj,
    });
  };



  const handleChange = event => {
    const userValidEmail = {
      nameError: false,
      userNameErrorMessage: '',
    };
    if (event.target.name === 'rolesPolicies') {
      if (event.target.value === 'Add') {
        setRolesPoliciesAdd('Add');
        setAgregar(true);
      } else if (event.target.value === 'Roles') {
        setRolesPoliciesAdd('Roles');
      } else if (event.target.value === 'Políticas') {
        setRolesPoliciesAdd('Políticas');
      }
    } else if (
      event.target.name === 'enabled' ||
      event.target.name === 'accountNonExpired' ||
      event.target.name === 'accountNonLocked' ||
      event.target.name === 'credentialsNonExpired'
    ) {
      let value = event.target.checked;
      if (
        event.target.name === 'accountNonExpired' ||
        event.target.name === 'accountNonLocked' ||
        event.target.name === 'credentialsNonExpired'
      ) {
        value = !value;
      }
      setState({ ...state, [event.target.name]: value });
    } else if (event.target.name === 'emailUser') {
      if (event.target.value.length === 0) {
        userValidEmail.nameError = false;
        userValidEmail.userNameErrorMessage = '';
      } else if (isValidEmail(event.target.value)) {
        userValidEmail.nameError = true;
        userValidEmail.userNameErrorMessage = '';
      } else {
        userValidEmail.nameError = false;
        userValidEmail.userNameErrorMessage = 'Ingrese un correo electrónico valido';
      }
    }

    setForm({
      ...form,
      userId: dataObject,
      [event.target.name]: event.target.value,
      nameError: userValidEmail.nameError,
      UserNameErrorMessage: userValidEmail.userNameErrorMessage,
    });
    setCloseClick(true);
  };

  const generatePassword = longitud => {
    const caracteres = 'abcdefghijkmnpqrtuvwxyzABCDEFGHIJKLMNPQRTUVWXYZ123467890';
    let contraseña = '';
    for (let i = 0; i < longitud; i += 1)
      contraseña += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
    return contraseña;
  };

  const resetPasswordUser = () => {
    const pass = generatePassword(16);
    resetPassword(form.emailUser, pass, pass)
      .then(response => {
        setForm({
          ...form,
          userId: response.id,
        });
        setReseted(true);
        setPassword(pass);
        setSeePassword(true);
        setIsLoading(false);
      })
      .catch(error => {
        if (error !== undefined) {
          messageError(error.userNameErrorMessage, 'error');
        } else messageError('Servicio no disponible.', 'warning');

        setIsLoading(false);
      });
    setConfirmationPassword({
      ...confirmationPassword,
      confirmationChange: false,
      confirmationOpen: false,
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    setIsLoading(true);
    const rolesAdd = [];
    const policesAdd = [];
    updateRol.accessPolicy.forEach(rolPolicy => {
      if (rolPolicy.type === 'Rol') {
        rolesAdd.push(rolPolicy.id);
      } else if (rolPolicy.type === 'Política') {
        policesAdd.push(rolPolicy.id);
      }
    });

    if (dataObject === 0) {
      if (isValidEmail(form.emailUser)) {
        if (validateAccountDate() && validateCredentialsDate()) {
          const pass = generatePassword(16);
          createUser(
            form.nameUser,
            form.emailUser,
            pass,
            form.extensionUser,
            form.storeUser,
            form.accountDate,
            form.credentialDate,
            rolesAdd,
            policesAdd,
          )
            .then(response => {
              setForm({
                ...form,
                userId: response.id,
              });
              setPassword(pass);
              setSeePassword(true);
              setIsLoading(false);
              refreshTable();
            })
            .catch(error => {
              if (error !== undefined) {
                setForm({
                  ...form,
                  emailUserError: !!error.userNameErrorMessage,
                  UserNameErrorMessage: error.userNameErrorMessage,
                });
              } else {
                messageError('Servicio no disponible.', 'warning');
                refreshTable();
              }
              setIsLoading(false);
            });
        } else {
          setIsLoading(false);
        }
      } else {
        const mensaje = 'Ingrese un correo electrónico valido';
        setForm({
          ...form,
          emailUserError: !!mensaje,
          UserNameErrorMessage: mensaje,
        });
        setIsLoading(false);
      }
    } else {
      updateUserById(
        form.userId,
        form.nameUser,
        form.emailUser,
        form.extensionUser,
        form.departmentUser,
        state.enabled,
        rolesAdd,
        policesAdd,
      )
        .then(() => {
          toggleForm();
          setForm(initialState);
          setIsLoading(false);
          messageError('Administrador actualizado', 'success');
          refreshTable();
        })
        .catch(error => {
          if (error !== undefined) {
            setForm({
              ...form,
              emailUserError: !!error.userNameErrorMessage,
              UserNameErrorMessage: error.userNameErrorMessage,
            });
          } else {
            refreshTable();
            messageError('Servicio no disponible.', 'warning');
          }
          setIsLoading(false);
        });
    }
  };

  const clearData = () => {
    setCloseClick('');
    setEditForm(false);
    setUpdateRol({ accessPolicy: [] });
    setState(stateInit);
    setAgregar(true);
    setRolesPoliciesAdd('Add');
    setForm({
      ...form,
      accountDate: moment()
        .add(1, 'M')
        .startOf('day'),
      accountDateError: false,
      accountDateErrorMessage: '',
      credentialDate: moment()
        .add(1, 'M')
        .startOf('day'),
      credentialDateError: false,
      credentialDateErrorMessage: '',
    });
  };

  const closeAdministratorDataForm = () => {
    setDataAdministratorOpen(!dataAdministratorOpen);
  };

  const initData = () => {
    if (dataObject === 0) {
      setForm({
        ...form,
        userId: 0,
        emailUser: '',
        nameUser: '',
        extensionUser: '',
        appDescription: '',
        departmentUser : [],

      });
    } else {
      getUserById(dataObject)
        .then(response => {
            console.log(response);
          setForm({
            userId: response.id,
            emailUser: response.email,
            nameUser: response.name,
            extensionUser: response.extension,
            accountDate: moment(response.accountExpirationDate),
            accountDateError: false,
            accountDateErrorMessage: '',
            credentialDate: moment(response.credentialsExpirationDate),
            credentialDateError: false,
            credentialDateErrorMessage: '',
            departmentUser : response.department,
          });

          setState({
            enabled: response.active,
            accountNonExpired: response.accountNonExpired,
            credentialsNonExpired: response.credentialsNonExpired,
            accountNonLocked: response.accountNonLocked,
          });
        })
        .catch(() => {
          return Promise.resolve(setForm({}));
        });

      getUserAuthorityById(dataObject)
        .then(response => {
          const rolesPolicy = [];
          response.roles.forEach(rol => {
            const rolPoliceSave = {
              id: rol.id,
              type: 'Rol',
              name: rol.name,
            };
            rolesPolicy.push(rolPoliceSave);
          });
          response.policies.forEach(rol => {
            const rolPoliceSave = {
              id: rol.id,
              type: 'Política',
              name: rol.name,
            };
            rolesPolicy.push(rolPoliceSave);
          });

          setUpdateRol({ accessPolicy: rolesPolicy });
        })
        .catch(() => {
          return Promise.resolve(setUpdateRol({ accessPolicy: [] }));
        });
    }
    getAllPolicies(false)
      .then(response => {
        const policiesAdministrator = [];
        if (response?.content)
          response.content.forEach(policy => {
            if (policy.name !== 'MOTUAccess') {
              policiesAdministrator.push(policy);
            }
          });
        return Promise.resolve(setPolicies(policiesAdministrator));
      })
      .catch(() => {
        return Promise.resolve(setPolicies([]));
      });

    getAllRoles(false)
      .then(response => {
        return Promise.resolve(setRoles(response.content));
      })
      .catch(() => {
        return Promise.resolve(setRoles([]));
      });

    getAllDepartments()
        .then(response => {
            return Promise.resolve(setDepartments(response.departments));
        })
        .catch(() => {
          return Promise.resolve(setDepartments([]));
      });

    setCloseClick(false);
    dataObject !== 0 ? setEditForm(false) : setEditForm(true);

  };

  const disableFormControl = () => {
    if (isLoading) {
      return true;
    }
    if (dataObject === 0) {
      return false;
    }
    if (dataObject !== 0 && editForm) {
      return false;
    }
    return true;
  };


  const actionClose = () => {
    setOpen(!open);
  };

  const setDataAdmin = () => {
    setDataAdministratorOpen(true);
  };

  const hide = () => {
    setSeePassword(false);
  };

  return (
    <Box>
      <Drawer
        anchor="right"
        open={formOpen}
        onClose={toggleForm}
        className={classes.container}
        classes={{ paper: classes.containerPaper }}
      >
        <Box className={classes.header}>
          <UsersIcon color="primary" className={classes.headerIcon} />
          <Box flexGrow={1}>
            <Typography variant="h5" color="primary">
              {dataObject === 0 ? 'Nuevo Administrador' : 'Actualizar Administrador'}
            </Typography>
          </Box>
          {dataObject !== 0 && (
            <IconButton
              disabled={!canUpdateUser}
              color="primary"
              className={classes.headerCloseButton}
              onClick={() => {
                setEditForm(!editForm);
              }}
            >
              <EditIcon />
            </IconButton>
          )}
          <IconButton
            color="secondary"
            className={classes.headerCloseButton}
            onClick={() => {
              toggleForm();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Box component="form" className={classes.form} onSubmit={handleSubmit}>
          <Box>
            <TextField
              error={!!form.UserNameErrorMessage}
              helperText={
                form.UserNameErrorMessage === 'El correo ya esta registrado'
                  ? msgBin
                  : form.UserNameErrorMessage
              }
              name="emailUser"
              label="Email"
              margin="normal"
              variant="outlined"
              fullWidth
              disabled={dataObject !== 0}
              value={form.emailUser}
              onChange={handleChange}
            />
            <TextField
                name="nameUser"
                label="Nombre"
                margin="normal"
                variant="outlined"
                fullWidth
                disabled={disableFormControl()}
                value={form.nameUser}
                onChange={handleChange}
            />
            <TextField
                name="extensionUser"
                label="Extencion"
                margin="normal"
                variant="outlined"
                fullWidth
                disabled={disableFormControl()}
                value={form.extensionUser}
                onChange={handleChange}
            />
            <Autocomplete
                multiple
                disabled={disableFormControl()}
                id="tags-outlined"
                options={departments}
                getOptionLabel={option => option.name}
                filterSelectedOptions
                value={
                  form.departmentUser ? form.departmentUser : []
                }
                onChange={(event, newValues) => {
                    setForm({
                        ...form, departmentUser : newValues,
                    });
                }}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip label={option.name} {...getTagProps({ index })} />
                    ))
                }
                renderInput={params => (
                    <TextField
                        {...params}
                        fullWidth
                        variant="outlined"
                        label="Departamentos"
                        placeholder="Departamento"
                    />
                )}
            />
            {dataObject > 0 && (
              <Card className={classes.card}>
                <CardContent className={classes.cardContent} style={{ paddingBottom: 16 }}>
                  <Box className={classes.formCardInput}>
                    <FormLabel style={{ flexGrow: 1 }} color="primary">
                      {state.enabled ? 'Deshabilitar Administrador' : 'Habilitar Administrador'}
                    </FormLabel>
                    <FormControlLabel
                      disabled={disableFormControl()}
                      style={{ margin: 0 }}
                      control={
                        <Switch
                          name="enabled"
                          color="primary"
                          checked={state.enabled}
                          onChange={handleChange}
                        />
                      }
                    />
                  </Box>
                  <Box className={classes.formCardInput} style={{ display: 'none' }}>
                    <FormLabel style={{ flexGrow: 1 }} color="primary">
                      {state.accountNonLocked ? 'Bloquear Cuenta' : 'Desbloquear Cuenta'}
                    </FormLabel>
                    <FormControlLabel
                      disabled={disableFormControl()}
                      style={{ margin: 0 }}
                      control={
                        <Switch
                          name="accountNonLocked"
                          color="primary"
                          checked={!state.accountNonLocked}
                          onChange={handleChange}
                        />
                      }
                    />
                  </Box>
                </CardContent>
              </Card>
            )}

            <Box className={classes.selectForm}>
              <FormControl className={classes.selectStyle}>
                <InputLabel htmlFor="filled-permission-native-simple">Permiso</InputLabel>
                <InputLabel htmlFor="select-rolesPolicies">Permiso</InputLabel>
                <Select
                  disabled={disableFormControl()}
                  // native
                  onChange={handleChange}
                  inputProps={{
                    name: 'rolesPolicies',
                    id: 'select-rolesPolicies',
                  }}
                  value={rolesPoliciesAdd}
                >
                  <MenuItem value="Add" name="Add" key={0}>
                    Seleccione Permiso
                  </MenuItem>
                  <MenuItem value="Roles" name="Roles" key={1}>
                    Roles
                  </MenuItem>
                  <MenuItem value="Políticas" name="Políticas" key={2}>
                    Política
                  </MenuItem>
                </Select>
              </FormControl>

              {rolesPoliciesAdd === 'Add' && (
                <FormControl className={classes.selectStyle}>
                  <InputLabel htmlFor="select-Rol-Policy">Rol/Política</InputLabel>
                  <Select
                    disabled={disableFormControl()}
                    // native
                    onChange={handleChange}
                    inputProps={{
                      name: 'agregar',
                      id: 'select-Rol-Policy',
                    }}
                    value={rolesPoliciesAdd}
                  >
                    <MenuItem value="Add" name="Add" key={0}>
                      Seleccione Rol/Política
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
              {rolesPoliciesAdd === 'Roles' && (
                <Box className={classes.selectStyle}>
                  <Autocomplete
                    id="combo-box-roles"
                    options={roleOptions.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter),
                    )}
                    renderOption={option => (
                      <option value={option.id} name={option.id} key={option.id}>
                        {`${option.name}`}
                      </option>
                    )}
                    disabled={disableFormControl()}
                    getOptionLabel={option => `${option.name}`}
                    noOptionsText="No hay roles disponibles"
                    autoComplete
                    onChange={(event, value) => {
                      setAgregar(!(value?.id > 0));
                      setAdministratorForm({
                        id: value?.id || 0,
                        type: 'Rol',
                        name: value?.name || 0,
                      });
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Roles"
                        variant="standard"
                        fullWidth
                        multiline
                      />
                    )}
                  />
                </Box>
              )}
              {rolesPoliciesAdd === 'Políticas' && (
                <Box className={classes.selectStyle}>
                  <Autocomplete
                    id="combo-box-politices"
                    options={policyOptions.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter),
                    )}
                    renderOption={option => (
                      <option value={option.id} name={option.id} key={option.id}>
                        {`${option.name}`}
                      </option>
                    )}
                    disabled={disableFormControl()}
                    getOptionLabel={option => `${option.name}`}
                    noOptionsText="No hay políticas disponibles"
                    autoComplete
                    onChange={(event, value) => {
                      setAgregar(!(value?.id > 0));
                      setAdministratorForm({
                        id: value?.id || 0,
                        type: 'Política',
                        name: value?.name || 0,
                      });
                    }}
                    renderInput={params => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        label="Políticas"
                        variant="standard"
                        fullWidth
                        multiline
                      />
                    )}
                  />
                </Box>
              )}
              <Box>
                <Fab
                  size="small"
                  className={classes.selectAddButton}
                  color="primary"
                  onClick={() => {
                    const tempPolicy = { ...updateRol };
                    tempPolicy.accessPolicy.unshift(administratorForm);
                    setUpdateRol(tempPolicy);
                    setAgregar(true);
                    setAdministratorForm({});
                  }}
                  disabled={agregar}
                >
                  <Tooltip title="Agregar Rol/Política" aria-label="add">
                    <AddIcon />
                  </Tooltip>
                </Fab>
              </Box>
            </Box>

            <MaterialTable
              title="Roles/Políticas"
              tableRef={tableUserRef}
              columns={[
                { title: 'Permiso', field: 'type', type: 'string' },
                { title: 'Nombre', field: 'name', type: 'string' },
              ]}
              data={updateRol.accessPolicy}
              options={{
                toolbar: true,
                search: false,
                paging: false,
                actionsColumnIndex: -1,
                toolbarButtonAlignment: 'right',
                sorting: true,
                addRowPosition: 'first',
                headerStyle: {
                  paddingTop: 0,
                },
                padding: 'dense',
                detailPanelColumnAlignment: 'right',
              }}
              localization={{
                body: {
                  emptyDataSourceMessage: 'No hay roles/políticas que mostrar',
                  editRow: {
                    deleteText: '¿Esta seguro de borrar el rol/política seleccionado?',
                    saveTooltip: 'Aceptar',
                    cancelTooltip: 'Cancelar',
                  },
                  deleteTooltip: 'Eliminar Rol/Política',
                },
                header: {
                  actions: 'Acciones',
                },
              }}
              icons={{
                Delete: () => <DeleteIcon color="secondary" className="icon-small" />,
              }}
              editable={
                !disableFormControl()
                  ? {
                      onRowDelete: oldData =>
                        new Promise(resolve => {
                          setTimeout(() => {
                            {
                              const deleteApplicationAction = { ...updateRol };
                              const index = deleteApplicationAction.accessPolicy.indexOf(oldData);
                              deleteApplicationAction.accessPolicy.splice(index, 1);
                              setUpdateRol(deleteApplicationAction);
                            }
                            resolve();
                          }, 200);
                        }),
                    }
                  : undefined
              }
            />
          </Box>

          <Box className={classes.bottomSpace}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={disableFormControl()}
              style={{ borderRadius: 30 }}
              size="small"
            >
              {dataObject === 0 ? 'Guardar Administrador' : 'Actualizar Administrador'}
            </Button>
            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            {formOpen && closeClick === '' && initData()}
            {!formOpen && closeClick !== '' && clearData()}
          </Box>
        </Box>
        {dataObject > 0 && resetPasswordByEmail && (
          <Box className={classes.bottomSpace}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              style={{ borderRadius: 30 }}
              size="small"
              onClick={() =>
                setConfirmationPassword({ ...confirmationPassword, confirmationOpen: true })
              }
            >
              Restaurar Contraseña
            </Button>
            {confirmationPassword.confirmationChange && resetPasswordUser()}
          </Box>
        )}
      </Drawer>
      <Box>
        <CopyPasswordDialog
          seePassword={seePassword}
          open={open}
          password={password}
          userError={form.emailUserError}
          txtUserError={form.emailUserErrorMessage}
          action={toggleForm}
          actionClose={actionClose}
          actionChange={handleChange}
          actionDataAdmin={setDataAdmin}
          hideDialog={hide}
          reseted={reseted}
        />
        <Confirmation
          open={confirmationPassword.confirmationOpen}
          hideDialog={() =>
            setConfirmationPassword({ ...confirmationPassword, confirmationOpen: false })
          }
          changeOk={() =>
            setConfirmationPassword({
              ...confirmationPassword,
              confirmationChange: true,
              confirmationOpen: false,
            })
          }
          changeCancel={() =>
            setConfirmationPassword({
              ...confirmationPassword,
              confirmationChange: false,
              confirmationOpen: false,
            })
          }
        />
      </Box>

    </Box>
  );
}

AdministratorForm.defaultProps = {
  refreshTable: null,
  dataObject: 0,
  canUpdateUser: false,
  canGetUserData: false,
  canUpdateUserData: false,
};

AdministratorForm.propTypes = {
  refreshTable: PropTypes.func,
  formOpen: PropTypes.bool.isRequired,
  toggleForm: PropTypes.func.isRequired,
  dataObject: PropTypes.number,
  canUpdateUser: PropTypes.bool,
  canGetUserData: PropTypes.bool,
  canUpdateUserData: PropTypes.bool,
};

export default AdministratorForm;
