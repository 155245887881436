import React, { useState, useEffect } from 'react';
import {
  Drawer,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Divider,
  Fab,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  List as CataloguesIcon,
  GpsFixed as TerritoriesIcons,
  LocalShipping as PortagesIcon,
  Waves as WavesIcon,
  SwapCalls as RoutesIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Build as BuildIcon,
  AllInbox as AllInboxIcon,
  Ballot as BallotIcon,
  Gavel as GavelIcon,
  Layers as LayersIcon,
  LocationSearching as LocationSearchingIcon,
} from '@material-ui/icons';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import clsx from 'clsx';

// Routes
import { WAVES_ROUTES } from '../../../Routes';

// Security
import Permissions from '../../../AppsPermissions/WavesAdministratorPermissions';
import { hasAuthority } from '../../AccessValidators';

const drawerWidth = 220;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.primary.contrastText,
    left: 'auto',
  },
  nestedItemList: {
    paddingLeft: theme.spacing(4),
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(4),
    transition: theme.transitions.create('margin'),
    marginLeft: -drawerWidth,
    width: theme.spacing(1),
  },
  contentShift: {
    transition: theme.transitions.create('margin'),
    marginLeft: 0,
    width: theme.spacing(1),
  },
  fabOpen: {
    transition: theme.transitions.create(['left', 'background-color', 'color']),
    position: 'fixed',
    height: 36,
    width: 36,
    left: 58 + drawerWidth,
    zIndex: 1201,
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
    },
  },
  fabClose: {
    transition: theme.transitions.create(['left', 'background-color', 'color']),
    position: 'fixed',
    height: 36,
    width: 36,
    left: 58,
    zIndex: 1201,
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

export default function WavesDrawer() {
  const classes = useStyles();
  const { push } = useHistory();
  const { url } = useRouteMatch();
  const [open, setOpen] = useState(true);
  const [canShowWaves, setCanShowWaves] = useState(false);
  const [canShowPortages, setCanShowPortages] = useState(true);

  useEffect(() => {
    // hasAuthority(Permissions.GetAllShops, setCanShowStores);
    // hasAuthority(Permissions.GetAllPortages, setCanShowPortages);
  }, []);

  const handleDrawerClose = () => {
    setOpen(!open);
  };

  return (
    <Box className={classes.root}>
      <Drawer
        id="app-waves-router-drawer"
        className={classes.drawer}
        variant="persistent"
        open={open}
        onClose={handleDrawerClose}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <List disablePadding>
          <ListItem button onClick={() => push(`${url}`)}>
            <ListItemIcon>
              <AllInboxIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Olas" />
          </ListItem>
          <ListItem button onClick={() => push(`${url}${WAVES_ROUTES.WAVES}`)}>
            <ListItemIcon>
              <BuildIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Constructor" />
          </ListItem>
          <ListItem button onClick={() => push(`${url}${WAVES_ROUTES.SUPPLYORDERS}`)}>
            <ListItemIcon>
              <BallotIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Ordenes de Surtido" />
          </ListItem>
          <ListItem button onClick={() => push(`${url}${WAVES_ROUTES.PICKING}`)}>
            <ListItemIcon>
              <LayersIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Ordenes de Picking" />
          </ListItem>
          <ListItem button onClick={() => push(`${url}${WAVES_ROUTES.ZONES}`)}>
            <ListItemIcon>
              <LocationSearchingIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Zonas" />
          </ListItem>
            <ListItem button onClick={() => push(`${url}${WAVES_ROUTES.TEAMS}`)}>
                <ListItemIcon>
                    <GroupAddIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Equipos" />
            </ListItem>
        </List>
      </Drawer>
      <Box
        component="main"
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <Box>
          <Divider orientation="vertical" variant="inset" absolute light />
          <Fab
            onClick={handleDrawerClose}
            className={clsx(classes.fabOpen, {
              [classes.fabClose]: !open,
            })}
            size="small"
          >
            {open ? <ChevronLeftIcon fontSize="small" /> : <ChevronRightIcon fontSize="small" />}
          </Fab>
        </Box>
      </Box>
    </Box>
  );
}
