import React, { useState, useRef, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { Visibility as ViewIcon, KeyboardArrowRight, KeyboardArrowDown } from '@material-ui/icons';

// Components
import RoleForm from '../components/RoleForm';
import PageHeader from '../../../components/PageHeader';
import DataDetail from '../../../components/DataDetail';

// Security
import Permissions from '../../../AppsPermissions/RolesAndPoliciesAdministratorPermissions';
import { hasAuthority } from '../../AccessValidators';

// Styles
import { AppStyles } from '../../../AppStyles';

// Data Api
import { getAllRoles, deleteRole } from '../../../api/RoleApi';

// Routes
import { APP_ROUTES, USERS_ROUTES } from '../../../Routes';
import WithSnackBarMessages from '../../../components/WithSnackBarMessages';
import DataTable from '../../../components/DataTable';

const breadcrumbsData = [
  { id: 1, label: 'Usuarios', base: APP_ROUTES.USERS },
  { id: 2, label: 'Roles y Políticas', base: APP_ROUTES.USERS + USERS_ROUTES.ROLES },
  { id: 3, label: 'Roles', base: APP_ROUTES.USERS + USERS_ROUTES.ROLES},
];

function RolesPage() {
  const appStyles = AppStyles();
  const tableRef = useRef();
  const [openForm, setOpenForm] = useState(false);
  const [dataObject, setDataObject] = useState(0);

  const [canGetAllRoles, setCanGetAllRoles] = useState(false);
  const [canGetRole, setCanGetRole] = useState(false);
  const [canCreateRole, setCanCreateRole] = useState(false);
  const [canUpdateRole, setCanUpdateRole] = useState(false);
  const [canDeleteRole, setCanDeleteRole] = useState(false);

  const toggleForm = () => {
    if (tableRef.current) {
      tableRef.current.state.query.page = 0;
      if (openForm) {
        tableRef.current.onQueryChange();
      }
    }
    setOpenForm(!openForm);
    setDataObject(0);
  };

  useEffect(() => {
    hasAuthority(Permissions.GetAllRoles, setCanGetAllRoles).then();
    hasAuthority(Permissions.GetRoleById, setCanGetRole).then();
    hasAuthority(Permissions.CreateRole, setCanCreateRole).then();
    hasAuthority(Permissions.UpdateRole, setCanUpdateRole).then();
    hasAuthority(Permissions.DeleteRole, setCanDeleteRole).then();
  }, []);

  const getRolesData = query =>
      //console.log(canGetAllRoles);
      canGetAllRoles ?
          getAllRoles(true, query.pageSize, query.page, query.search)
              .then(response => ({
                  ...query,
                  data: response.data,
                  page: response.current_page-1,
                  totalCount: response.total
              }))
              .catch(() => ({
                  ...query,
                  page: 0,
                  pageSize: 15,
                  totalCount: 0,
                  data: [],
              }))
          : new Promise(resolve => {
              resolve({...query, data: [], page: 0, totalCount: 0});
          });


  const deleteRoleData = oldData =>
    new Promise((resolve, reject) => {
      setTimeout(() => {
        deleteRole(oldData.id)
          .then(() => {
            if (tableRef.current) {
              tableRef.current.state.query.page = 0;
              tableRef.current.onQueryChange();
            }
          })
          .catch(() => reject());
        resolve();
      }, 1000);
    });

  useEffect(() => {
    tableRef.current && tableRef.current.onQueryChange();
  }, [canGetAllRoles]);

  return (
    <Box id="app-users-roles-page" component="main" className={appStyles.pageRoot}>
        <PageHeader
            title="Roles"
            titleButton="Nuevo Rol"
            displayButton
            canExecuteButton={canCreateRole}
            action={toggleForm}
            breadcrumbsData={breadcrumbsData}
        />
        <RoleForm
            formOpen={openForm}
            toggleForm={toggleForm}
            refreshTable={() => tableRef.current && tableRef.current.onQueryChange()}
            dataObject={dataObject}
            canUpdateRole={canUpdateRole}
        />
        <DataTable
            nameEntity="Rol"
            columns={[
                { title: 'Id', field:'id',type:'numeric'},
                { title: 'Nombre', field: 'name', type: 'string' },
                { title: 'Descripción', field: 'description', type: 'string' },
            ]}
            tableActions={[
                {
                    disabled: !canGetRole,
                    icon: () => (<ViewIcon color={canGetRole ? 'primary' : 'disabled'} className="icon-small" />),
                    tooltip: canGetRole ? 'Ver Rol' : undefined,
                    onClick: (event, rowData) => {
                        setDataObject(rowData.id);
                        setOpenForm(!openForm);
                    },
                },
            ]}
            tableRef          = {tableRef}
            getDataFunction   = {getRolesData}
            deleteDataFunction= {deleteRoleData}
            canGetAll         = {canGetAllRoles}
            canDelete         = {canDeleteRole}
            tableDetailPanel  = {[
                {
                    tooltip: 'Mas Información',
                    icon: () => <KeyboardArrowRight color="primary" />,
                    openIcon: () => <KeyboardArrowDown color="primary" />,
                    render: rowData => { return <DataDetail rowData={rowData} />;},
                },
            ]}
        />
    </Box>
  );
}

export default WithSnackBarMessages(RolesPage);
