import React from 'react';

import { Route, Switch } from 'react-router-dom';
import AppRoute from './components/AppRoute';
import { APP_ROUTES } from './Routes';

import NotFound from './pages/NotFoundPage';
import LoginPage from './pages/Login/pages/LoginPage';
import HomePage from './pages/HomePage';
import UsersRouter from './pages/Users/UsersRouter';
import WavesRouter from './pages/Waves/WavesRouter';
import ProfileRouter from "./pages/Perfil/ProfileRouter";
import PriorityRouter from "./pages/Priority/PriorityRouter";
import ResetPasswordPage from "./pages/ForgotPassword/pages/ResetPasswordPage";
import ChangePasswordPage from "./pages/ForgotPassword/pages/ChangePasswordPage";

export default function Routes() {
  return (
    <Switch>
      <AppRoute exact path={APP_ROUTES.LOGIN} component={LoginPage} redirectTo={APP_ROUTES.HOME} />
      <AppRoute exact path={APP_ROUTES.RESETPASSWORD}  component={ResetPasswordPage} redirectTo={APP_ROUTES.HOME} />
      <AppRoute exact path={APP_ROUTES.CHANGEPASSWORD} component={ChangePasswordPage} redirectTo={APP_ROUTES.HOME} />
      <AppRoute exact path={APP_ROUTES.HOME} component={HomePage} requireAuth />
      <AppRoute path={APP_ROUTES.USERS} component={UsersRouter} requireAuth />
      <AppRoute path={APP_ROUTES.WAVES} component={WavesRouter} requireAuth />
      <AppRoute path={APP_ROUTES.PROFILE} component={ProfileRouter} requireAuth />
      <AppRoute path={APP_ROUTES.PRIORITY} component={PriorityRouter} requireAuth />
      <Route component={NotFound} />
    </Switch>
  );
}
