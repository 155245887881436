import { makeStyles } from '@material-ui/core/styles';

export const AppStyles = makeStyles(theme => ({
  pageRouter: {
    display: 'flex',
    flexGrow: 1,
  },
  pageRoot: {
    flexGrow: 1,
    padding: '20px',
  },
  pageControlsBox: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    justifyContent: 'space-between',
  },
  pageControlsBox2: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    justifyContent: 'space-between',
  },
  pageControlsBreadcrumbs: {
    marginBottom: theme.spacing(1),
  },
  tableGroup: {
    marginBottom: theme.spacing(1),
  },
}));
