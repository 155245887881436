import React, { useState, useRef, useEffect } from 'react';
import { Box, TextField, Chip } from '@material-ui/core';
import {
  Visibility as ViewIcon,
  KeyboardArrowDown,
  KeyboardArrowRight,
  AccountTree as TreeIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

// Components
import MaterialTable from 'material-table';
import PageHeader from '../../../components/PageHeader';
import WithSnackBarMessages from '../../../components/WithSnackBarMessages';
import DataDetail from '../../../components/DataDetail';
import OrderGroupDetail from '../components/OrderGroupDetail';

// Security
import Permissions from '../../../AppsPermissions/ShopsAdministratorPermissions';
import { hasAuthority } from '../../AccessValidators';

// Styles
import { AppStyles } from '../../../AppStyles';
import materialTableLocaleES from '../../../components/MaterialTableLocaleES';

// Data Apis
import {
  getAllOrderGroups,
  deleteSupplyOrder,
  getOrderGroupData,
  getOrderGroupExcel,
} from '../../../api/WaveServices/WavesApi';

// Routes
import { APP_ROUTES, WAVES_ROUTES } from '../../../Routes';

const breadcrumbsData = [
  { id: 1, label: 'Listado', base: APP_ROUTES.WAVES },
  {
    id: 2,
    label: 'Ordenes de Surtido',
    base: APP_ROUTES.WAVES + WAVES_ROUTES.SUPPLYORDERS,
  },
];

const useStyles = makeStyles(() => ({
  actionDescriptionEdit: {
    '& .MuiInput-root': {
      fontSize: 'small',
    },
  },
}));

const renderOrderGroupsDetail = rowData => {
  const columns = [
    { title: 'Division', field: 'division' },
    {
      title: 'Piezas',
      field: 'pieces',
      type: 'numeric',
      render: rowData => {
        return `${rowData.pieces.toLocaleString()} (~${Math.round(rowData.pieces / 25)} Cajas)`;
      },
    },
    {
      title: 'En Ola',
      field: 'in_wave',
      type: 'numeric',
      render: rowData => {
        return `${rowData.in_wave.toLocaleString()} (~${Math.round(rowData.in_wave / 25)} Cajas)`;
      },
    },
    {
      title: 'Pendientes',
      field: 'pending',
      type: 'numeric',
      render: rowData => {
        return `${rowData.pending.toLocaleString()} (~${Math.round(rowData.pending / 25)} Cajas)`;
      },
    },
  ];
  return (
    <MaterialTable
      columns={columns}
      data={rowData.divisions}
      title="Distribución de divisiones"
      options={{
        search: false,
        paging: false,        
        toolbar: false,
        rowStyle: {
          backgroundColor: '#EEE',
        },
      }}
      localization={materialTableLocaleES}
    />
  );
};

function SupplyOrdersPage() {
  const classes = useStyles();
  const appStyles = AppStyles();
  const [openForm, setOpenForm] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const tableRef = useRef();
  const [dataObject, setDataObject] = useState(0);
  const [dataDetail, setDataDetail] = useState({});
  const [canGetAllSupplyOrders, setCanGetAllSupplyOrders] = useState(true);

  const toggleForm = () => {
    if (tableRef.current) {
      tableRef.current.state.query.page = 0;
      if (openForm) {
        tableRef.current.onQueryChange();
      }
    }
    setDataObject(0);
    setOpenForm(!openForm);
  };

  const toggleDetailDrawer = () => {
    setDataDetail(0);
    setOpenDetail(!openDetail);
  };

  useEffect(() => {
    // hasAuthority(Permissions.GetAllRoutes, setCanGetAllRoutes);
    // hasAuthority(Permissions.CreateRoute, setCanCreateRoute);
    // hasAuthority(Permissions.UpdateRouteByID, setCanUpdateRoute);
    // hasAuthority(Permissions.DeleteRouteByID, setCanDeleteRoute);
    // hasAuthority(Permissions.AddShopToRouteByID, setCanAddShopToRoute);
    // hasAuthority(Permissions.RemoveShopFromRouteByID, setRemoveShopFromRoute);
  }, []);

  const getSupplyOrders = query =>
    getAllOrderGroups(true, query.pageSize, query.page, query.search)
      .then(response => ({
        ...query,
        data: response.data,
        page: response.current_page - 1,
        totalCount: response.total,
      }))
      .catch(() => ({
        ...query,
        page: 0,
        pageSize: 15,
        totalCount: 0,
        data: [],
      }));

  const downloadXlsx = orderGroupId => {
    getOrderGroupExcel(orderGroupId)
      .then(response => {
        const contentDisposition = response.headers['content-disposition'];
        const contentType = response.headers['content-type'];
        const filename = contentDisposition.match(/filename="(.+)"/)[1];
        const file = new Blob([response.data], { type: contentType });
        // For Internet Explorer and Edge
        if ('msSaveOrOpenBlob' in window.navigator) {
          window.navigator.msSaveOrOpenBlob(file, filename);
        }
        // For Firefox and Chrome
        else {
          // Bind blob on disk to ObjectURL
          const data = URL.createObjectURL(file);
          const a = document.createElement('a');
          a.style = 'display: none';
          a.href = data;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          // For Firefox
          setTimeout(function() {
            document.body.removeChild(a);
            // Release resource on disk after triggering the download
            window.URL.revokeObjectURL(data);
          }, 100);
        }
      })
      .catch(e => {
        console.log(e);
      });
  };

  return (
    <Box id="app-routes-page" component="main" className={appStyles.pageRoot}>
      <OrderGroupDetail
        formOpen={openDetail}
        toggleForm={toggleDetailDrawer}
        dataObject={dataDetail}
      />
      <MaterialTable
        columns={[
          { title: 'Fecha de creación', field: 'created_at', type: 'datetime' },
          { title: 'Grupo', field: 'order_group' },
          {
            title: 'Piezas totales',
            field: 'pieces',
            type: 'numeric',
            render: rowData => {
              return `${rowData.total_pieces.toLocaleString()} (~${Math.round(
                rowData.total_pieces / 25,
              )} Cajas)`;
            },
          },
          {
            title: 'Piezas en Ola',
            field: 'pieces',
            type: 'numeric',
            render: rowData => {
              return `${rowData.total_in_wave.toLocaleString()} (~${Math.round(
                rowData.total_in_wave / 25,
              )} Cajas)`;
            },
          },
          {
            title: 'Piezas pendientes',
            field: 'pieces',
            type: 'numeric',
            render: rowData => {
              return `${rowData.total_pending.toLocaleString()} (~${Math.round(
                rowData.total_pending / 25,
              )} Cajas)`;
            },
          },
        ]}
        title="Ordenes de Surtido"
        localization={materialTableLocaleES}
        tableRef={tableRef}
        data={getSupplyOrders}
        options={{
          search: false,
          paging: false,
          toolbar: true,          
        }}
        detailPanel={rowData => renderOrderGroupsDetail(rowData)}
        actions={[
          {
            icon: () => <ViewIcon color="primary" className="icon-small" />,
            tooltip: 'Buscar detalles por SKU',
            onClick: (event, rowData) => {
              setDataDetail(rowData);
              setOpenDetail(!openDetail);
            },
          },
          {
            icon: () => <TreeIcon color="primary" className="icon-small" />,
            tooltip: 'Exportar detalles a excel',
            onClick: (event, rowData) => {
              downloadXlsx(rowData.order_group_id);
            },
          },
        ]}
      />
    </Box>
  );
}

export default WithSnackBarMessages(SupplyOrdersPage);
