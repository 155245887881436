import React, { useState, useRef, useEffect } from 'react';
import { Box, TextField, Chip } from '@material-ui/core';
import { Visibility as ViewIcon, KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

// Components
import MaterialTable from 'material-table';
import WaveForm from '../components/WaveForm';
import WaveDetail from '../components/WaveDetail';
import PageHeader from '../../../components/PageHeader';
import WithSnackBarMessages from '../../../components/WithSnackBarMessages';
import DataDetail from '../../../components/DataDetail';

// Security
import Permissions from '../../../AppsPermissions/ShopsAdministratorPermissions';
import { hasAuthority } from '../../AccessValidators';

// Styles
import { AppStyles } from '../../../AppStyles';

// Data Apis
import { getAllWaves, deleteWave, getWaveData } from '../../../api/WaveServices/WavesApi';

// Routes
import { APP_ROUTES, WAVES_ROUTES } from '../../../Routes';
import DataTable from '../../../components/DataTable';

const breadcrumbsData = [
  { id: 1, label: 'Listado', base: APP_ROUTES.WAVES },
  {
    id: 2,
    label: 'Olas',
    base: APP_ROUTES.WAVES + WAVES_ROUTES.WAVES,
  },
];

const useStyles = makeStyles(() => ({
  actionDescriptionEdit: {
    '& .MuiInput-root': {
      fontSize: 'small',
    },
  },
}));

const getWaveRoutesData = waveId =>
  getWaveData(waveId)
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => console.log(error));

const renderOrdersDetail = rowData => {
  const columns = [
    { title: 'Orden', field: 'id' },
    { title: 'No. de tienda', field: 'storeNumber' },
    { title: 'Tienda', field: 'store' },
    { title: 'Ruta', field: 'storeRoute' },
    { title: 'Piezas', field: 'pieces' },
  ];
  return (
    <MaterialTable
      columns={columns}
      data={rowData.orders}
      options={{
        search: false,
        paging: false,
        padding: 'dense',
        selection: true,
        toolbar: false,
        rowStyle: {
          backgroundColor: '#EEE',
        },
      }}
    />
  );
};

const waveData = [
  { id: 1, description: 'hola', pieces: 10, color: 'azul' },
  { id: 2, description: 'hola', pieces: 10, color: 'azul' },
  { id: 3, description: 'hola', pieces: 10, color: 'azul' },
];

const columns = [
  { title: 'No. de Ruta', field: 'id' },
  { title: 'Nombre de Ruta', field: 'description' },
  { title: 'Piezas', field: 'pieces' },
  { title: 'Color', field: 'color' },
];

const renderRoutesDetail = async waveId => {
  const columns = [
    { title: 'No. de Ruta', field: 'id' },
    { title: 'Nombre de Ruta', field: 'description' },
    { title: 'Piezas', field: 'pieces' },
    { title: 'Color', field: 'color' },
  ];
  // const waveData = await getWaveRoutesData(waveId);
  const waveData = [
    { id: 1, description: 'hola', pieces: 10, color: 'azul' },
    { id: 2, description: 'hola', pieces: 10, color: 'azul' },
    { id: 3, description: 'hola', pieces: 10, color: 'azul' },
  ];
  return (
    <MaterialTable
      columns={columns}
      data={waveData}
      options={{
        search: false,
        paging: false,
        padding: 'dense',
        selection: true,
        rowStyle: {
          backgroundColor: '#EEE',
        },
      }}
      detailPanel={rowData => renderOrdersDetail(rowData)}
    />
  );
};

function WavePage() {
  const classes = useStyles();
  const appStyles = AppStyles();
  const [openForm, setOpenForm] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const tableRef = useRef();
  const [dataObject, setDataObject] = useState(0);
  const [dataDetail, setDataDetail] = useState(0);
  const [canGetAllWaves, setCanGetAllWaves] = useState(true);
  const [canCreateRoute, setCanCreateRoute] = useState(true);
  const [canUpdateRoute, setCanUpdateRoute] = useState(true);
  const [canDeleteRoute, setCanDeleteRoute] = useState(true);
  const [canAddShopToRoute, setCanAddShopToRoute] = useState(true);
  const [canRemoveShopFromRoute, setRemoveShopFromRoute] = useState(true);

  const toggleForm = () => {
    if (tableRef.current) {
      tableRef.current.state.query.page = 0;
      if (openForm) {
        tableRef.current.onQueryChange();
      }
    }
    setDataObject(0);
    setOpenForm(!openForm);
  };

  const toggleDetailDrawer = () => {
    setDataDetail(0);
    setOpenDetail(!openDetail);
  };

  useEffect(() => {
    // hasAuthority(Permissions.GetAllRoutes, setCanGetAllRoutes);
    // hasAuthority(Permissions.CreateRoute, setCanCreateRoute);
    // hasAuthority(Permissions.UpdateRouteByID, setCanUpdateRoute);
    // hasAuthority(Permissions.DeleteRouteByID, setCanDeleteRoute);
    // hasAuthority(Permissions.AddShopToRouteByID, setCanAddShopToRoute);
    // hasAuthority(Permissions.RemoveShopFromRouteByID, setRemoveShopFromRoute);
  }, []);

  const getWavesData = query =>
    getAllWaves(true, query.pageSize, query.page, query.search)
      .then(response => ({
        ...query,
        data: response.data,
        page: response.current_page - 1,
        totalCount: response.total,
      }))
      .catch(() => ({
        ...query,
        page: 0,
        pageSize: 15,
        totalCount: 0,
        data: [],
      }));

  const deleteRouteData = oldData =>
    new Promise((resolve, reject) => {
      setTimeout(() => {
        deleteWave(oldData.id)
          .then(() => {
            if (tableRef.current) {
              tableRef.current.state.query.page = 0;
              tableRef.current.onQueryChange();
            }
          })
          .catch(() => reject());
        resolve();
      }, 1000);
    });

  useEffect(() => {
    tableRef.current && tableRef.current.onQueryChange();
  }, [canGetAllWaves]);

  return (
    <Box id="app-routes-page" component="main" className={appStyles.pageRoot}>
      <PageHeader
        title="Olas"
        titleButton="Nueva Ola"
        displayButton
        canExecuteButton
        action={toggleForm}
        breadcrumbsData={breadcrumbsData}
      />

      <WaveDetail formOpen={openDetail} toggleForm={toggleDetailDrawer} dataObject={dataDetail} />

      <WaveForm
        formOpen={openForm}
        toggleForm={toggleForm}
        refreshTable={() => tableRef.current && tableRef.current.onQueryChange()}
        dataObject={dataObject}
        canUpdateRole={canUpdateRoute}
        canAddShopToRoute={canAddShopToRoute}
        canRemoveShopFromRoute={canRemoveShopFromRoute}
      />

      <DataTable
        nameEntity="Ola"
        columns={[
          { title: 'Fecha de creación', field: 'created_at', type: 'datetime' },
          { title: 'Grupo', field: 'ordergroup.description' },
          {
            title: 'Division(es)',
            field: 'business_rules',
            render: rowData => {
              const parsed = JSON.parse(rowData.business_rules);              
              return (
                <Box>
                  {parsed.divisionsNames.map(name => {                    
                    return <Box key={'r'+rowData.id+'-c1-'+name}><Chip label={name} variant="outlined" color="primary" size="small" /></Box>;
                  })}
                </Box>
              );
            },
          },
          {
            title: 'Excepcion(es)',
            field: 'business_rules',
            render: rowData => {
              const parsed = JSON.parse(rowData.business_rules);
              return (
                <Box>
                  <Box>
                    {parsed.excludedDepartmentsNames.length > 0 ? 'Dptos:' : null}
                    <Box>
                      {parsed.excludedDepartmentsNames.map(name => {
                        return <Chip label={name} variant="outlined" color="secondary" size="small" key={'r'+rowData.id+'-c2-'+name}/>;
                      })}
                    </Box>
                  </Box>
                  <Box>
                    {parsed.excludedRoutesNames.length > 0 ? 'Rutas:' : null}
                    <Box>
                      {parsed.excludedRoutesNames.map(name => {    
                        return <Chip label={name} variant="outlined" color="secondary" size="small" key={'r'+rowData.id+'-c3-'+name} />;
                      })}
                    </Box>
                  </Box>
                </Box>
              );
            },
          },
          { title: 'Piezas', field: 'pieces', type: 'numeric',render: rowData => { return rowData.pieces.toLocaleString() } },
        ]}
        tableActions={[
          {
            icon: () => <ViewIcon color="primary" className="icon-small" />,
            tooltip: 'Ver Ola',
            onClick: (event, rowData) => {
              setDataDetail(rowData.id);
              setOpenDetail(!openDetail);
            },
          },
        ]}
        tableRef={tableRef}
        getDataFunction={getWavesData}
        deleteDataFunction={deleteRouteData}
        canGetAll        
      />
    </Box>
  );
}

export default WithSnackBarMessages(WavePage);
