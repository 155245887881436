import React from 'react';
import { Button, Box, Typography, Breadcrumbs } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Add as AddIcon } from '@material-ui/icons';
import { Link as LinkRouter } from 'react-router-dom';

// Styles
import { AppStyles } from '../AppStyles';

export default function PageHeader({
  title,
  titleButton,
  displayButton,
  canExecuteButton,
  action,
  breadcrumbsData,
}) {
  const appStyles = AppStyles();

  return (
    <Box>
      <Box className={appStyles.pageControlsBox}>
        <Typography variant="h4" component="main">
          {title}
        </Typography>
        {displayButton && (
          <Button
            variant="contained"
            color="primary"
            style={{ borderRadius: 20 }}
            size="small"
            disabled={!canExecuteButton}
            onClick={() => action()}
          >
            <AddIcon />
            {titleButton}
          </Button>
        )}
      </Box>
      <Box className={appStyles.pageControlsBreadcrumbs}>
        <Breadcrumbs aria-label="breadcrumb">
          {breadcrumbsData.map((item, index) => {
            const last = index === breadcrumbsData.length - 1;
            return last ? (
              <Typography key={item.id}>{item.label}</Typography>
            ) : (
              <LinkRouter key={item.id} to={item.base}>
                {item.label}
              </LinkRouter>
            );
          })}
        </Breadcrumbs>
      </Box>
    </Box>
  );
}

PageHeader.defaultProps = {
  titleButton: null,
  canExecuteButton: false,
  displayButton: false,
  action: null,
};

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  titleButton: PropTypes.string,
  displayButton: PropTypes.bool,
  canExecuteButton: PropTypes.bool,
  action: PropTypes.func,
  breadcrumbsData: PropTypes.arrayOf(PropTypes.object).isRequired,
};
