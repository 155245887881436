import React, { useState, useEffect } from 'react';
import { Box, Backdrop, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AppRouter from './AppRouter';
import { UserProvider } from './UserContext';
import { removeCookie } from './AppCookies';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 2,
    color: theme.palette.primary.light,
  },
}));

export default function App() {
  const classes = useStyles();
  const [user, setUser] = useState(false);

  useEffect(() => {
    if (user) {
      removeCookie('user_token');
      window.location.reload();
    }
  }, [user]);

  return (
    <Box id="app-root">
      <UserProvider value={{ user, setUser }}>
        <Backdrop className={classes.backdrop} open={user}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <AppRouter />
      </UserProvider>
    </Box>
  );
}
