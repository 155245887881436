/* eslint-disable no-console */
import * as Sentry from '@sentry/browser';

const SERVER_NAME = 'CCP Order Manager Administrator';
const ENVIRONMENT = process.env.REACT_APP_PROFILE;

export default class Logger {
  constructor(fileName: String) {
    this.fileName = fileName;
  }

  buildSentryEvent(
    functionName: String,
    message: String,
    level: Sentry.Severity,
    extra: Object,
  ): Sentry.Event {
    return {
      message,
      level,
      logger: `${this.fileName}.${functionName}()`,
      server_name: SERVER_NAME,
      extra,
    };
  }

  info(functionName: String, message: String, extra: Object = {}, toSentry: Boolean = true): void {
    const sentryEvent = this.buildSentryEvent(functionName, message, Sentry.Severity.Info, extra);
    ENVIRONMENT === 'LOCAL' && console.info({ [sentryEvent.level]: sentryEvent });
    toSentry && Sentry.captureEvent(sentryEvent);
  }

  debug(functionName: String, message: String, extra: Object = {}, toSentry: Boolean = true): void {
    const sentryEvent = this.buildSentryEvent(functionName, message, Sentry.Severity.Debug, extra);
    ENVIRONMENT === 'LOCAL' && console.debug({ [sentryEvent.level]: sentryEvent });
    toSentry && Sentry.captureEvent(sentryEvent);
  }

  warning(
    functionName: String,
    message: String,
    extra: Object = {},
    toSentry: Boolean = true,
  ): void {
    const sentryEvent = this.buildSentryEvent(
      functionName,
      message,
      Sentry.Severity.Warning,
      extra,
    );
    ENVIRONMENT === 'LOCAL' && console.warn({ [sentryEvent.level]: sentryEvent });
    toSentry && Sentry.captureEvent(sentryEvent);
  }

  error(functionName: String, message: String, extra: Object = {}, toSentry: Boolean = true): void {
    const sentryEvent = this.buildSentryEvent(functionName, message, Sentry.Severity.Error, extra);
    ENVIRONMENT === 'LOCAL' && console.error({ [sentryEvent.level]: sentryEvent });
    toSentry && Sentry.captureEvent(sentryEvent);
  }
}
