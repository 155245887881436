import { apiClientBearerToken } from '../ApiClientConfig';
import { getCookie } from '../../AppCookies';
import Logger from '../../Logger';

const Log = new Logger('src/api/ShopServices/ShopsApi.js');

const SHOPS_PATH = '/stores';

export const getAllShops = (): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${SHOPS_PATH}/all`, {
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getAllShops.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getShopById = (id: Number): Promise<Object> => {
  return apiClientBearerToken()
    .get(`${SHOPS_PATH}/${id}`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getShopById.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      const errors = {
        errorCode: error.response.request.response.code,
        errorMessage: error.response.request.response.message,
      };
      return Promise.reject(errors);
    });
};

export const createShop = (
  tdaJda: String,
  name: String,
  email: String,
  branchOffice: String,
  telephone: String,
  telephoneExtension: String,
  surface: Number,
  status: Boolean,
  comingSoon: Boolean,
  ranking: Number,
  managerName: String,
  idDistrict: Number,
): Promise<Object> => {
  const userToken = getCookie('user_token');

  return apiClientBearerToken(userToken.access_token)
    .post(SHOPS_PATH, {
      tdaJda,
      name,
      email,
      branchOffice: branchOffice === '' ? null : branchOffice,
      telephone: telephone === '' ? null : telephone,
      telephoneExtension: telephoneExtension === '' ? null : telephoneExtension,
      surface: surface === '' ? null : surface,
      status,
      comingSoon,
      ranking: ranking === '' ? null : ranking,
      managerName: managerName === '' ? null : managerName,
      district: idDistrict > 0 ? { id: idDistrict } : null,
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      if (error.response) {
        if (!error.response.data.errors) {
          const errorDuplicate = {
            shopTdaJdaMessageError: 'Código de JDA duplicado.',
          };
          return Promise.reject(errorDuplicate);
        }
        const errors = {
          shopNameErrorMessage: null,
          shopTdaJdaMessageError: null,
          shopEmailMessageError: null,
          shopBranchOfficeMessageError: null,
          shopTelephoneMessageError: null,
          shopTelephoneExtensionMessageError: null,
          shopDistrictIdMessageError: null,
          shopManagerNameMessageError: null,
          shopSurfaceMessageError: null,
        };
        error.response.data.errors.forEach(e => {
          if (e.field === 'shop.name') {
            errors.shopNameErrorMessage = e.message;
          }
          if (e.field === 'shop.tdaJda') {
            errors.shopTdaJdaMessageError = e.message;
          }
          if (e.field === 'shop.email') {
            errors.shopEmailMessageError = e.message;
          }
          if (e.field === 'shop.branchOffice') {
            errors.shopBranchOfficeMessageError = e.message;
          }
          if (e.field === 'shop.telephone') {
            errors.shopTelephoneMessageError = e.message;
          }
          if (e.field === 'shop.telephoneExtension') {
            errors.shopTelephoneExtensionMessageError = e.message;
          }
          if (e.field === 'shop.district.id') {
            errors.shopDistrictIdMessageError = e.message;
          }
          if (e.field === 'shop.managerName') {
            errors.shopManagerNameMessageError = e.message;
          }
          if (e.field === 'shop.surface') {
            errors.shopSurfaceMessageError = e.message;
          }
        });

        return Promise.reject(errors);
      }

      Log.error(createShop.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const updateShopById = (
  id: Number,
  tdaJda: String,
  name: String,
  email: String,
  branchOffice: String,
  telephone: String,
  telephoneExtension: String,
  surface: Number,
  status: Boolean,
  comingSoon: Boolean,
  ranking: Number,
  managerName: String,
  idDistrict: Number,
): Promise<Object> => {
  const userToken = getCookie('user_token');

  return apiClientBearerToken(userToken.access_token)
    .patch(`${SHOPS_PATH}/${id}`, {
      tdaJda,
      name,
      email,
      branchOffice: branchOffice === '' ? null : branchOffice,
      telephone: telephone === '' ? null : telephone,
      telephoneExtension: telephoneExtension === '' ? null : telephoneExtension,
      surface: surface === '' ? null : surface,
      status,
      comingSoon,
      ranking: ranking === '' ? null : ranking,
      managerName: managerName === '' ? null : managerName,
      district: idDistrict > 0 ? { id: idDistrict } : null,
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      if (error.response) {
        if (!error.response.data.errors) {
          const errorDuplicate = {
            shopTdaJdaMessageError: 'Código de JDA duplicado.',
          };
          return Promise.reject(errorDuplicate);
        }
        const errors = {
          shopNameErrorMessage: null,
          shopTdaJdaMessageError: null,
          shopEmailMessageError: null,
          shopBranchOfficeMessageError: null,
          shopTelephoneMessageError: null,
          shopTelephoneExtensionMessageError: null,
          shopManagerNameMessageError: null,
          shopSurfaceMessageError: null,
          shopDistrictIdMessageError: null,
        };
        error.response.data.errors.forEach(e => {
          if (e.field === 'shop.name') {
            errors.shopNameErrorMessage = e.message;
          }
          if (e.field === 'shop.tdaJda') {
            errors.shopTdaJdaMessageError = e.message;
          }
          if (e.field === 'shop.email') {
            errors.shopEmailMessageError = e.message;
          }
          if (e.field === 'shop.branchOffice') {
            errors.shopBranchOfficeMessageError = e.message;
          }
          if (e.field === 'shop.telephone') {
            errors.shopTelephoneMessageError = e.message;
          }
          if (e.field === 'shop.telephoneExtension') {
            errors.shopTelephoneExtensionMessageError = e.message;
          }
          if (e.field === 'shop.managerName') {
            errors.shopManagerNameMessageError = e.message;
          }
          if (e.field === 'shop.surface') {
            errors.shopSurfaceMessageError = e.message;
          }
          if (e.field === 'shop.district.id') {
            errors.shopDistrictIdMessageError = e.message;
          }
        });

        return Promise.reject(errors);
      }

      Log.error(updateShopById.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const deleteShop = (id: Number, restore?: Boolean = false): Promise<Object> => {
  return apiClientBearerToken()
    .delete(`${SHOPS_PATH}/${id}`, {
      params: {
        restore,
      },
    })
    .then(() => {
      return Promise.resolve();
    })
    .catch(error => {
      Log.error(
        deleteShop.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

// Address
export const getAddressByID = (id: Number): Promise<Object> => {
  return apiClientBearerToken()
    .get(`${SHOPS_PATH}/${id}/address`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getAddressByID.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const updateAddressByID = (
  id: Number,
  street: String,
  latitude: Number,
  longitude: Number,
  state: String,
  city: String,
  zip: String,
  extNumber: String,
  intNumber: String,
  neighborhood: String,
  town: String,
  countryId: Number,
): Promise<Object> => {
  const userToken = getCookie('user_token');

  return apiClientBearerToken(userToken.access_token)
    .patch(`${SHOPS_PATH}/${id}/address`, {
      street,
      latitude,
      longitude,
      state,
      city,
      zip,
      extNumber: extNumber === '' ? null : extNumber,
      intNumber: intNumber === '' ? null : intNumber,
      neighborhood: neighborhood === '' ? null : neighborhood,
      town: town === '' ? null : town,
      country: countryId > 0 ? { id: countryId } : null,
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      if (error.response) {
        if (!error.response.data.errors) {
          const errorDuplicate = {
            errorMessage: 'Código de JDA duplicado.',
          };
          return Promise.reject(errorDuplicate);
        }
        const errors = {
          addressStreetErrorMessage: null,
          addressLatitudeMessageError: null,
          addressLongitudeMessageError: null,
          addressStateMessageError: null,
          addressCityMessageError: null,
          addressZipMessageError: null,
          addressExtNumberMessageError: null,
          addressIntNumberMessageError: null,
          addressNeighborhoodMessageError: null,
          addressTownMessageError: null,
          addressCountryMessageError: null,
        };
        error.response.data.errors.forEach(e => {
          if (e.field === 'address.street') {
            errors.addressStreetErrorMessage = e.message;
          }
          if (e.field === 'address.latitude') {
            errors.addressLatitudeMessageError = e.message;
          }
          if (e.field === 'address.longitude') {
            errors.addressLongitudeMessageError = e.message;
          }
          if (e.field === 'address.state') {
            errors.addressStateMessageError = e.message;
          }
          if (e.field === 'address.city') {
            errors.addressCityMessageError = e.message;
          }
          if (e.field === 'address.zip') {
            errors.addressZipMessageError = e.message;
          }
          if (e.field === 'address.extNumber') {
            errors.addressExtNumberMessageError = e.message;
          }
          if (e.field === 'address.intNumber') {
            errors.addressIntNumberMessageError = e.message;
          }
          if (e.field === 'address.neighborhood') {
            errors.addressNeighborhoodMessageError = e.message;
          }
          if (e.field === 'address.town') {
            errors.addressTownMessageError = e.message;
          }
          if (e.field === 'address.country') {
            errors.addressCountryMessageError = e.message;
          }
        });

        return Promise.reject(errors);
      }

      Log.error(updateAddressByID.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

// Portage
export const getShopPortageByID = (id: Number): Promise<Object> => {
  return apiClientBearerToken()
    .get(`${SHOPS_PATH}/${id}/portage`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getShopPortageByID.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const updateShopPortageByID = (id: Number, portageId: Number): Promise<Object> => {
  const userToken = getCookie('user_token');

  return apiClientBearerToken(userToken.access_token)
    .patch(`${SHOPS_PATH}/${id}/portage`, {
      id: portageId === 0 ? '' : portageId,
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      if (error.response) {
        if (!error.response.data.errors) {
          const errorDuplicate = {
            portageIdErrorMessage: 'Seleccione un porteo',
          };
          return Promise.reject(errorDuplicate);
        }
        const errors = {
          portageIdErrorMessage: null,
        };
        error.response.data.errors.forEach(e => {
          if (e.field === 'portage.id') {
            errors.portageIdErrorMessage = e.message;
          }
        });

        return Promise.reject(errors);
      }

      Log.error(updateShopPortageByID.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

// Schedule   Get Schedule By ID
export const getScheduleByID = (id: Number): Promise<Object> => {
  return apiClientBearerToken()
    .get(`${SHOPS_PATH}/${id}/schedule`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getScheduleByID.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const updateScheduleByID = (
  id: Number,
  mondayOpen: String,
  mondayClose: String,
  mondayAvailable: Boolean,
  tuesdayOpen: String,
  tuesdayClose: String,
  tuesdayAvailable: Boolean,
  wednesdayOpen: String,
  wednesdayClose: String,
  wednesdayAvailable: Boolean,
  thursdayOpen: String,
  thursdayClose: String,
  thursdayAvailable: Boolean,
  fridayOpen: String,
  fridayClose: String,
  fridayAvailable: Boolean,
  saturdayOpen: String,
  saturdayClose: String,
  saturdayAvailable: Boolean,
  sundayOpen: String,
  sundayClose: String,
  sundayAvailable: Boolean,
): Promise<Object> => {
  const userToken = getCookie('user_token');

  return apiClientBearerToken(userToken.access_token)
    .patch(`${SHOPS_PATH}/${id}/schedule`, {
      mondayOpen,
      mondayClose,
      mondayAvailable,
      tuesdayOpen,
      tuesdayClose,
      tuesdayAvailable,
      wednesdayOpen,
      wednesdayClose,
      wednesdayAvailable,
      thursdayOpen,
      thursdayClose,
      thursdayAvailable,
      fridayOpen,
      fridayClose,
      fridayAvailable,
      saturdayOpen,
      saturdayClose,
      saturdayAvailable,
      sundayOpen,
      sundayClose,
      sundayAvailable,
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      if (error.response) {
        const errors = {
          mondayMessageError: null,
          tuesdayMessageError: null,
          wednesdayMessageError: null,
          thursdayMessageError: null,
          fridayMessageError: null,
          saturdayMessageError: null,
          sundayMessageError: null,
        };
        error.response.data.errors.forEach(e => {
          if (e.field === 'shop.{schedule.days.validator.monday}') {
            errors.mondayMessageError = e.message;
          }
          if (e.field === 'shop.{schedule.days.validator.tuesday}') {
            errors.tuesdayMessageError = e.message;
          }
          if (e.field === 'shop.{schedule.days.validator.wednesday}') {
            errors.wednesdayMessageError = e.message;
          }
          if (e.field === 'shop.{schedule.days.validator.thursday}') {
            errors.thursdayMessageError = e.message;
          }
          if (e.field === 'shop.{schedule.days.validator.friday}') {
            errors.fridayMessageError = e.message;
          }
          if (e.field === 'shop.{schedule.days.validator.saturday}') {
            errors.saturdayMessageError = e.message;
          }
          if (e.field === 'shop.{schedule.days.validator.sunday}') {
            errors.sundayMessageError = e.message;
          }
        });

        return Promise.reject(errors);
      }

      Log.error(updateScheduleByID.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

// Delivery

export const getDeliveryByID = (id: Number): Promise<Object> => {
  return apiClientBearerToken()
    .get(`${SHOPS_PATH}/${id}/delivery`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getDeliveryByID.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const updateDeliveryByID = (
  id: Number,
  available: Boolean,
  mondayOpen: String,
  mondayClose: String,
  mondayAvailable: Boolean,
  tuesdayOpen: String,
  tuesdayClose: String,
  tuesdayAvailable: Boolean,
  wednesdayOpen: String,
  wednesdayClose: String,
  wednesdayAvailable: Boolean,
  thursdayOpen: String,
  thursdayClose: String,
  thursdayAvailable: Boolean,
  fridayOpen: String,
  fridayClose: String,
  fridayAvailable: Boolean,
  saturdayOpen: String,
  saturdayClose: String,
  saturdayAvailable: Boolean,
  sundayOpen: String,
  sundayClose: String,
  sundayAvailable: Boolean,
): Promise<Object> => {
  const userToken = getCookie('user_token');

  return apiClientBearerToken(userToken.access_token)
    .patch(`${SHOPS_PATH}/${id}/delivery`, {
      available,
      schedule: {
        mondayOpen,
        mondayClose,
        mondayAvailable,
        tuesdayOpen,
        tuesdayClose,
        tuesdayAvailable,
        wednesdayOpen,
        wednesdayClose,
        wednesdayAvailable,
        thursdayOpen,
        thursdayClose,
        thursdayAvailable,
        fridayOpen,
        fridayClose,
        fridayAvailable,
        saturdayOpen,
        saturdayClose,
        saturdayAvailable,
        sundayOpen,
        sundayClose,
        sundayAvailable,
      },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      if (error.response) {
        const errors = {
          mondayMessageError: null,
          tuesdayMessageError: null,
          wednesdayMessageError: null,
          thursdayMessageError: null,
          fridayMessageError: null,
          saturdayMessageError: null,
          sundayMessageError: null,
          invalidField: null,
        };
        error.response.data.errors.forEach(e => {
          if (e.message.includes('Lunes') || e.message.includes('Monday')) {
            errors.mondayMessageError = e.message;
          }
          if (e.message.includes('Martes') || e.message.includes('Tuesday')) {
            errors.tuesdayMessageError = e.message;
          }
          if (e.message.includes('Miércoles') || e.message.includes('Wednesday')) {
            errors.wednesdayMessageError = e.message;
          }
          if (e.message.includes('Jueves') || e.message.includes('Thursday')) {
            errors.thursdayMessageError = e.message;
          }
          if (e.message.includes('Viernes') || e.message.includes('Friday')) {
            errors.fridayMessageError = e.message;
          }
          if (e.message.includes('Sábado') || e.message.includes('Saturday')) {
            errors.saturdayMessageError = e.message;
          }
          if (e.message.includes('Domingo') || e.message.includes('Sunday')) {
            errors.sundayMessageError = e.message;
          }
          if (e.field === 'Invalid fields.' || e.field === 'Campos inválidos.') {
            errors.invalidField = e.message;
          }
        });

        return Promise.reject(errors);
      }

      Log.error(updateDeliveryByID.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

// Pay Methods

export const getPayMethodsByID = (id: Number): Promise<Object> => {
  return apiClientBearerToken()
    .get(`${SHOPS_PATH}/${id}/paymethods`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getPayMethodsByID.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const updateShopPayMethodsByID = (id: Number, payMethods: Array): Promise<Object> => {
  const userToken = getCookie('user_token');

  return apiClientBearerToken(userToken.access_token)
    .patch(`${SHOPS_PATH}/${id}/paymethods`, {
      payMethods,
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        updateShopPayMethodsByID.name,
        error.message,
        error.response ? error.response : error,
      );
      return Promise.reject(error);
    });
};

// Services

export const getServicesByID = (id: Number): Promise<Object> => {
  return apiClientBearerToken()
    .get(`${SHOPS_PATH}/${id}/services`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getServicesByID.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const updateShopServicesByID = (id: Number, shopServices: Array): Promise<Object> => {
  const userToken = getCookie('user_token');

  return apiClientBearerToken(userToken.access_token)
    .patch(`${SHOPS_PATH}/${id}/services`, {
      shopServices,
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        updateShopServicesByID.name,
        error.message,
        error.response ? error.response : error,
      );
      return Promise.reject(error);
    });
};

// Types

export const getTypesByID = (id: Number): Promise<Object> => {
  return apiClientBearerToken()
    .get(`${SHOPS_PATH}/${id}/types`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getTypesByID.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const updateShopTypesByID = (id: Number, shopTypes: Array): Promise<Object> => {
  const userToken = getCookie('user_token');

  return apiClientBearerToken(userToken.access_token)
    .patch(`${SHOPS_PATH}/${id}/types`, {
      shopTypes,
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(updateShopTypesByID.name, error.message, error.response ? error.response : error);
      return Promise.reject(error);
    });
};

// Divisions
export const getDivisionsByID = (id: Number): Promise<Object> => {
  return apiClientBearerToken()
    .get(`${SHOPS_PATH}/${id}/divisions`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getDivisionsByID.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const updateDivisionsByID = (id: Number, divisions: Array): Promise<Object> => {
  const userToken = getCookie('user_token');

  return apiClientBearerToken(userToken.access_token)
    .patch(`${SHOPS_PATH}/${id}/divisions`, {
      divisions,
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(updateDivisionsByID.name, error.message, error.response ? error.response : error);
      return Promise.reject(error);
    });
};
// Departments
export const getDepartmentsByID = (id: Number): Promise<Object> => {
  return apiClientBearerToken()
    .get(`${SHOPS_PATH}/${id}/departments`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getDepartmentsByID.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const updateDepartmentsByID = (id: Number, departments: Array): Promise<Object> => {
  const userToken = getCookie('user_token');

  return apiClientBearerToken(userToken.access_token)
    .patch(`${SHOPS_PATH}/${id}/departments`, {
      departments,
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(updateDepartmentsByID.name, error.message, error.response ? error.response : error);
      return Promise.reject(error);
    });
};

// Routes
export const getShopRoutesByID = (id: Number): Promise<Object> => {
  return apiClientBearerToken()
    .get(`${SHOPS_PATH}/${id}/routes`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getShopRoutesByID.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};
