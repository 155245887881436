import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import PropTypes from 'prop-types';

// Components
import DataTimeDetail from './DataTimeDetail';
// import AdicionalDataDetail from './AdicionalDataDetail';

export default function DataDetail({ rowData, fullRow, showAdicionalData, adicionalData }) {
  return (
    <Box id="table-row-detail-panel" bgcolor={grey[300]} color={grey[700]}>
      <Typography align="center">Work in progress...</Typography>
      {/* <DataTimeDetail rowData={rowData} fullRow={fullRow} /> */}
      {/* {showAdicionalData && !isLoading && <AdicionalDataDetail adicionalData={adicionalData} />} */}
    </Box>
  );
}

DataDetail.defaultProps = {
  showAdicionalData: false,
  fullRow: false,
  adicionalData: [],
};

DataDetail.propTypes = {
  rowData: PropTypes.objectOf(PropTypes.object).isRequired,
  showAdicionalData: PropTypes.bool,
  adicionalData: PropTypes.arrayOf(PropTypes.object),
  fullRow: PropTypes.bool,
};
