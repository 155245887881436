/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { Snackbar, SnackbarContent, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { amber, green } from '@material-ui/core/colors';
import {
  Error as ErrorIcon,
  CheckCircle as SuccessIcon,
  Info as InfoIcon,
  Warning as WarnIcon,
  Close as CloseIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const variantIcon = {
  success: SuccessIcon,
  warning: WarnIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const snackbarWrapperStyles = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function MySnackbarContentWrapper(props) {
  const classes = snackbarWrapperStyles();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    />
  );
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};

const snackbarStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

export default function SnackBarMessage({ open, message, horizontal, vertical, onClose, variant }) {
  const classes = snackbarStyles();
  return (
    <Snackbar
      open={open}
      anchorOrigin={{ horizontal, vertical }}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      onClose={onClose}
    >
      <MySnackbarContentWrapper
        onClose={onClose}
        variant={variant}
        message={message}
        className={classes.margin}
      />
    </Snackbar>
  );
}

SnackBarMessage.defaultProps = {
  horizontal: 'left',
  vertical: 'bottom',
  open: false,
  message: 'Message',
};

SnackBarMessage.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  horizontal: PropTypes.string,
  vertical: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.string.isRequired,
};
