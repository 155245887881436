export default {
  AppKey: 'AP_OTSSR',
  GetAllCountries: 'AP_OTSSR:AC_GEEEL',
  GetCountryByID: 'AP_OTSSR:AC_YYYET',
  CreateCountry: 'AP_OTSSR:AC_RCOAT',
  UpdateCountryByID: 'AP_OTSSR:AC_PNCUI',
  DeleteCountryByID: 'AP_OTSSR:AC_NETNR',
  GetAllCurrencies: 'AP_OTSSR:AC_TLIGN',
  GetCurrencyByID: 'AP_OTSSR:AC_NCGTI',
  CreateCurrency: 'AP_OTSSR:AC_ERTNC',
  UpdateCurrencyByID: 'AP_OTSSR:AC_PCYUD',
  DeleteCurrencyByID: 'AP_OTSSR:AC_DCDIC',
  GetAllLanguages: 'AP_OTSSR:AC_GGGTL',
  GetLanguageByID: 'AP_OTSSR:AC_GLUDE',
  CreateLanguage: 'AP_OTSSR:AC_TNRCE',
  UpdateLanguageByID: 'AP_OTSSR:AC_AIAUP',
  DeleteLanguageByID: 'AP_OTSSR:AC_GLGDA',
  GetAllRegions: 'AP_OTSSR:AC_RSOER',
  GetRegionByID: 'AP_OTSSR:AC_REBIG',
  CreateRegion: 'AP_OTSSR:AC_GEOAE',
  UpdateRegionByID: 'AP_OTSSR:AC_NDDTE',
  DeleteRegionByID: 'AP_OTSSR:AC_ITETE',
  GetAllDistricts: 'AP_OTSSR:AC_CSTSS',
  GetDistrictByID: 'AP_OTSSR:AC_TTIRI',
  CreateDistrict: 'AP_OTSSR:AC_RDITT',
  UpdateDistrictByID: 'AP_OTSSR:AC_YDCTA',
  DeleteDistrictByID: 'AP_OTSSR:AC_ERTTT',
  GetAllShopTypes: 'AP_OTSSR:AC_ELELL',
  GetShopTypeByID: 'AP_OTSSR:AC_YGTGB',
  CreateShopType: 'AP_OTSSR:AC_CERTY',
  UpdateShopTypeByID: 'AP_OTSSR:AC_TEDYB',
  DeleteShopTypeByID: 'AP_OTSSR:AC_DEYPL',
  GetAllShopServices: 'AP_OTSSR:AC_EVGRS',
  GetShopServiceByID: 'AP_OTSSR:AC_ISGED',
  CreateShopService: 'AP_OTSSR:AC_ERIVV',
  UpdateShopServiceByID: 'AP_OTSSR:AC_EUTTE',
  DeleteShopServiceByID: 'AP_OTSSR:AC_RIETS',
  GetAllPayMethods: 'AP_OTSSR:AC_YASPM',
  GetPayMethodByID: 'AP_OTSSR:AC_IDGME',
  CreatePayMethod: 'AP_OTSSR:AC_EHYAA',
  UpdatePayMethodByID: 'AP_OTSSR:AC_BUUOD',
  DeletePayMethodByID: 'AP_OTSSR:AC_HOODE',
  GetAllDivisions: 'AP_OTSSR:AC_OLAON',
  GetDivisionByID: 'AP_OTSSR:AC_IYSBE',
  CreateDivision: 'AP_OTSSR:AC_IRREA',
  UpdateDivisionByID: 'AP_OTSSR:AC_PVIII',
  DeleteDivisionByID: 'AP_OTSSR:AC_OEDIO',
  GetAllDepartments: 'AP_OTSSR:AC_ENSDT',
  GetDepartmentByID: 'AP_OTSSR:AC_EYIDG',
  CreateDepartment: 'AP_OTSSR:AC_CEERA',
  UpdateDepartmentByID: 'AP_OTSSR:AC_TPTMT',
  DeleteDepartmentByID: 'AP_OTSSR:AC_DTPTT',
  GetAllPortages: 'AP_OTSSR:AC_RPLSG',
  GetPortageById: 'AP_OTSSR:AC_EDTEP',
  CreatePortage: 'AP_OTSSR:AC_TPPCG',
  UpdatePortageByID: 'AP_OTSSR:AC_EEOPP',
  GetAllPortageShopsByID: 'AP_OTSSR:AC_EAOYB',
  DeletePortageByID: 'AP_OTSSR:AC_EIYRP',
  GetPortageScheduleByID: 'AP_OTSSR:AC_TADGC',
  UpdatePortageScheduleByID: 'AP_OTSSR:AC_EYYGU',
  GetPortageAddressByID: 'AP_OTSSR:AC_AEEDE',
  UpdatePortageAddressByID: 'AP_OTSSR:AC_EOPGA',
  GetAllShops: 'AP_OTSSR:AC_SLLPA',
  GetShopById: 'AP_OTSSR:AC_OOSHT',
  CreateShop: 'AP_OTSSR:AC_OCRSP',
  UpdateShopByID: 'AP_OTSSR:AC_BDEYD',
  DeleteShopByID: 'AP_OTSSR:AC_EPPOL',
  GetShopPayMethodsByID: 'AP_OTSSR:AC_SOMTA',
  UpdateShopPayMethodsByID: 'AP_OTSSR:AC_HTODD',
  GetShopScheduleByID: 'AP_OTSSR:AC_HEEBH',
  UpdateShopScheduleByID: 'AP_OTSSR:AC_HHSDP',
  GetShopTypesByID: 'AP_OTSSR:AC_YYBST',
  UpdateShopTypesByID: 'AP_OTSSR:AC_SSTPE',
  GetShopServicesByID: 'AP_OTSSR:AC_EEPSE',
  UpdateShopServicesByID: 'AP_OTSSR:AC_SOSAI',
  GetShopDepartmentsByID: 'AP_OTSSR:AC_BETGG',
  UpdateShopDepartmentsByID: 'AP_OTSSR:AC_EEDSE',
  GetShopDivisionsByID: 'AP_OTSSR:AC_PDPNV',
  UpdateShopDivisionsByID: 'AP_OTSSR:AC_TIVSD',
  GetShopDeliveryByID: 'AP_OTSSR:AC_SHGYL',
  UpdateShopDeliveryByID: 'AP_OTSSR:AC_BDIDP',
  GetShopAddressByID: 'AP_OTSSR:AC_SESTH',
  UpdateShopAddressByID: 'AP_OTSSR:AC_TAYSP',
  GetShopPortageByID: 'AP_OTSSR:AC_OPTPR',
  UpdateShopPortageByID: 'AP_OTSSR:AC_BTOAA',
  GetAllColors: 'AP_OTSSR:AC_ACOSL',
  GetColorByID: 'AP_OTSSR:AC_GOGEG',
  CreateColor: 'AP_OTSSR:AC_OCCOO',
  UpdateColorByID: 'AP_OTSSR:AC_AOTTL',
  DeleteColorByID: 'AP_OTSSR:AC_YLELL',
  GetAllRoutes: 'AP_OTSSR:AC_AOGLG',
  GetRouteById: 'AP_OTSSR:AC_YYUTT',
  CreateRoute: 'AP_OTSSR:AC_ERRCE',
  UpdateRouteByID: 'AP_OTSSR:AC_EYOEY',
  DeleteRouteByID: 'AP_OTSSR:AC_BEERU',
  GetRouteShopsByID: 'AP_OTSSR:AC_BOIPY',
  AddShopToRouteByID: 'AP_OTSSR:AC_OPPYU',
  RemoveShopFromRouteByID: 'AP_OTSSR:AC_PURME',
  GetRoutePortagesByID: 'AP_OTSSR:AC_UGYSY',
  UpdateShopEmail: 'AP_OTSSR:AC_PEDAA',
  UpdateShopJDA: 'AP_OTSSR:AC_POPDD',
  UpdatePortageEmail: 'AP_OTSSR:AC_MTEAL',
  UpdatePortageJDA: 'AP_OTSSR:AC_PTRAA',
};
