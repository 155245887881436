import { apiClientBearerToken } from './ApiClientConfig';
import Logger from '../Logger';

const Log = new Logger('src/api/RoleApi.js');

const ROLES_PATH = '/role';

export const getAllRoles = (
  paginated: Boolean,
  size?: Number,
  page?: Number,
  search?: String,
  resourceView?: String,
): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(ROLES_PATH, {
      params: {
        paginated,
        size,
        page,
        search: search?.trim(),
        resourceView,
      },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getAllRoles.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getRoleById = (id: Number): Promise<Object> => {
  return apiClientBearerToken()
    .get(`${ROLES_PATH}/${id}`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(getRoleById.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const getPoliciesRoleById = (id: Number): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${ROLES_PATH}/${id}/policies`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(getPoliciesRoleById.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const updateRoleById = (id: Number, name: String, description: String, policies: Array): Promise<Object> => {
  return apiClientBearerToken()
      .post(ROLES_PATH+"/"+id, {
      name,
      description,
      policies,
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      if (error.response) {
        const errorDuplicate = {
          roleNameErrorMessage: 'Nombre del rol duplicado.',
        };
        return Promise.reject(errorDuplicate);
      }
      Log.error(updateRoleById.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const createRole = (name: String, description: String, policies: Array): Promise<Object> => {
    return apiClientBearerToken()
        .post(ROLES_PATH, {
            name,
            description,
            policies,
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            if (error.response) {
                if (!error.response.data.errors) {
                    const errorDuplicate = {
                        roleNameErrorMessage: 'Nombre del rol duplicado.',
                    };
                return Promise.reject(errorDuplicate);
                }

            const errors = {
              roleNameErrorMessage: null,
              roleDescriptionError: null,
              roleAccessMessage: null,
            };
        error.response.data.errors.forEach(e => {
          if (e.field === 'role.name') {
            errors.roleNameErrorMessage = e.message;
          }
          if (e.field === 'role.description') {
            errors.roleDescriptionError = e.message;
          }
          if (e.field === 'role.policies') {
            errors.roleAccessMessage = e.message;
          }
        });

        return Promise.reject(errors);
      }

      Log.error(createRole.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const getPoliciesApplicationActionById = (id: Number): Promise<Object> => {
  return apiClientBearerToken()
    .get(`${ROLES_PATH}/${id}/access`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getPoliciesApplicationActionById.name,
        error.message,
        error.response ? error.response : error,
      );
      return Promise.reject();
    });
};

export const deleteRole = (id: Number, restore?: Boolean): Promise<Object> => {
  return apiClientBearerToken()
    .post(`${ROLES_PATH}/delete/${id}`, {
      params: {
        restore,
      },
    })
    .then(() => {
      return Promise.resolve();
    })
    .catch(error => {
      Log.error(deleteRole.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};
