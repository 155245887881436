import React, { useState, useRef, useEffect } from 'react';
import { Box, Tooltip } from '@material-ui/core';
import {
    Visibility as ViewIcon,
    EventAvailable as AccountNonExpiredIcon,
    EventBusy as AccountExpiredIcon,
    Lock as AccountLockedIcon,
    LockOpen as AccountNonLockedIcon,
    RadioButtonChecked as EnableIcon,
    VerifiedUser as CredentialsNonExpiredIcon,
    Block as CredentialsExpiredIcon,
    KeyboardArrowRight,
    KeyboardArrowDown,
} from '@material-ui/icons';

// Components
import AdminForm from '../components/PriorityForm';
import PageHeader from '../../../components/PageHeader';
import WithSnackBarMessages from '../../../components/WithSnackBarMessages';
import DataTable from '../../../components/DataTable';
import DataDetail from '../../../components/DataDetail';

// Security
import Permissions from '../../../AppsPermissions/UsersAdministratorPermissions';
import { hasAuthority } from '../../AccessValidators';

// Styles
import { AppStyles } from '../../../AppStyles';

// Data Apis
import { getAllPriorities, deletePriority } from '../../../api/PriorityServices/PriorityApi';

// Routes
import { APP_ROUTES, USERS_ROUTES } from '../../../Routes';
import {useSnackbar} from "notistack";

const breadcrumbsData = [
    {
        id: 1,
        label: 'Prioridades',
        base: APP_ROUTES.PRIORITY,
    }
];

function PriorityPage() {
    const appStyles = AppStyles();
    const tableRef = useRef();
    const { enqueueSnackbar } = useSnackbar();
    const [openForm, setOpenForm] = useState(false);
    const [dataObject, setDataObject] = useState(0);
    const [canGetAllUsers, setCanGetAllUsers] = useState(true);
    const [canGetUser, setCanGetUser] = useState(true);
    const [canCreateUser, setCanCreateUser] = useState(true);
    const [canUpdateUser, setCanUpdateUser] = useState(true);
    const [canGetUserData, setCanGetUserData] = useState(true);
    const [canUpdateUserData, setCanUpdateUserData] = useState(true);
    const [canDeleteUser, setCanDeleteUser] = useState(true);

    const toggleForm = () => {
        if (tableRef.current) {
            tableRef.current.state.query.page = 0;
            if (openForm) {
                tableRef.current.onQueryChange();
            }
        }
        setOpenForm(!openForm);
        setDataObject(0);
    };
    const messageError = (message, variant) => {
        enqueueSnackbar(message, {
            variant,
        });
    };

    useEffect(() => {
        /*
        hasAuthority(Permissions.GetAllUsers, setCanGetAllUsers);
        hasAuthority(Permissions.GetUserById, setCanGetUser);
        hasAuthority(Permissions.CreateUser, setCanCreateUser);
        hasAuthority(Permissions.UpdateUser, setCanUpdateUser);
        hasAuthority(Permissions.GetUserDataById, setCanGetUserData);
        hasAuthority(Permissions.UpdateUserDataById, setCanUpdateUserData);
        hasAuthority(Permissions.DeleteUser, setCanDeleteUser);
        */
    }, []);

    const getPrioritiesData = query =>
        //canGetAllUsers ?

        getAllPriorities(query.pageSize, query.page, query.search)
            .then(response => {
                return {
                    ...query,
                    data: response.priorities.data,
                    page: response.priorities.current_page - 1,
                    totalCount: response.priorities.total,
                }})
            .catch((error) => {
                console.log(error);
                return {
                    ...query,
                    page: 0,
                    pageSize: 15,
                    totalCount: 0,
                    data: [],
                }
            });


    const deletePriorityData = oldData =>
        new Promise((resolve, reject) => {
            setTimeout(() => {
                deletePriority(oldData.id)
                    .then(() => {
                        if (tableRef.current) {
                            tableRef.current.state.query.page = 0;
                            tableRef.current.onQueryChange();
                        }
                        messageError('Prioridad eliminada', 'warning');
                    })
                    .catch(() => reject());
                resolve();
            }, 1000);
        });

    useEffect(() => {
        tableRef.current && tableRef.current.onQueryChange();
    }, [canGetAllUsers]);

    return (
        <Box id="app-users-admins-page" component="main" className={appStyles.pageRoot}>
            <PageHeader
                title="Prioridades"
                titleButton="Nueva Prioridad"
                displayButton
                canExecuteButton={canCreateUser}
                action={toggleForm}
                breadcrumbsData={breadcrumbsData}
            />

            <AdminForm
                formOpen={openForm}
                toggleForm={toggleForm}
                refreshTable={() => tableRef.current && tableRef.current.onQueryChange()}
                dataObject={dataObject}
                canUpdateUser={canUpdateUser}
                canGetUserData={canGetUserData}
                canUpdateUserData={canUpdateUserData}
            />
            <DataTable
                nameEntity="Prioridad"
                columns={[
                    {
                        title: 'Nombre',
                        field: 'name',
                        type: 'string',
                    },
                    {
                        title: 'Orden',
                        field: 'order',
                        type: 'string',
                    },


                ]}
                tableActions={[
                    {
                        disabled: !canGetUser,
                        icon: () => (
                            <ViewIcon color={canGetUser ? 'primary' : 'disabled'} className="icon-small" />
                        ),
                        tooltip: canGetUser ? 'Ver Prioridad' : undefined,
                        onClick: (event, rowData) => {
                            setDataObject(rowData.id);
                            setOpenForm(!openForm);
                        },
                    },
                ]}
                tableRef={tableRef}
                getDataFunction={getPrioritiesData}
                deleteDataFunction={deletePriorityData}
                canGetAll={canGetAllUsers}
                canDelete={canDeleteUser}

            />
        </Box>
    );
}

export default WithSnackBarMessages(PriorityPage);
